@use 'sass:math';

.inbox-container {
  background: $white 0% 0% no-repeat padding-box;

  &.collapse-menu {
    .message-left-panel {
      margin-left: 0px !important;
      width: 13.5%;
      overflow-x: hidden;

      .message-menu-list {
        margin-left: 0px !important;
      }
    }

    .message-details {
      width: 61%;
    }
  }
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: 5px;
  }
}

.message-filters {
  align-items: center;
  display: grid;
  position: relative;

  .search {
    border: 1px solid $color-8b8b8b;
    border-radius: 10px;
    width: 390px;
    height: 39px;
    padding-left: 50px;
    background: $white escape-svg($molina-search-svg) 22px 50% no-repeat padding-box;
    outline: none;
    &:focus-visible {
      border: 2px solid $black;
      border-radius: 10px;
    }
  }
  .autocomplete-control {
    .autocomplete-list {
      left: 45px;
      max-width: 345px;
      min-width: 250px;
      top: 40px;
    }
  }
}

.checkbox-img {
  display: grid;
  float: right;

  label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 25px; /* 10 px padding right + 15px input type*/
    margin-right: 10px; /* margin between inputs types */
  }

  label:before {
    content: '';
    width: 26px;
    height: 26px;
    position: absolute;
    left: 0;
  }

  input[type='checkbox'] {
    display: none;
  }
  label:before {
    background: escape-svg($molina-checkbox-deselected-svg) 0px 0px no-repeat;
    background-size: 26px 26px;
    visibility: hidden;
  }

  input[type='checkbox']:checked + label:before {
    background: escape-svg($molina-checkbox-selected-svg) 0px 0px no-repeat;
    background-size: 26px 26px;
    visibility: visible;
  }
}
.sort-container {
  margin-top: $spacer * 2.375;

  .message-menu-list {
    display: none;
  }

  &.sort-container-col-collapse {
    > div:nth-child(1) {
      width: 13.5%;
      overflow-x: hidden;
    }

    > div:nth-child(3) {
      width: 61%;
    }
  }

  .sort-by {
    color: $gray-656565;
    margin-left: -22px;

    img {
      width: 25px;
      height: 17px;
      margin-right: 3px;
      margin-top: -5px;
    }

    span {
      font: normal normal normal 0.96rem/1.12rem Arial;
      letter-spacing: 0.79px;
    }
  }

  .sort-by.dropdown-toggle::after {
    content: none;
  }

  .sort-by.show {
    display: inline-block;
  }

  .sort-by + .dropdown-menu {
    .dropdown-item {
      font: normal normal normal 0.72rem/0.92rem 'campton-medium';
    }

    .dropdown-item .dropdown-icon {
      visibility: hidden;
      height: 24px;
      width: 24px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .dropdown-item.active {
      background-color: $white;

      .dropdown-icon {
        visibility: visible;
      }

      span {
        border-bottom: 1px solid $black;
        padding-bottom: 1px;
      }
    }
  }

  .select-all {
    font: normal normal normal 0.96rem/1.12rem Arial;
    letter-spacing: 0.74px;
    color: $gray-656565;
    float: right;

    input {
      width: 20px;
      height: 20px;
    }

    .checkbox-img {
      margin: 1px -7px 0 15px;

      label:before {
        visibility: visible;
      }
    }
  }

  .archive {
    padding-left: 10px;
    padding-right: 30px;
    color: $nav-tab-txt-color;
    font: normal normal normal 0.96rem/1.12rem Arial;
    vertical-align: middle;
    display: inline;
    cursor: pointer;

    img {
      // width: 34px;
      height: 1.36rem;
      margin-right: 9px;
      margin-top: -10px;
      // margin-bottom: 5px;
    }
  }

  .print {
    padding-left: 10px;
    padding-right: 30px;
    color: $nav-tab-txt-color;
    font: normal normal normal 0.96rem/1.12rem Arial;
    vertical-align: middle;
    display: inline;
    cursor: pointer;

    img {
      // width: 36px;
      height: 1.44rem;
      margin-right: 9px;
      margin-top: -10px;
    }
  }

  .head-new-mess {
    padding-left: 10px;
    padding-right: 30px;
    color: #009ea0;
    font: normal normal normal 0.96rem/1.12rem Arial;
    vertical-align: middle;
    display: inline;
    cursor: pointer;
  }
}

.message-left-panel {
  border: 1px solid $color-cecece;
  padding-top: 75px;
  overflow-y: hidden;
  position: relative;

  .message-left-panel-arrow {
    position: absolute;
    top: 15px;
    right: 13px;
    cursor: pointer;
  }

  .message-menu-list {
    text-align: left;
    margin-left: 13px;
    font: normal normal normal 1.4rem/1.66rem 'campton-medium';
    letter-spacing: 0px;
    color: $black;

    .active {
      background: #cceaea;
      border-radius: 10px 0 0 10px;
    }

    .message-menu {
      position: relative;
      //margin-bottom: 30px;
      padding: 0.6rem 0;
      display: inline-flex;
      cursor: pointer;

      img {
        // width: 36px;
        height: 1.44rem;
        margin-right: 0.6rem;
        margin-top: 2px;
      }

      &.tab-disabled {
        color: $color-9a9a9a;
        //cursor: not-allowed;

        .msg-counter {
          display: none;
        }
      }

      .msg-counter {
        text-align: center;
        padding-top: 3px;
        margin-left: 18px;
        // margin-top: 5px;
        font: normal normal bold 0.8rem/1.2rem Arial;
        letter-spacing: 0px;
        color: $white;
        background: $teal 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 9px $molina-color-e6e6e68f;
        border-radius: 7px;
        width: 1.4rem;
        height: 1.4rem;
        margin-top: 0.15rem;
      }
    }
  }
}
.mymessages-show-more {
  .show-more-btn {
    display: block;
    text-align: center;
    width: 50%;
    margin: auto;
    text-transform: uppercase;
    .card-link {
      font: normal normal 22px/28px Campton-medium;
      letter-spacing: 0px;
      color: $teal;
    }
  }
}
.show-more-messages {
  min-height: 900px;
  border-right: 1px solid #cecece;
}

.message-inbox-panel {
  min-height: 875px;
  max-height: 875px;
  overflow-y: hidden;
  border-right: 1px solid #cecece;

  .list-group {
    padding-right: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-100;
  }
  &::-webkit-scrollbar {
    width: 11px;
    background-color: $gray-100;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color-525252;
  }

  ul {
    li {
      text-align: left;
      padding: $spacer * 1.75 $spacer $spacer * 0.81 43px;
      cursor: pointer;

      .message-subject {
        font: normal normal normal 0.8rem/1.76rem Arial;
        letter-spacing: 0.74px;
        max-width: 80%;
        display: block;
        display: -webkit-box;
        margin: 0 auto;
        float: left;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .message-date {
        font: normal normal normal 0.84rem/1.12rem Arial;
        letter-spacing: 0.69px;
        float: right;
        padding-top: math.div($spacer, 2);
        position: absolute;
        right: 0;
        top: 29px;
        right: 18px;
      }

      .message-body {
        font: normal normal normal 0.72rem/0.8rem Arial;
        letter-spacing: 0.32px;
        display: inline-block;
        padding-bottom: $spacer * 0.938;
        width: 100%;

        div {
          float: left;
          display: block;
          display: -webkit-box;
          max-width: 82%;
          margin: 0 auto;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:focus,
      &:active,
      &:hover {
        background-color: $gray-3;
        z-index: 0;

        .checkbox-img label:before {
          visibility: visible;
          margin-left: 3px;
        }
      }

      .message-body-pdf {
        font: normal normal normal 0.72rem/0.8rem Arial;
        letter-spacing: 0.32px;
        display: inline-block;
        padding-bottom: $spacer * 0.938;
        // width: 100%;
        height: 1780px;

        div {
          float: left;
          display: block;
          display: -webkit-box;
          max-width: 82%;
          margin: 0 auto;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:focus,
      &:active,
      &:hover {
        background-color: $gray-3;
        z-index: 0;

        .checkbox-img label:before {
          visibility: visible;
          margin-left: 3px;
        }
      }
    }
    .inbox-message-read,
    .inbox-alert-read {
      .message-subject {
        color: $dropdown-molina-color;
      }
      .message-body {
        color: $molina-color-black-80;
      }
      .message-date {
        color: $dropdown-molina-color;
      }
    }

    .inbox-message-unread,
    .inbox-alert-unread {
      .message-subject {
        font: normal normal bold 0.96rem/1.76rem Arial;
        color: $molina-color-blackde;
      }
    }

    .inbox-message-read {
      background: escape-svg($molina-message-read-svg) 10px 35px no-repeat padding-box;
    }
    .inbox-message-unread {
      background: escape-svg($molina-message-unread-svg) 10px 40px no-repeat padding-box;
    }
    .inbox-alert-read {
      background: escape-svg($molina-alert-read-svg) 10px 35px no-repeat padding-box;
    }
    .inbox-alert-unread {
      background: escape-svg($molina-alert-unread-svg) 10px 35px no-repeat padding-box;
    }

    .active-item {
      background-color: $gray-3;
      .checkbox-img label:before {
        visibility: visible;
        margin-left: 3px;
      }
    }

    .attachment-block {
      .attachments {
        > img {
          width: 17px;
          height: 22px;
          margin: 0px 15px 2px 15px;
        }
        > span {
          vertical-align: middle;
        }
        font: normal normal normal 0.72rem/1.76rem Arial;
        letter-spacing: 0.56px;
        max-width: 80%;
        background-color: $white;
        border: 1px solid $gray-border;
        padding: 9px 5px 7px 0px;
        background-size: 22px 22px;
        display: inline;
        a {
          margin-left: 5px;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
      .attachments {
        > img {
          width: 17px;
          height: 22px;
          margin: 0px 15px 2px 15px;
        }
        > span {
          vertical-align: middle;
        }
        font: normal normal normal 0.72rem/1.76rem Arial;
        letter-spacing: 0.56px;
        max-width: 80%;
        background-color: $white;
        border: 1px solid $gray-border;
        padding: 9px 5px 7px 0px;
        border-radius: 5px;
        @include media-breakpoint-down(xxl) {
          padding: 8px 5px 9px 0px;
        }
        @include media-breakpoint-down(lg) {
          padding: 8px 5px 8px 0px;
        }
        background-size: 22px 22px;
        display: inline;
        a {
          margin-left: 5px;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }

      .attachment-count {
        font: normal normal normal 0.72rem/0.84rem Arial;
        margin-left: 15px;
        display: inline;
      }
    }
  }

  .pagination {
    nav {
      margin: auto;
      padding-top: 24px;
      margin-bottom: 105px;
    }
    li {
      padding: 0 !important;
    }
    li .page-link {
      padding: 1.5px 8px !important;
      color: black;
      font: normal normal normal 20px/23px Arial;
      text-align: center;
    }
    li .page-link span {
      vertical-align: bottom;
    }
    .page-item.active .page-link {
      background: #e0e0e0;
      color: black;
      box-shadow: none;
      border-radius: 4px;
    }
    li:hover,
    li:active,
    li:focus {
      background: none;
      height: 26px;
    }
    .page-item.active:active,
    .page-item.active:focus {
      height: 26px;
      background: none;
    }
  }
}

@include media-breakpoint-up(xl) {
  .message-inbox-panel:hover {
    overflow-y: overlay;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .message-inbox-panel {
    overflow-y: hidden;
  }

  .message-inbox-panel:hover {
    overflow-y: overlay;
  }

  .sort-container .select-all .checkbox-img {
    margin: -3px 5px 0 15px !important;
  }
  
}

.message-details-view {
  display: none;
}
.message-details {
  text-align: left;
  padding: 20px 30px !important;

  .message-date {
    font: normal normal normal 0.96rem/0rem Arial;
    letter-spacing: 0.43px;
    color: $dropdown-molina-color;
  }
  .message-heading {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    font: normal normal bold 1.84rem/2rem Arial;
    letter-spacing: 0.82px;
  }

  .message-from,
  .message-to {
    font: normal normal normal 0.96rem/1.76rem Arial;
    letter-spacing: 0.74px;
    color: $color-666666;
  }
  .message-from {
    margin-bottom: -5px;
  }
  .message-to {
    margin-bottom: 30px;
  }
  .message-body {
    margin-top: 35px;
    font: normal normal normal 1.04rem/1.2rem Arial;
    letter-spacing: 0.46px;
    color: $dropdown-molina-color;
  }
  .message-body-pdf {
    font: normal normal normal 0.72rem/0.8rem Arial;
    letter-spacing: 0.32px;
    display: inline-block;
    padding-bottom: $spacer * 0.938;
    // width: 100%;
    height: 1780px;

    div {
      float: left;
      display: block;
      display: -webkit-box;
      max-width: 82%;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:focus,
  &:active,
  &:hover {
    background-color: $gray-3;
    z-index: 0;

    .checkbox-img label:before {
      visibility: visible;
      margin-left: 3px;
    }
  }
}
.parent-expand {
  margin-right: 20px;
}

.attachment-box {
  width: 40%;
}
.attachments-expanded-msg {
  font: normal normal normal 0.96rem/1.36rem Arial;
  letter-spacing: 0.74px;
  width: 90%;
  color: #585858;
  background-color: $white;
  // border: 1px solid $gray-border;
  padding: 0.54rem 0rem 0.72rem 0.8rem;
  background-size: 1195px 903px;
  display: inline-flex;
  margin: 1.32rem 0px 0.92rem 0.8rem;
  box-shadow: 0px 2px 6px #00000029;
  border: 2px solid #cecece;
  border-radius: 5px;
  .attachment-img {
    height: 44px;
    margin-top: 7px;
  }
  > span {
    vertical-align: middle;
  }
  .attachment-size {
    font: normal normal normal 0.8rem/0.8rem Arial;
    letter-spacing: 0.62px;
    padding: 0px 13px 0px 10px;
  }
  .download-link {
    width: 20%;
    .download-img {
      height: 36px;
      margin-top: 7px;
    }
  }
}
.attachments-expanded-msg {
  @include media-breakpoint-down(xxl) {
    margin: 1.32rem 0px 0.92rem -0.2rem;
  }
}
.message-details .show {
  @include media-breakpoint-up(xl) {
    margin-left: -5px;
  }
}
#newrow {
  display: none;
}
#showless {
  display: none;
}
.tax-forms-readmore {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  padding: 14px 20px 0px 10px;
}
.show-less-img {
  transform: rotateX(180deg);
}

.message-details {
  .show {
    font: normal normal normal 16px/21px Arial;
    color: #646464;
  }
}

.attachment-large-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 33%;
  vertical-align: middle;
  margin-left: 0px;
}
@include media-breakpoint-down(xxl) {
  .sort-container {
    .sort-by {
      margin-left: -8px;
    }
    .archive img {
      margin-top: -5px;
    }
    &.sort-container-col-collapse {
      > div:nth-child(1) {
        width: auto;
      }

      > div:nth-child(3) {
        width: auto;
      }
    }
    .select-all {
      .checkbox-img {
        margin: -3px 5px 0 15px;
      }
    }
  }
  .message-left-panel {
    .message-left-panel-arrow {
      display: none;
    }
  }
  .message-inbox-panel {
    ul {
      .inbox-message-read,
      .inbox-alert-read,
      .inbox-alert-unread {
        background-position-y: 29px;
        min-height: 100px;
      }
      .inbox-message-unread {
        background-position-y: 32px;
        min-height: 100px;
      }
      li {
        .message-date {
          top: 23px;
        }
      }
    }
  }
  .attachment-large-text {
    margin-left: 0px;
  }

  .attachments-expanded-msg {
    .attachment-img {
      height: 30px;
    }
    .download-link {
      .download-img {
        height: 30px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .sort-container {
    .sort-by {
      margin-left: -8px;
    }
    .archive img {
      margin-top: -5px;
    }
    .select-all {
      .checkbox-img {
        margin: -3px 5px 0 15px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .message-filters {
    .autocomplete-control {
      .autocomplete-list {
        max-width: 342px;
        min-width: 250px;
      }
    }
  }
  .inbox-container {
    &.collapse-menu {
      .message-left-panel {
        margin-left: 0px !important;
        width: 13%;
      }
    }
  }
  .sort-container {
    .sort-by {
      margin-left: -8px;
      img {
        width: 30px;
        height: 20px;
      }
      span {
        font: normal normal normal 20px/24px Arial;
      }
    }
    // &.sort-container-col-collapse {
    //   > div:nth-child(1) {
    //     width: 13%;
    //   }
    // }

    .select-all {
      font: normal normal normal 20px/24px Arial;

      .checkbox-img {
        margin-right: 10px;
      }
    }
    .archive {
      padding-left: 5px;
      padding-right: 20px;
      font: normal normal normal 20px/24px Arial;
      img {
        width: 27px;
        height: 27px;
      }
    }
    .print {
      padding-left: 5px;
      padding-right: 20px;
      font: normal normal normal 20px/24px Arial;
      img {
        width: 27px;
        height: 27px;
      }
    }
  }
  .mymessages-show-more {
    .show-more-btn {
      display: block;
      text-align: center;
      width: 50%;
      margin: auto;
      text-transform: uppercase;
      .card-link {
        font: normal normal 22px/28px Campton-medium;
        letter-spacing: 0px;
        color: $teal;
      }
    }
  }
  .message-left-panel {
    .message-menu-list {
      margin-left: 10px;
      font: normal normal normal 28px/36px 'campton-medium';

      .message-menu {
        // margin-bottom: 25px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
        .msg-counter {
          margin-left: 10px;
          margin-top: 5px;
          font: normal normal bold 0.72rem/0.96rem Arial;
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .message-inbox-panel {
    ul {
      li {
        .message-subject {
          font: normal normal normal 20px/44px Arial;
          letter-spacing: 0.74px;
          color: #00000099;
          max-width: 80%;
        }

        .message-subject-cm {
          font: normal normal normal 20px/44px Arial;
          letter-spacing: 0.74px;
          border-color: orangered;
          color: orangered;
        }

        .message-date {
          font: normal normal normal 21px/28px Arial;
          letter-spacing: 0.69px;
          top: 16px;
        }
        .message-date-cm {
          font: normal normal normal 21px/28px Arial;
          letter-spacing: 0.69px;
          top: 16px;
          border-color: orangered;
          color: orangered;
        }

        .message-body {
          font: normal normal normal 16px/20px Arial;
          letter-spacing: 0.29px;
        }

        .message-body-pdf {
          font: normal normal normal 16px/20px Arial;
          letter-spacing: 0.29px;
        }

        .message-body-cm {
          font: normal normal normal 16px/20px Arial;
          letter-spacing: 0.29px;
          border-color: orangered;
          color: orangered;
        }
      }

      .inbox-message-unread,
      .inbox-alert-unread {
        .message-subject {
          font: normal normal bold 20px/44px Arial;
        }
      }

      .attachment-block {
        .attachments {
          font: normal normal normal 16px/20px Arial;
          letter-spacing: 0.56px;
        }

        .attachment-count {
          font: normal normal normal 16px/20px Arial;
          margin-left: 15px;
        }
      }
    }
  }

  .message-details {
    .message-date {
      font: normal normal normal 16px/20px Arial;
      letter-spacing: 0.29px;
    }
    .message-heading {
      margin-top: 20px;
      margin-bottom: 20px;
      font: normal normal bold 22px/20px Arial;
      letter-spacing: 0.39px;
    }
    .message-from,
    .message-to {
      font: normal normal normal 16px/20px Arial;
      letter-spacing: 0.5px;
    }
    .message-from {
      margin-bottom: 5px;
    }
    .message-to {
      margin-bottom: 45px;
    }
    .message-body {
      margin-top: 45px;
      font: normal normal normal 16px/20px Arial;
      letter-spacing: 0.29px;
    }
    .message-body-pdf {
      width: 700px;
      height: 790px;
    }
  }

  .parent-expand {
    margin-right: 20px;
  }

  .attachment-box {
    width: 40%;
  }
  .attachments-expanded-msg {
    font: normal normal normal 18px/35px Arial;
    letter-spacing: 0.74px;
    width: 94%;
    color: #585858;
    background-color: $white;
    border: 1px solid $gray-border;
    padding: 15px;
    background-size: 1195px 903px;
    display: inline-flex;
    margin: 33px 0px 23px 4px;
    .attachment-img {
      height: 35px;
    }
    > span {
      vertical-align: middle;
    }
    .attachment-size {
      font: normal normal normal 0.8rem/0.8rem Arial;
      letter-spacing: 0.62px;
      padding: 0px 13px 0px 10px;
    }
    .download-link {
      width: 10%;
      .download-img {
        height: 30px;
        margin-top: 7px;
      }
    }
  }
  #newrow {
    display: none;
  }
  #showless {
    display: none;
  }
  .tax-forms-readmore {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75%;
    padding: 7px 20px 0px 10px;
  }
  .show-less-img {
    transform: rotateX(180deg);
  }
  .message-details {
    .show {
      font: normal normal normal 0.72rem/0.84rem Arial;
    }
  }
  .attachment-large-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
    vertical-align: middle;
    margin-left: 10px;
  }

  .message-details-view {
    .archive img {
      height: 17px;
      width: 17px;
    }
    .print img {
      height: 16px;
      width: 17px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .inbox-container {
    &.collapse-menu {
      .message-left-panel {
        width: 12.5%;
      }

      .message-details {
        width: 52.5%;
      }
    }
  }

  .sort-container {
    &.sort-container-col-collapse {
      > div:nth-child(1) {
        width: 12.5%;
      }

      > div:nth-child(3) {
        width: 52.5%;
      }
    }
  }

  .mymessages-show-more {
    .show-more-btn {
      display: block;
      text-align: center;
      width: 50%;
      margin: auto;
      text-transform: uppercase;

      .card-link {
        font: normal normal 22px/28px Campton-medium;
        letter-spacing: 0px;
        color: $teal;
      }
    }
  }

  .message-left-panel {
    .message-menu-list {
      margin-left: 5px;
      font: normal normal normal 22px/27px 'campton-medium';

      .message-menu {
        // margin-bottom: 25px;
        img {
          width: 26px;
          height: 26px;
          margin-right: 5px;
        }

        .msg-counter {
          margin-left: 5px;
          margin-top: 0px;
          font: normal normal normal 14px/21px Arial;
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .parent-expand {
    margin-right: 20px;
  }

  .attachment-box {
    width: 45%;
  }

  .attachments-expanded-msg {
    letter-spacing: 0.74px;
    width: 95%;
    color: #585858;
    background-color: $white;
    border: 1px solid $gray-border;
    // padding: 15px 15px 18px 20px;
    background-size: 1195px 903px;
    display: inline-flex;
    margin: 33px 0px 23px 4px;

    > span {
      vertical-align: middle;
    }

    .attachment-size {
      font: normal normal normal 0.8rem/0.8rem Arial;
      letter-spacing: 0.62px;
      padding: 0px 13px 0px 10px;
    }

    .download-link {
      width: 20%;

      .download-img {
        height: 30px;
        margin-top: 7px;
      }
    }
  }

  #newrow {
    display: none;
  }

  #showless {
    display: none;
  }

  .tax-forms-readmore {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
    padding: 7px 10px 0px 10px;
  }

  .show-less-img {
    transform: rotateX(180deg);
  }

  .message-details-view {
    background: $body-bg;
    padding-top: 25px;
    height: 100%;
    width: 0%;
    top: 82px;
    right: 0;
    position: absolute;
    overflow-x: hidden;
    transition: 0.3s;
    transform: translate(100%, 0);
    display: block;

    .back-btn {
      font: normal normal normal 24px/31px 'campton-medium';
      cursor: pointer;

      img {
        transform: rotate(180deg);
        margin-bottom: 3px;
        margin-right: 10px;
      }
    }

    .archive img {
      height: 20px;
      width: 20px;
      margin-right: $spacer;
    }

    .print img {
      height: 19px;
      width: 20px;
      margin-right: $spacer;
    }

    &.open {
      width: 100% !important;
      transform: translate(0, 0);
    }
  }

  .message-details {
    .show {
      font: normal normal normal 0.72rem/0.84rem Arial;
    }

    height: 100%;
    min-height: 746px;
    width: 0%;
    padding: 0 !important;
    top: 176px;
    right: 0;
    position: absolute;
    overflow-x: hidden;
    transition: 0.3s;
    transform: translate(100%, 0);
    background: $white;

    &.open {
      width: 100%;
      padding: 45px 42px !important;
      transform: translate(0, 0);
    }
  }

  .attachment-large-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
    vertical-align: middle;
    margin-left: 10px;
  }
}

@include media-breakpoint-down(lg) {
  .inbox-container {
    &.collapse-menu {
      .message-left-panel {
        width: 25%;
      }
      > div:nth-child(2) {
        width: 75%;
      }
    }
  }
  .mymessages-show-more {
    .show-more-btn {
      display: block;
      text-align: center;
      width: 50%;
      margin: auto;
      text-transform: uppercase;
      .card-link {
        font: normal normal 22px/28px Campton-medium;
        letter-spacing: 0px;
        color: $teal;
      }
    }
  }
  .sort-container {
    margin-bottom: 5px;
    .sort-by {
      img {
        width: 30px;
        height: 20px;
      }
    }
    // &.sort-container-col-collapse {
    //   > div:nth-child(1) {
    //     width: 25%;
    //   }
    //   > div:nth-child(2) {
    //     width: 75%;
    //   }
    // }

    .archive {
      padding-right: 12px;
      span {
        display: none;
      }
      img {
        margin-right: 0px;
      }
    }
    .print {
      padding-right: 12px;
      span {
        display: none;
      }
      img {
        margin-right: 0px;
      }
    }
  }

  .message-details {
    height: 100%;
    min-height: 746px;
    width: 0%;
    padding: 0 !important;
    top: 176px;
    right: 0;
    position: absolute;
    overflow-x: hidden;
    transition: 0.3s;
    transform: translate(100%, 0);
    background: $white;
  }

  .message-inbox-panel {
    ul {
      .inbox-message-read,
      .inbox-alert-read,
      .inbox-alert-unread {
        background-position-y: 24px;
        min-height: 100px;
      }
      .inbox-message-unread {
        background-position-y: 27px;
        min-height: 100px;
      }
      li {
        .message-date {
          top: 16px;
        }
      }
    }
  }

  .parent-expand {
    margin-right: 20px;
  }

  .attachment-box {
    width: 40%;
  }
  .attachments-expanded-msg {
    font: normal normal normal 20px/34px Arial;
    letter-spacing: 0.74px;
    width: 90%;
    color: #585858;
    background-color: $white;
    border: 1px solid $gray-border;
    padding: 21px 24px 18px 20px;
    background-size: 1195px 903px;
    display: inline-flex;
    margin: 33px 0px 23px 4px;
    .attachment-img {
      margin-top: 7px;
    }
    > span {
      vertical-align: middle;
    }
    .attachment-size {
      font: normal normal normal 0.8rem/0.8rem Arial;
      letter-spacing: 0.62px;
      padding: 0px 13px 0px 10px;
    }
    .download-link {
      width: 20%;
      .download-img {
        height: 36px;
        margin-top: 7px;
      }
    }
  }
  #newrow {
    display: none;
  }
  #showless {
    display: none;
  }
  .tax-forms-readmore {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 65%;
    padding: 7px 20px 0px 20px;
  }
  .show-less-img {
    transform: rotateX(180deg);
  }
  .message-details {
    .show {
      font: normal normal normal 0.72rem/0.84rem Arial;
    }
  }
  .attachment-large-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
    vertical-align: middle;
    margin-left: 0px;
  }
}

@include media-breakpoint-down(md) {
  .message-filters {
    margin-top: $spacer;
    .search {
      width: 100%;
    }
    .autocomplete-control {
      .autocomplete-list {
        left: 0px;
        max-width: 302px;
        min-width: 150px;
      }
    }
  }
  .sort-container {
    margin-bottom: 5px;
    .message-menu-list {
      display: block;
      padding-bottom: 5px;
      margin-left: 35px;

      .active {
        background: #cceaea;
        border-radius: 5px;
        padding: 2px 2px 6px;
      }

      .message-menu:first-child {
        margin-right: 16px;
      }

      .message-menu {
        font: normal normal normal 22px/28px 'campton-medium';
        display: inline;
        cursor: pointer;

        &.tab-disabled {
          color: $gray-2;
          //cursor: not-allowed;

          .msg-counter {
            display: none;
          }
        }

        .msg-counter {
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          margin-left: 6px;
          font: normal normal normal 14px/21px Arial;
          color: $white;
          background: $teal 0% 0% no-repeat padding-box;
          box-shadow: 0px 6px 9px $molina-color-e6e6e68f;
          border-radius: 7px;
          width: 23px;
          height: 23px;
        }
      }
    }
    .sort-by {
      img {
        margin-top: 3px;
      }
      span {
        display: none;
      }
    }

    .select-all {
      .checkbox-img {
        margin: 0px 7px 0px 0px;
      }

      span:first-child {
        display: none;
      }
    }
  }

  .message-inbox-panel ul li {
    .message-date {
      top: 21px;
    }
  }
  .message-inbox-panel {
    min-height: auto;
    max-height: initial;
    overflow-x: auto;
  }
  .parent-expand {
    margin-right: 20px;
  }

  .attachment-box {
    width: 100%;
  }
  .attachments-expanded-msg {
    font: normal normal normal 16px/34px Arial;
    letter-spacing: 0.74px;
    width: 60%;
    color: #585858;
    background-color: $white;
    border: 1px solid $gray-border;
    padding: 21px 24px 18px 20px;
    background-size: 1195px 903px;
    display: inline-flex;
    margin: 15px 0 10px;
    .attachment-img {
      height: 35px;
      margin-top: 7px;
    }
    > span {
      vertical-align: middle;
    }
    .attachment-size {
      font: normal normal normal 0.8rem/0.8rem Arial;
      letter-spacing: 0.62px;
      padding: 0px 13px 0px 10px;
    }
    .download-link {
      width: 20%;
      .download-img {
        height: 36px;
      }
    }
  }
  #newrow {
    display: none;
  }
  #showless {
    display: none;
  }
  .tax-forms-readmore {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 65%;
    padding: 7px 20px 0px 10px;
  }
  .show-less-img {
    transform: rotateX(180deg);
  }
  .message-details {
    .show {
      font: normal normal normal 0.72rem/0.84rem Arial;
    }
  }
  .attachment-large-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
    vertical-align: middle;
    margin-left: 0px;
  }
}

@include media-breakpoint-down(sm) {
  .parent-expand {
    margin-right: 20px;
  }

  .attachment-box {
    width: 100%;
  }
  .attachments-expanded-msg {
    font: normal normal normal 16px/34px Arial;
    letter-spacing: 0.74px;
    width: 100%;
    color: #585858;
    background-color: $white;
    border: 1px solid $gray-border;
    padding: 21px 24px 18px 20px;
    background-size: 1195px 903px;
    display: inline-flex;
    margin: 33px 0px 23px 0px;
    .attachment-img {
      width: 15%;
      height: 35px;
    }
    > span {
      vertical-align: middle;
    }
    .attachment-size {
      font: normal normal normal 0.8rem/0.8rem Arial;
      letter-spacing: 0.62px;
      padding: 0px 13px 0px 10px;
    }
    .download-link {
      width: 20%;
      .download-img {
        height: 36px;
        margin-top: 7px;
      }
    }
  }
  #newrow {
    display: none;
  }
  #showless {
    display: none;
  }
  .tax-forms-readmore {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 65%;
    padding: 7px 20px 0px 10px;
  }
  .show-less-img {
    transform: rotateX(180deg);
  }
  .message-details {
    .show {
      font: normal normal normal 0.72rem/0.84rem Arial;
    }
  }
  .attachment-large-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.p-r-10 {
  padding-right: 10px;
}

.mb-pagination {
  .disabled {
    color: #c1c1c1;
  }

  .pg-active {
    background: #029aa5;
    color: white;
    border-radius: 5px;
    margin-top: -1px;
    padding: 3px 7.5px;
  }

  .active {
    padding: 3.5px 0px !important;
  }

  .page-link:focus {
    background-color: none;
  }
}

.MuiPaper-root {
  border-radius: 0;
}

.container-fluid.p-0 {
  overflow-x: hidden;
}

.inbox-container .row.message-inbox-panel > p {
  padding-left: 42px !important;
}
.mess-top-head {
  margin-bottom: 60px;
}

.msg-id {
  font: normal normal normal 1.05rem/1.56rem campton-medium;
  margin-bottom: 10px;
}
.msg-details {
  border: 1px solid #75757514;
  padding: 10px;
  box-shadow: -9px 9px 8px #75757514;
  margin-bottom: 20px;
}
.msg-details-head {
  color: #009ea0;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 5px 10px 5px;
}
.msg-tbl {
  padding: 10px;
  font-size: 14px;
}
.msg-category {
  margin: 10px 0px 10px 0px;
  font-weight: normal;
}
.msg-subject {
  margin: 10px 0px 10px 0px;
  font-weight: normal;
}
.msg-status {
  margin: 10px 0px 10px 0px;
  font-weight: normal;
}
.msg-status-highlighted {
  border-color: orangered;
  color: orangered;
}
.msg-contact-methods {
  margin: 10px 0px 10px 0px;
  font-weight: normal;
}
.msg-dtl-correspondence {
  padding: 20px 3px 13px;
  color: #009ea0;
  font-size: 20px;
  font-weight: bold;
}

.btn-reply {
  float: right;
  background-color: orangered;
  color: white;
}

.btn-reply-termed {
  float: right;
  background-color: grey;
  color: white;
}

.btn-reply-now {
  float: left;
  background-color: orangered;
  color: white;
}
.btn-send {
  float: right;
  margin: 20px 10px 10px 10px;
  background-color: orangered !important;
  color: white !important;
}
.btn-submit {
  float: right;
  margin: 20px 10px 10px 10px;
  background-color: teal !important;
  color: white !important;
}
.btn-done {
  margin: 20px 10px 10px 10px;
  background-color: teal !important;
  color: white !important;
}
.popup-teal-text {
  text-align: center !important;
  color: teal !important;
}

.btn-send:hover,
.btn-send:hover a:hover {
  background-color: #fff !important;
  color: orangered !important;
}
.btn-submit:hover,
.btn-submit:hover a:hover {
  background-color: #fff !important;
  color: teal !important;
}
.btn-send-termed {
  float: right;
  margin: 20px 10px 10px 10px;
  background-color: grey !important;
  color: white !important;
}
.btn-send-termed,
.btn-send-termed a:hover {
  background-color: grey !important;
  color: white !important;
}
.btn-cancel {
  float: right;
  margin: 20px 10px 10px 10px;
  background-color: #d3d3d3 !important;
  color: white !important;
}
.btn-cancel:hover,
.btn-cancel:hover a:hover {
  background-color: #fff !important;
  color: #d3d3d3 !important;
}
.btn-upload {
  float: left;
  margin: 15px 10px 15px 0;
  background-color: #d3d3d3 !important;
  color: #000 !important;
}
.btn-upload:hover,
.btn-upload:hover a:hover {
  background-color: #d3d3d3 !important;
  color: #000 !important;
}
.msg-cores {
  color: orangered;
}
.msg-bold {
  font-weight: 700;
}

.newmessage {
  padding-left: 10px;
  padding-right: 30px;
  color: $nav-tab-txt-color;
  font: normal normal normal 0.96rem/1.12rem Arial;
  vertical-align: middle;
  display: inline;
  cursor: pointer;
  img {
    // width: 36px;
    height: 1.44rem;
    margin-right: 9px;
    margin-top: -10px;
  }
}

.grid-2col-container {
  margin-left: 20px;
  display: inline;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
}

.grid-3col-container {
  display: inline-flex;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.grid-4col-container {
  display: inline-flex;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}

.grid-1col-container {
  display: inline-flex;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
  .contact-us-page .grid-item:first-child {
    padding-bottom: 0.8rem;
  }
}
.hide-div {
  display: none;
}
.head-create-mess {
  margin-top: 25px;
  margin-left: 30px;
}
.crt-mess-heading {
  color: #009ea0;
  font: normal normal 0.98rem/1.12rem Campton-medium;
  vertical-align: middle;
  display: inline;
  margin-top: 15px;
}
.subhead-crt-mess {
  color: #000;
  margin-top: 25px;
  margin-left: 10px;
  font: 0.7rem/1.12rem Campton-medium;
}
.subhead-crt-mess-text {
  font: 15px webkit-control;
  position: relative;
  color: #000;
  margin: 20px 10px 40px 30px;
  //margin: top right  bottom left
}
.crt-mess-subhead {
  font-size: 15px;
  margin-top: 15px;
}
.row-title {
  margin-top: 25px;
}
.row-sub-text {
  font: small-caption;
  margin-bottom: 5px;
  margin-top: 5px;
}
.dropdown-menu-width {
  width: 60%;
}
.message-body-pdf {
  width: 1000px;
  height: 1200px;
}
@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
  .dropdown-menu-width {
    width: 80% !important;
  }
}
.txt-area-width {
  width: 80%;
}
.create-mess-snd-btn {
  width: 80%;
  margin: 20px 0 50px 10px;
}
.btn-margin {
  margin-right: 10px;
}
.label-radio {
  font: -webkit-small-control;
  padding-top: 10px;
  margin-left: 25px;
}
.radio {
  margin-left: 25px;
  font: -webkit-small-control;
}

.success-msg {
  font: normal normal 0.7rem/1.12rem Campton-medium;
  color: indianred;
  position: relative;
  margin: 40px 10px 30px 30px;
  font-size: 14px;
}
.text-danger {
  display: inline;
}
.row-radio-btn-sub-text {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;
}
.mess-content {
  font: normal normal normal 0.72rem/0.96rem Arial;
}
.new-mess-link {
  border-radius: 0.25rem;
  background-color: #009ea0;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  font: normal normal normal 0.85rem/0.99rem Arial;
  display: inline;
  margin-right: 20px;
  position: relative;
  top: -5px;
}
//for Terminated members.
.new-mess-link-termed {
  border-radius: 0.25rem;
  background-color: grey;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  font: normal normal normal 0.85rem/0.99rem Arial;
  display: inline;
  margin-right: 20px;
  position: relative;
  top: -5px;
}
.expand-icon {
  cursor: pointer;
  float: right;
  img {
    width: 22px;
    height: 22px;
  }
}
.attach-text {
  color: #000;
  font: 0.6rem/1rem Campton-medium;
}
input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}
.label-file-upload {
  font: -webkit-small-control;
  padding-top: 5px;
}
.label-attachments {
  color: #000;
  font: 0.7rem/1.12rem Campton-medium;
}
.img-close {
  cursor: pointer;
  width: 10px;
  height: 13px;
  margin-left: 178px;
}
.message-body-pdf {
  width: 820px;
  height: 1800px;
}
//changes to commit
