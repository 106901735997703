.login {
  .pwd-icon {
    height: 24px;
    width: 24px;
  }
  .login-img {
    background: escape-svg($molina-out-of-expenses-svg);
    background-repeat: no-repeat;
  }
  .login-img1 {
    background: escape-svg($molina-id-card-svg);
    background-repeat: no-repeat;
    background-position-y: center;
  }
  .login-img2 {
    background: escape-svg($molina-change-my-doctor-svg);
    background-repeat: no-repeat;
  }
  .login-img3 {
    background: escape-svg($molina-schedule-of-benefits-svg);
    background-repeat: no-repeat;
  }
  .login-img4 {
    background: escape-svg($molina-contact2-svg);
    background-repeat: no-repeat;
  }
  .login-img5 {
    background: escape-svg($molina-EOC-svg);
    background-repeat: no-repeat;
  }

  .pass-wrapper {
    position: relative;
    display: flex;
    i {
      position: absolute;
      right: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  .grid-2col-container {
    display: grid;
    row-gap: 34px;
    column-gap: 39px;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    width: 935px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #0000001f;
  }

  .row-title {
    font: normal normal normal 0.72rem/0.96rem Arial;
    letter-spacing: 0.22px;
    padding-bottom: 12px;
    color: #000000;
  }
  .login-section {
    margin-top: 2.8rem;
    // .lg-card .card-body {
    //   padding: 39px 79px 52px 79px;
    // }
  }
  .card-title {
    font: normal normal normal 2.24rem/1.92rem 'campton-medium';
    letter-spacing: 0.42px;
  }
  .card-subtitle {
    font: normal normal normal 1.2rem/1.52rem 'campton-book';
    color: #454545;
    margin-bottom: 39px;
  }
  .signin {
    font: normal normal bold 0.88rem/1.04rem Arial;
    color: #000000;
    margin-bottom: 20px;
  }
  .account {
    font: normal normal normal 0.72rem/0.84rem Arial;
    color: #454545;
    p {
      margin-bottom: 11px;
      margin-top: 9px;
    }
  }
  .btn {
    width: 4.44rem;
    height: 1.76rem;
    border-radius: 5px;
    padding: 0;
  }
  .create-account {
    font: normal normal bold 0.72rem/0.84rem Arial;
    color: #009ea0;
  }
  .login-btn,
  .frgt-pswd {
    float: right;
    text-align: right;
  }
  .online-section {
    margin-top: 1.8rem;
    margin-bottom: 2.2rem;
    // .lg-card .card-body {
    //   padding: 36px 79px 12px 79px;
    // }
    .card-title {
      font: normal normal normal 1.2rem/1.52rem 'campton-book';
      color: #151515;
      margin-bottom: 58px;
    }
    .col-gap,
    .login-contact-us {
      margin-top: 12px;
    }
    span {
      font: normal normal normal 0.72rem/1.4rem Arial;
      color: #000000;
      margin-left: 50px;
    }
    .services {
      font: normal normal bold 0.72rem/0.84rem Arial;
      color: #009ea0;
    }
  }
}

.loginFailed-msg,
.loginSuccess-msg {
  text-align: center;
}
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .login {
    .online-section {
      .grid-item {
        display: flex;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .login {
    .pass-wrapper .i {
      margin-top: 0.7rem;
    }
    .signin {
      font: normal normal bold 20px/23px Arial;
    }
    .row-title {
      font: normal normal normal 18px/24px Arial;
    }
    .btn {
      width: 116px;
      height: 44px;
      border-radius: 5px;
      padding: 0;
    }
    .grid-2col-container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 25px;
    }
    .login-section {
      margin-top: 50px;
      .lg-card .card-body {
        padding: 30px 19px 23px 19px;
      }
      .card-title {
        font: normal normal 600 25px/32px 'Campton-semibold';
        color: #212121;
        letter-spacing: 0;
      }
    }
    .online-section {
      margin-bottom: 67px;
      margin-top: 40px;
      .lg-card .card-body {
        padding: 33px 15px 24px;
      }
      .col-gap {
        margin-top: 0px;
      }
      .login-contact-us {
        margin-top: 25px;
      }
      span {
        font: normal normal normal 18px/21px Arial;
      }
    }
    .card-subtitle {
      font: normal normal normal 22px/28px 'campton-book';
    }
    .frgt-pswd {
      text-align: left;
      margin-top: 28px;
    }
    .create-account {
      font: normal normal bold 16px/18px Arial;
    }
    .login-btn {
      text-align: center;
      float: none;
      margin-bottom: 39px;
    }
    .online-section {
      p {
        font: normal normal normal 20px/24px Arial;
      }
      .grid-item {
        display: flex;
        margin-bottom: 0px !important;
        .services {
          font: normal normal bold 16px/18px Arial;
          width: 100%;
          word-break: break-word;
        }
      }
      .card-title {
        font: normal normal normal 22px/30px 'campton-book';
        margin-bottom: 44px;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

body.gcaptcha .grecaptcha-badge {
  visibility: visible !important;
}
