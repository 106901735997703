//
// Material styles for check / radio / switch
//

.form-check {
  .form-check-input {
    margin-right: 0.8rem;

    &[type="radio"] {
      margin-left: $form-check-input-radio-margin-left * -1;
    }
  }

  margin-bottom: 0;
  min-height: auto;
}

.form-check-input {
  position: relative;
  width: $form-check-input-width-md;
  height: $form-check-input-height;
  background-color: $form-check-input-background-color;
  border: $form-check-input-border-width solid $form-check-input-border-color;

  &:before {
    content: "";
    position: absolute;
    box-shadow: $form-check-input-before-box-shadow;
    border-radius: $form-check-input-before-border-radius;
    width: $form-check-input-before-width;
    height: $form-check-input-before-height;
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    transform: $form-check-input-before-transform;
  }

  &:hover {
    cursor: pointer;

    &:before {
      opacity: $form-check-input-hover-before-opacity;
      box-shadow: $form-check-input-hover-before-box-shadow;
    }
  }

  &:focus {
    box-shadow: none;
    border-color: $form-check-input-focus-border-color;
    transition: $form-check-input-focus-transition;

    &:before {
      opacity: $form-check-input-focus-before-opacity;
      box-shadow: $form-check-input-focus-before-box-shadow;
      transform: $form-check-input-focus-before-transform;
      transition: $form-check-input-focus-before-transition;
    }
  }

  &:checked {
    border-color: $form-check-input-checked-border-color;

    &:before {
      opacity: $form-check-input-checked-before-opacity;
    }

    &:after {
      content: "";
      position: absolute;
    }

    &:focus {
      border-color: $form-check-input-checked-focus-border-color;

      &:before {
        box-shadow: $form-check-input-checked-focus-before-box-shadow;
        transform: $form-check-input-checked-focus-before-transform;
        transition: $form-check-input-checked-focus-before-transition;
      }
    }
  }

  &:indeterminate {
    &:focus {
      &:before {
        box-shadow: $form-check-input-indeterminate-focus-before-box-shadow;
      }
    }
  }

  &[type="checkbox"] {
    border-radius: $form-check-input-checkbox-border-radius;

    &:focus {
      &:after {
        content: "";
        position: absolute;
        width: $form-check-input-checkbox-focus-after-width;
        height: $form-check-input-checkbox-focus-after-height;
        z-index: 1;
        display: block;
        border-radius: 0;
        background-color: $form-check-input-checkbox-focus-after-background-color;
      }
    }

    &:checked {
      background-image: none;
      background-color: $form-check-input-checkbox-checked-background-color;

      &:after {
        display: block;
        transform: $form-check-input-checkbox-checked-after-transform #{"/*!rtl:ignore*/"};
        border-width: $form-check-input-checkbox-checked-after-border-width;
        border-color: $form-check-input-checkbox-checked-after-border-color;
        width: $form-check-input-checkbox-checked-after-width;
        height: $form-check-input-checkbox-checked-after-height;
        border-style: solid;
        border-top: 0;
        border-left: 0 #{"/*!rtl:ignore*/"};
        margin-left: $form-check-input-checkbox-checked-after-margin-left;
        margin-top: $form-check-input-checkbox-checked-after-margin-top;
        background-color: transparent;
      }

      &:focus {
        background-color: $form-check-input-checkbox-checked-focus-background-color;
      }

      &:disabled {
        border-color: #757575;
        background-color: #757575;
      }
    }

    &:indeterminate {
      background-image: none;
      background-color: transparent;
      border-color: $form-check-input-indeterminate-border-color;

      &:after {
        display: block;
        transform: $form-check-input-indeterminate-checked-after-transform #{"/*!rtl:ignore*/"};
        border-width: $form-check-input-indeterminate-checked-after-border-width;
        border-color: $form-check-input-indeterminate-checked-after-border-color;
        width: $form-check-input-indeterminate-checked-after-width;
        height: $form-check-input-indeterminate-checked-after-height;
        border-style: solid;
        border-top: 0;
        border-left: 0 #{"/*!rtl:ignore*/"};
        margin-left: $form-check-input-indeterminate-checked-after-margin-left;
        margin-top: 0;
      }

      &:focus {
        background-color: $form-check-input-indeterminate-focus-background-color;
        border-color: $form-check-input-indeterminate-focus-border-color;
      }
    }
  }

  &[type="radio"] {
    border-radius: $form-check-input-radio-border-radius;
    width: $form-check-input-radio-width;
    height: $form-check-input-radio-height;

    &:before {
      width: $form-check-input-radio-before-width;
      height: $form-check-input-radio-before-height;
    }

    &:after {
      content: "";
      position: absolute;
      width: $form-check-input-radio-after-width;
      height: $form-check-input-radio-after-height;
      z-index: 1;
      display: block;
      border-radius: $form-check-input-radio-after-border-radius;
      background-color: $form-check-input-radio-after-background-color;
    }

    &:checked {
      background-image: none;
      background-color: $form-check-input-radio-checked-background-color;

      &:after {
        border-radius: $form-check-input-radio-checked-after-border-radius;
        width: $form-check-input-radio-checked-after-width;
        height: $form-check-input-radio-checked-after-height;
        border-color: $form-check-input-radio-checked-after-border-color;
        background-color: $form-check-input-radio-checked-after-background-color;
        margin-top: $form-check-input-radio-checked-after-margin-top;
        margin-left: $form-check-input-radio-checked-after-margin-left;
        transition: $form-check-input-radio-checked-after-transition;
      }

      &:focus {
        background-color: $form-check-input-radio-checked-focus-background-color;
      }
      &:disabled {
        &:after {
          background-color: #757575;
          margin-top: 0.0755rem;
        }
        border-color: #757575;
      }
    }
  }
}

.form-check-label {
  &:hover {
    cursor: pointer;
  }
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-left;

  .form-check-input {
    background-image: none;
    border-width: 0;
    border-radius: $form-switch-form-check-input-border-radius;
    width: $form-switch-form-check-input-width;
    height: $form-switch-form-check-input-height;
    background-color: $form-switch-form-check-input-background-color;
    margin-top: $form-switch-form-check-input-margin-top;
    margin-right: $form-switch-form-check-input-margin-right;

    &:after {
      content: "";
      position: absolute;
      border: none;
      z-index: 2;
      border-radius: $form-switch-form-check-input-after-border-radius;
      width: $form-switch-form-check-input-after-width;
      height: $form-switch-form-check-input-after-height;
      background-color: $form-switch-form-check-input-after-background-color;
      margin-top: $form-switch-form-check-input-after-margin-top;
      box-shadow: $form-switch-form-check-input-after-box-shadow;
      transition: $form-switch-form-check-input-after-transition;
    }

    &:focus {
      background-image: none;

      &:before {
        box-shadow: $form-switch-form-check-input-focus-before-box-shadow;
        transform: $form-switch-form-check-input-focus-before-transform;
        transition: $form-switch-form-check-input-focus-before-transition;
      }

      &:after {
        border-radius: $form-switch-form-check-input-focus-after-border-radius;
        width: $form-switch-form-check-input-focus-after-width;
        height: $form-switch-form-check-input-focus-after-height;
      }
    }

    &:checked {
      background-image: none;

      &:focus {
        background-image: none;

        &:before {
          margin-left: $form-switch-form-check-input-checked-focus-before-margin-left;
          box-shadow: $form-switch-form-check-input-checked-focus-before-box-shadow;
          transform: $form-switch-form-check-input-checked-focus-before-transform;
          transition: $form-switch-form-check-input-checked-focus-before-transition;
        }
      }

      &[type="checkbox"] {
        background-image: none;

        &:after {
          content: "";
          position: absolute;
          border: none;
          z-index: 2;
          border-radius: $form-switch-form-check-input-checked-checkbox-after-border-radius;
          width: $form-switch-form-check-input-checked-checkbox-after-width;
          height: $form-switch-form-check-input-checked-checkbox-after-height;
          background-color: $form-switch-form-check-input-checked-checkbox-after-background-color;
          margin-top: $form-switch-form-check-input-checked-checkbox-after-margin-top;
          margin-left: $form-switch-form-check-input-checked-checkbox-after-margin-left;
          box-shadow: $form-switch-form-check-input-checked-checkbox-after-box-shadow;
          transition: $form-switch-form-check-input-checked-checkbox-after-transition;
        }
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .form-check {
    .form-check-input {
      &[type="radio"] {
        margin-left: -1.44rem;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .form-check {
    .form-check-input {
      &[type="radio"] {
        margin-left: -1.84rem;
      }
    }
  }
}
