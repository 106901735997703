/* Wellness */
.scMedicaidRewads {
  table,
  th,
  td {
    border: 1px solid #000000;
  }
}
.wellness-page {
  .page-heading {
    margin-bottom: 20px;
  }
  // padding-bottom: 13rem;
  .no-tab-title {
    padding-top: 3.2rem;
    .no-records {
      color: #ff0000;
      margin-bottom: 356px;
      font: normal normal normal 0.88rem/1.04rem Arial;
    }
  }

  .assessment-content-1 {
    text-align: left;
    font: normal normal 0.88rem/1.12rem Campton-medium;
    letter-spacing: 0.31px;
    color: #000000;
  }

  .question-content {
    padding-bottom: 0.88rem;
  }

  .question {
    padding-top: 2.6rem;
  }

  .assessment-content-2 {
    text-align: left;
    font: normal normal normal 0.88rem/1.12rem Arial;
    letter-spacing: 0.26px;
    color: #000000;
  }

  .assessment-content-3 {
    text-align: left;
    font: normal normal 0.88rem/1.12rem campton-medium;
    letter-spacing: 0.26px;
    color: #000000;
  }

  .assessment-content-2-span {
    text-align: left;
    font: normal normal bold 0.88rem/1.12rem Arial;
    letter-spacing: 0.26px;
    color: #000000;
  }

  .assessment-padding {
    padding-top: 1.4rem;
  }

  .assessment-thra {
    text-align: left;
    font: normal normal 0.88rem/0.96rem campton-medium;
    letter-spacing: 0.26px;
    color: #ff0000;
    padding-top: 1.8rem;
  }

  .assessment-thra-child {
    text-align: left;
    font: normal normal 0.88rem/1.12rem campton-medium;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 1.8rem;
  }

  .crisis {
    padding-top: 0.8rem;
  }
  .assessment-directive {
    text-align: left;
    font: normal normal 0.88rem/1.12rem campton-medium;
    letter-spacing: 0.26px;
    color: #000000;
    padding-top: 1.8rem;
  }

  // .question-1{
  //   padding-top: 0.6rem;
  // }

  .assessment-directive-question,
  .question-1 {
    padding-top: 1.8rem;
  }

  .no-assessment-records {
    color: #ff0000;
    font: normal normal normal 0.8rem/0.92rem Arial;
    margin-bottom: 356px;
    margin-top: -1.313rem;
  }
  .no-assessment-records1 {
    color: #ff0000;
    font: normal normal normal 0.8rem/0.92rem Arial;
    margin-bottom: 356px;
    margin-top: 2.13rem;
  }
  .row-title {
    padding-bottom: 0.75rem;
  }
  .describe {
    height: 112px;
  }
  .dynamic-data {
    margin-top: 3.3rem;
    padding-left: 4.06rem;
    padding-right: 4.06rem;
    .form-check-input {
      //margin-right: unset;
      label {
        vertical-align: middle;
      }
      .row-title {
        font: normal normal normal 0.9rem/1rem Arial;
      }
    }
    .modal-footer {
      border-top: 1px solid #e0e0e0 !important;
    }
    .form-check {
      margin-bottom: 12px;
      label {
        font: normal normal normal 0.88rem/1.04rem Arial;
        vertical-align: super;
        width: 93%;
        display: table-row;
      }
    }
    .row-data {
      padding-left: 36px;
      position: relative;
    }
    .hide-question {
      padding-bottom: 0 !important;
      .row-data {
        padding-bottom: 0;
      }
    }
    .spacing-left {
      padding-left: 36px;
    }
    .form-check-inline {
      margin-bottom: 1rem;
      label {
        font: normal normal normal 0.9rem/1rem Arial;
      }
    }
    .space-between {
      .numbers {
        width: 40px;
        position: absolute;
        left: 0;
      }
    }
    .MuiFormControl-marginNormal {
      margin-top: unset;
      margin-bottom: unset;
    }
    // .molina-select-wrapper{
    //   margin-left: 30px;
    // }
    .dynamic-btn {
      float: right;
      .draft-btn {
        color: #009ea0;
        margin-right: 15px;
      }
      .next-btn {
        background: #009ea0;
        color: #ffffff;
      }
      .clear-btn {
        color: #009ea0;
        margin-top: 30px;
        box-shadow: none;
        float: right;
        font: normal normal bold 0.9rem/1rem Arial;
        margin-right: -17px;
      }
    }
  }
  .border {
    border: 1px solid #707070;
    border-radius: 12px;
    padding: 0rem 1rem 1rem;
    margin-top: 20px;
  }

  .form-check-input {
    font: normal normal normal 0.88rem/1.04rem Arial;
  }
  .list-desc-title {
    color: #000;
    font: normal normal 0.72rem/0.92rem campton-medium;
    opacity: inherit;
    font-weight: bolder;
  }
  .list-desc-details {
    color: #454545;
    font: normal normal normal 0.72rem/0.84rem Arial;
    opacity: inherit;
  }
  .nav-tabs .nav-link {
    letter-spacing: 1.35px;
    color: #454545;
    text-transform: capitalize;
  }
  .list-group-item {
    margin-bottom: 1.0368rem;
    padding: 1.5rem;
  }
  .field1 {
    justify-content: space-around;
  }
  .contact-us {
    font: normal normal normal 0.72rem/0.84rem Arial;
    padding-left: 12px;
  }
  .wellness-back {
    .list-desc-title {
      font: normal normal 0.88rem/1.12rem Campton-medium;
      color: #212121;
      padding-bottom: 0.4rem;
    }
    .list-desc-details {
      color: #575757;
      letter-spacing: 0.18px;
    }
    .field1 {
      padding-bottom: 1.76rem;
    }
    .left-arrow-custom {
      transform: rotate(0);
    }
  }
  .no-tab-title.wellness-back {
    padding-top: unset;
  }
  .progress {
    height: 4px;
    max-width: 43%;
    min-width: 10%;
    margin: 0 0.8rem;
    transform: translate(0px, -50px);
    display: inline-flex;
  }
  .step:after {
    width: 150px;
    display: block;
    transform: translate(-46px, 12px);
    font: normal normal 18px/26px Campton-medium;
    color: #000000;
    content: attr(data-desc);
  }

  #steps {
    padding-left: 4.06rem;
    padding-right: 4.06rem;
  }
  .step {
    width: 70px;
    height: 70px;
    display: inline-block;
    border: 5px solid;
    border-color: #e1e1e1;
    border-radius: 50%;
    color: #e1e1e1;
    font-weight: 600;
    text-align: center;
    line-height: 62px;
    font-size: 1.28rem;
  }
  .step.active {
    color: #009ea0;
    border-color: #009ea0;
  }
  .tick {
    background-color: #009ea0;
    border-color: #009ea0 !important;
  }
  .bottom-divider {
    margin-top: 3.2rem;
  }
  .bottom-para {
    text-align: left;
    font: normal normal normal 0.88rem/1.12rem Arial;
    letter-spacing: 0.26px;
    color: #454545;
    padding: 0.5rem 2.2rem 1rem 1.3rem;
  }
  //MyWellness health reminder//
  .health-reminder {
    //padding-top: 2.153rem;
    .pagination {
      font: normal normal normal 24px/28px Arial;
      margin-bottom: 11px;
      margin-top: unset;

      img {
        width: 30px;
        margin-bottom: 7px;
      }
    }
    .details {
      .list-desc-title {
        padding-bottom: unset;
        padding-right: 10px;
      }
    }
  }

  //MyWellness health reminder End//
  .questions {
    margin-bottom: 1rem;
    font: normal normal normal 0.9rem/1rem Arial;
    margin-top: 1rem;
  }
  .space-between > div {
    padding-bottom: 1.5rem;
  }
  .grid-2col-container {
    grid-gap: 21px;
  }
}

.mywellness-modal {
  .a-link {
    color: $teal;
  }
}
.assessment-modal {
  .assessment-heading {
    font: normal normal 26px/33px Campton-medium;
    color: #000000;
  }
  .assessment-para {
    font: normal normal normal 22px/26px Arial;
    letter-spacing: 0.26px;
    color: #454545;
  }
  .assessment-btns {
    text-align: end;
    .okay-btn {
      width: 99px;
      height: 44px;
      background: #009ea0 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      border-radius: 5px;
      font: normal normal 18px/23px Campton-medium;
      color: #ffffff;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .wellness-page {
    .list-icon-group img {
      height: 27px;
    }

    .list-icon-open img {
      height: 27px;
    }
    .step {
      width: 45px;
      height: 45px;
      border: 4px solid;
      line-height: 37px;
      //font-size: larger;
    }
    .progress {
      transform: translate(0px, -34px);
    }
    .step:after {
      transform: translate(-56px, -5px);
      padding: 7px;
    }
    .dynamic-data {
      .row-data {
        padding-left: 28px;
      }
      .spacing-left {
        padding-left: 28px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .wellness-page {
    .wellness-back {
      #steps {
        padding-left: unset;
        padding-right: unset;
      }
      .progress {
        min-width: 10%;
      }
      .dynamic-data {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .wellness-page {
    .dynamic-data {
      .form-check {
        label {
          line-height: 1.2rem;
        }
      }
      .row-data {
        padding-left: 25px;
      }
      .spacing-left {
        padding-left: 25px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .wellness-page {
    .list-icon-group img {
      height: 24px;
    }
    .list-icon-open img {
      height: 24px;
    }

    .list-desc-title {
      font-size: 22px;
      line-height: 28px;
      width: auto;
      font-weight: normal;
    }
    .no-tab-title {
      padding-top: 4rem;
      .no-records {
        margin-bottom: 673.25px;
        font: normal normal normal 20px/23px Arial;
        margin-left: 10px;
      }
    }
    .assessment-content-1 {
      font: normal normal normal 18px/21px Arial;
    }
    .assessment-content-2,
    .assessment-thra,
    .assessment-thra-child,
    .assessment-content-2-span,
    .assessment-directive {
      font: normal normal normal 18px/21px Arial;
    }

    .assessment-content-3 {
      font: normal normal 18px/21px campton-medium;
    }

    .crisis {
      padding-top: 0.8rem;
    }
    .no-assessment-records {
      font: normal normal normal 20px/23px Arial;
      margin-bottom: 673.25px;
      margin-left: 10px;
    }
    .no-assessment-records1 {
      color: #ff0000;
      font: normal normal normal 20px/23px Arial;
      margin-bottom: 673.25px;
      margin-top: 2.13rem;
    }
    .field1 {
      padding-top: 15px;
      .text-right {
        top: 16px;
        right: 19px;
      }
    }
    .list-group-item {
      padding: 50px 32px 24px 32px;
    }
    .list-desc-details {
      font: normal normal normal 0.72rem/0.84rem Arial;
      letter-spacing: 0.18px;
      padding-bottom: 1.3rem;
      width: auto;
    }
    .bottom-divider {
      display: none;
    }
    .contact-us {
      font: normal normal bold 18px/22px Arial;
    }
    .tab-sub-title {
      font: normal normal 25px/37px Campton-medium;
      padding-bottom: 1.5rem;
    }
    .step {
      width: 28px;
      height: 28px;
      border: 2px solid;
      line-height: 25px;
      font-size: 14px;
      img {
        width: 18px;
      }
    }
    .step:nth-child(n + 2) {
      margin: unset;
    }
    .step:after {
      font: normal normal normal 12px/15px Arial;
      transform: translate(-61px, -5px);
    }
    .bottom-para {
      padding: 3rem 0 12rem 0.5rem;
      letter-spacing: 0px;
    }
    .bottom-para p {
      margin-bottom: 16px;
      font: normal normal normal 18px/27px Arial;
    }
    .progress {
      transform: translateY(-21px);
      min-width: 7% !important;
      margin: 0 4px;
    }
    .dropdown-divider {
      margin-top: 14px;
    }
    //HealthReminder//
    .health-reminder {
      // padding-top: 0.938rem;
      .pagination {
        font: normal normal bold 0.72rem/0.84rem Arial;
        margin-bottom: 17px;
        img {
          width: 20px;
        }
      }
      .details {
        display: grid;
        .list-desc-title {
          padding-bottom: 8px;
        }
      }
    }
    //End//
    .dynamic-data {
      padding-left: unset;
      padding-right: unset;
      .row-data {
        padding-left: 26px;
      }
      .spacing-left {
        padding-left: 26px;
      }
      .row-title {
        font: normal normal normal 18px/21px Arial;
      }
      .space-between > div {
        padding-bottom: 2.5rem;
      }
      .form-check-inline {
        margin-left: 3px;
        label {
          font: normal normal normal 18px/21px Arial;
        }
      }

      .form-check {
        label {
          font: normal normal normal 18px/21px Arial;
        }
      }

      .questions {
        font: normal normal normal 18px/21px Arial;
      }

      .space-between {
        select {
          height: 46px;
        }
        .form-select {
          font: normal normal normal 16px/24px Arial;
        }
      }
      .dynamic-btn {
        text-align: right;
        .draft-btn {
          margin-right: 0px;
          font: normal normal 14px/20px Campton-medium;
          margin-bottom: 15px;
        }
        .tick {
          img {
            width: 15px;
            height: 15px;
          }
        }
        .next-btn {
          font: normal normal 14px/20px Campton-medium;
          margin-bottom: 15px;
          margin-left: 15px;
        }
        .clear-btn {
          margin-top: 15px;
          margin-right: -20px;
        }
        .form-check {
          margin-bottom: 20px;
          label {
            vertical-align: middle;
            margin-left: 12px;
          }
        }
      }
    }
  }
  .assessment-modal {
    .assessment-heading {
      font: normal normal 22px/28px Campton-medium;
    }
    .assessment-para {
      font: normal normal normal 16px/24px Arial;
      letter-spacing: 0px;
    }
  }
  .tab-content .accordion-md {
    padding-top: 1.781rem;
  }
}

@include media-breakpoint-down(lg) {
  .wellness-page {
    .list-desc-title,
    .list-desc-details {
      word-break: break-all;
    }
    .wellness-back {
      .field1 {
        padding-bottom: unset;
        padding-top: unset;
      }
      .list-desc-details {
        font: normal normal normal 18px/21px Arial;
        color: #575757;
        padding-bottom: 1.3rem;
      }
      .list-desc-title {
        font: normal normal 22px/28px Campton-medium;
        padding-bottom: 0.7rem;
      }
    }
    .list-group-item {
      padding: 1.5rem;
    }
    .field1 .text-right {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}

.rewards-error-check {
  display: flex !important;
  color: #ef2e2e;
  font-size: .7rem;
}

.rewards-checkbox {
  width: 3%;
}

.rewards-drpdwn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}