.coverage-page {
  .plan-year-block {
    padding-top: 5px;
    > div {
      display: inline-block;
      font: normal normal normal 0.88rem/1.12rem 'campton-medium';
      letter-spacing: 0px;
      color: #212121;
      select {
        width: 138px;
        margin-left: $spacer * 1.563;
        font: normal normal normal 0.8rem/0.8rem Arial;
        letter-spacing: 0.19px;
        border-radius: 4px;
        padding: 18px 15px 16px 29px;
      }
    }
    .molina-select__menu {
      font: normal normal normal 0.8rem/0.8rem Arial;
    }
  }
  .eligibility-block {
    margin-top: $spacer * 3.438;
    margin-bottom: $spacer * 4.063;

    .card-title {
      font: normal normal normal 1.2rem/1.52rem 'campton-medium';
      letter-spacing: 0px;
    }
  }

  .eligibility-details {
    margin-bottom: $spacer * 2;
  }

  .trackmyspending {
    margin-bottom: $spacer * 5.625;
    .card-body {
      //margin-bottom: $spacer * 2;
      .card-title {
        margin-bottom: 1.8rem;
      }
    }
  }
  .molina-progress-bar-list {
    display: block;
  }
  .track-more-details {
    color: $gray-1;
    border-left: 1px solid $black;
    &:hover {
      border: none;
    }
  }
  .track-more-details:hover {
    text-decoration: underline;
  }

  hr {
    border: 1px solid #c4c4c4;
    margin-right: 54px;
  }
  .track-msg-tip {
    margin-right: 54px;
  }
  .cost-estimator-title {
    font: normal normal normal 1.04rem/1.32rem 'campton-medium';
  }

  .cost-estimator-desc {
    color: $gray-1;
    font: normal normal normal 0.64rem/0.84rem campton-book;
  }
  .cost-estimator-btn {
    button {
      font: normal normal normal 0.72rem/0.92rem 'campton-medium';
      text-transform: uppercase;
    }
  }
  .cost-estimator-centered {
    display: grid;
    vertical-align: middle;
    align-items: center;
  }
  .cost-estimator {
    box-shadow: 0px 2px 2px #00000029;
    border: 1px solid #d8d5d5;
    border-radius: 10px;
    padding: 2rem;
    margin-right: 6px;
    .cost-estimator-title {
      margin-bottom: $spacer * 1.5;
    }

    .cost-estimator-desc {
      margin-bottom: $spacer * 2.25;
    }
  }
  .cost-estimator-block {
    margin-bottom: $spacer * 5.625;
    .cost-estimator-title {
      margin-bottom: $spacer * 0.438;
    }
    .cost-estimator-title + .row {
      margin-bottom: $spacer * 2;
    }
    .cost-estimator-desc {
      margin-top: $spacer * 1.375;
    }

    .cost-estimator-btn {
      position: relative;
      height: 100%;
      margin-right: 6px;
      button {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .custom-modal-md {
    .back-link {
      .back-link-arrow {
        margin-bottom: 3px;
        margin-right: 7px;
      }

      .back-link-title {
        display: inline;
        color: #000000;
      }
    }
  }
  .custom-modal-lg {
    .back-link {
      .back-link-arrow {
        margin-bottom: 3px;
        margin-right: 7px;
      }

      .back-link-title {
        display: inline;
        color: #000000;
      }
    }
  }

  .molina-select-wrapper {
    width: 138px;
    margin-left: 25px;

    .molina-select__control {
      border-radius: 4px !important;
      padding-left: 25px;
    }

    .fa-caret-down {
      font-size: 20px;
    }
  }

  .molina-select__control {
    font: normal normal normal 0.8rem/0.8rem Arial;
  }
}
.mycoverage-modal {
  .table {
    border: 1px solid $gray-3;
    box-shadow: 0px 2px 12px #0000001f;
    th {
      font: normal normal normal 0.88rem/0.88rem Campton-medium;
      letter-spacing: 1.01px;
      padding: $spacer * 1.266 $spacer * 1.5;
      text-align: left;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    tbody {
      max-height: 511px;
      overflow-y: auto;
      display: block;

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 12px;
        background-color: #dedede;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #dedede;
      }
      td:first-child {
        padding: $spacer * 0.969 $spacer * 1.875;
      }
      td {
        font: normal normal normal 0.72rem/0.88rem Arial;
        letter-spacing: 0.22px;
        padding: $spacer * 0.969 $spacer * 1.625;
        .card-link {
          color: $teal;
          font-weight: bold;
        }
      }
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 52%;
    }
  }
  .show-more-btn {
    display: none;
  }
  .estimated-cost-title {
    padding: 40px 0px 12px 0px;
    text-align: center;
    font: normal normal normal 1.12rem/1.4rem 'campton-book';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .estimated-cost-subeading {
    text-align: center;
    font: normal normal normal 1.44rem/1.84rem 'campton-semibold';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .for-ZIP-text {
    font: normal normal normal 1.12rem/1.4rem 'campton-book';
    display: inline-block;
    color: #000000;
  }
  .parent-zip {
    padding: 20px 0px 30px 0px;
    text-align: center;
  }
  .estimated-cost-zipcode {
    padding: 0px 30px 0px 16px;
    font: normal normal normal 1.68rem/1.4rem 'campton-medium';
    color: #000000;
  }
  .estimated-cost-notzipcode {
    padding: 0px 0px 0px 0px;
    font: normal normal normal 0.72rem/0.96rem Arial;
    display: inline-block;
    text-align: left;
    color: #454545;
  }
  .estimated-cost-zipcodeupdate {
    color: $teal;
    // padding-left: 225px;
    font: normal normal normal 0.72rem/0.96rem Arial;
    // display: block;
  }
  .estimated-cost-description {
    font: normal normal normal 0.88rem/0.96rem Arial;
    padding-bottom: $spacer * 2;
    text-align: center;
    letter-spacing: 0.26px;
    color: #454545;
    opacity: 1;
  }
  .estimated-cost-card-left {
    min-width: 406px;
    // height: 166px;
    // margin: 30px 30px 30px 30px;
    // margin-left: 203px;
    background-color: #f2fafa;
    border: 1px solid #009ea0;
    border-radius: 16px;
    float: right;
    text-align: center;
    padding: 30px 30px 30px 30px;
    // padding: 30px 30px 30px 30px;
  }
  .estimated-cost-card-right {
    min-width: 406px;
    // height: 166px;
    // margin-left: 315px;
    background-color: #f2fafa;
    // margin: 30px 30px 30px 30px;
    border: 1px solid #009ea0;
    border-radius: 16px;
    float: left;
    text-align: center;
    padding: 30px 30px 30px 30px;

    // padding: 30px 30px 30px 30px;
  }

  .estimated-cost-backgroundimage-header {
    font: normal normal normal 0.88rem/2.12rem 'campton-medium';
    text-align: center;
    // padding: 30px 30px 0px 97px;
    color: #454545;
    opacity: 1;
  }
  .estimated-cost-backgroundimage-subheading {
    font: normal normal normal 1.64rem/2.4rem 'campton-semibold';
    text-align: center;
    // padding: 0px 0px 30px 0px;
    color: #000000;
    opacity: 1;
  }

  .estimated-cost-disclaimer {
    padding: 20px 0px 60px 0px;
    font: normal normal normal 0.72rem/0.84rem Arial;
    text-align: center;
    letter-spacing: 0px;
    color: #454545;
    opacity: 1;
  }
  .estimated-cost-print {
    background-color: #009ea0;
    color: white;
    width: auto;
    text-align: center;
    padding: 11px 24px 10px 24px;
    font: normal normal normal 0.72rem/0.92rem 'campton-medium';
    box-shadow: 0px 2px 2px #0000003d;
    border-radius: 5px;
  }
  .print-parent {
    text-align: center;
    margin: auto;
  }
  .estimated-cost-start-over {
    color: #009ea0;
    padding: 20px 0px 0px 0px;
    text-align: center;
    font: normal normal normal 0.72rem/0.92rem 'campton-medium';
    .card-link {
      color: #009ea0;
      font-weight: 700;
    }
  }
}
.track-msg-tip {
  font: normal normal normal 0.64rem/0.84rem 'campton-book';
  letter-spacing: 0px;
  color: #454545;
  margin-top: 4px;
}

@include media-breakpoint-down(xxl) {
  .coverage-page {
    .plan-year-block {
      padding-top: 0px;
      margin-top: -5px;
      > div {
        //font: normal normal normal 0.88rem/1.12rem "campton-medium";
        select {
          letter-spacing: 0.19px;
          padding: 18px 18px 16px 18px;
        }
      }
    }
    .eligibility-block {
      .card-title {
        //font: normal normal normal 25px/32px Campton-medium;
        margin-bottom: $spacer * 1.813;
      }
    }
    hr {
      margin-right: 25px;
    }
    .track-msg-tip {
      margin-right: 25px;
      //font: normal normal normal 14px/17px Arial;
      color: $list-desc-details;
      opacity: 0.8;
    }
    .row-details {
      //font: normal normal normal 18px/21px Arial;
      letter-spacing: 0.18px;
    }
    .cost-estimator-centered {
      margin-top: 1rem;
    }
  }
  .mycoverage-modal {
    .table {
      tbody {
        max-height: 300px;
      }
    }
    .estimated-cost-title {
      font: normal normal normal 28px/50px 'campton-book';
    }

    .estimated-cost-card-left {
      min-width: 300px;
      float: right;
      padding: 10px 30px 10px 30px;
    }
    .estimated-cost-card-right {
      float: left;
      min-width: 300px;
      padding: 10px 30px 10px 30px;
    }

    // .estimated-cost-disclaimer {
    //   font: normal normal normal 14px/18px 'campton-book';
    // }

    // .estimated-cost-start-over {
    //   font: normal normal normal 16px/23px 'campton-medium';
    // }
  }
}
@include media-breakpoint-down(xl) {
  .coverage-page {
    .track-msg-tip {
      margin: $spacer * 0.719 0 $spacer * 1.25 0;
    }
    .row-title {
      padding-top: 22px;
      padding-bottom: 5px;
    }
    .trackmyspending {
      .family-spending {
        margin-top: 25.5px;
      }
    }
  }
  .mycoverage-modal {
    .estimated-cost-title {
      font: normal normal normal 18px/60px 'campton-book';
    }

    .estimated-cost-card-left {
      float: none;
      padding: 20px 25px 25px 20px;
    }
    .estimated-cost-card-right {
      float: none;
      padding: 20px 25px 25px 20px;
    }
  }
}
@include media-breakpoint-down(lg) {
  .coverage-page {
    .eligibility-block {
      margin-top: $spacer * 1.5;
      margin-bottom: $spacer * 1.875;
      .card-title {
        font: normal normal normal 25px/32px Campton-medium;
        margin-bottom: $spacer * 0.938;
      }
    }
    .eligibility-details {
      margin-bottom: 1rem;
    }
    hr {
      margin-right: 0px;
      border: none;
      margin: 0 0 0.5rem 0;
      border-top: 1px solid rgba(112, 112, 112, 0.37);
    }
    .track-msg-tip,
    .cost-estimator-desc {
      min-width: 246px;
      font: normal normal normal 14px/17px Arial;
    }

    .row-details {
      padding-bottom: 0px;
    }
    .cost-estimator {
      margin-top: $spacer * 1.25;
      margin-right: 0px;

      .cost-estimator-title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: $spacer;
      }
      .cost-estimator-desc {
        // font-size: 16px;
        // line-height: 24px;
        letter-spacing: 0.26px;
        margin-bottom: $spacer * 1.531;
      }
    }

    .track-more-details {
      display: block;
      border-left: none;
      padding-top: 7px;
      padding-left: 0px;
      margin-left: 0px;
    }
    .trackmyspending {
      margin-bottom: $spacer * 1.875;
      .card-title {
        margin-bottom: $spacer * 2.656;
      }
      .family-spending {
        margin-top: 25.5px;
      }
      .track-more-details {
        display: none;
      }
    }

    .cost-estimator-block {
      margin-bottom: $spacer * 1.875;
      .cost-estimator-title {
        margin-bottom: $spacer * 0.5;
      }
      .cost-estimator-title + .row {
        margin-bottom: $spacer * 1.5;
      }
      .cost-estimator-desc {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.26px;
        margin-bottom: $spacer * 1.5;
        margin-top: $spacer * 0.5;
      }

      .cost-estimator-btn {
        margin-right: 0px;
        text-align: center;
        button {
          position: initial;
        }
      }
    }
  }
  .mycoverage-modal {
    .estimated-cost-title {
      font: normal normal normal 18px/60px 'campton-book';
    }
    .estimated-cost-subeading {
      font: normal normal normal 18px/22px 'campton-bold';
    }
    .parent-zip {
      padding: 15px 0px $spacer 0px;
    }

    .estimated-cost-notzipcode {
      vertical-align: middle;
    }

    .estimated-cost-card-left {
      float: none;
      padding: 20px 25px 25px 20px;
      margin-bottom: 20px;
      margin-left: 30px;
      margin-right: 30px;
    }
    .estimated-cost-card-right {
      float: none;
      padding: 20px 25px 25px 20px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

@include media-breakpoint-down(md) {
  .coverage-page {
    .plan-year-block {
      padding-top: 0px;
      margin-top: 23px;
      > div {
        font: normal normal normal 18px/21px 'campton-medium';
        select {
          font: normal normal normal 20px/20px Arial;
          margin-left: $spacer;
          width: 100%;
        }
      }
      > div:nth-child(1) {
        width: 29%;
      }
      > div:nth-child(2) {
        width: 69%;
        display: inline-flex;
      }
    }
    .molina-select-wrapper {
      width: 220px;
    }
    .molina-select__control {
      font: normal normal normal 20px/20px Arial;
      letter-spacing: 0.19px;
    }
    .eligibility-details {
      margin-bottom: 0px;
    }
    .cost-estimator {
      margin: 20px -10.5px 0px -10.5px;
      padding: 1.5rem;
    }
    .trackmyspending {
      .card-body {
        margin-bottom: 0;
      }
    }
    .cost-estimator-btn button {
      letter-spacing: 0.36px;

      font-size: 18px;
      line-height: 23px;
    }
    .cost-estimator-block {
      .cost-estimator-title {
        font-size: 16px;
        line-height: 21px;
      }
      .cost-estimator-title + .row {
        margin-bottom: $spacer * 0.375;
      }
    }
    .custom-modal-md {
      .modal-body {
        padding: 67px 19px 24px 20px;
      }
      .back-link {
        .back-link-arrow {
          margin-bottom: 3px;
          margin-right: 7px;
        }

        .back-link-title {
          display: inline;
          color: #000000;
        }
      }
    }

    .custom-modal-lg {
      .modal-body {
        padding-top: 28px;
      }
      .back-link {
        .back-link-arrow {
          margin-bottom: 3px;
          margin-right: 7px;
        }

        .back-link-title {
          display: inline;
          color: #000000;
        }
      }
    }
  }
  .mycoverage-modal {
    .form-group {
      label > div {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: clip;
      }
    }
    .form-label-ZIP {
      font: normal normal normal 0.72rem/0.84rem Arial;
      letter-spacing: 0px;
      color: $black;
    }
    .table td:nth-child(2),
    .table th:nth-child(2) {
      width: 62%;
    }
    .table {
      border: 1px solid #cccccc;
      box-shadow: none;
      th {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0px;
        padding: 16px 12px;
      }
      tbody {
        max-height: initial;
        overflow-y: visible;
        td {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0px;
          color: $gray-1;
          padding: 16px 12px;
          .d-md-none {
            margin-top: $spacer * 0.766;
          }
          .card-link {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0px;
            color: #00a2ad;
          }
        }
      }
      th:last-child,
      td:last-child {
        display: none;
      }
    }
    .show-more-btn {
      display: block;
      text-align: center;
      margin-top: 0.897rem;
      text-transform: uppercase;
      .card-link {
        font: normal normal normal 0.72rem/0.92rem Campton-medium;
        letter-spacing: 0px;
        color: $teal;
      }
    }
    .estimated-cost-title {
      font: normal normal normal 18px/60px 'campton-book';
    }
    .estimated-cost-subeading {
      font: normal normal normal 18px/22px 'campton-bold';
    }
    .for-ZIP-text {
      padding: 0px 0px 0px 0px;
      font: normal normal normal 16px/60px 'campton-book';
    }
    .estimated-cost-zipcode {
      font: normal normal normal 16px/60px 'campton-book';
    }
    .estimated-cost-notzipcode {
      font: normal normal normal 13px/24px Arial;
    }
    .estimated-cost-zipcodeupdate {
      font: normal normal normal 13px/24px Arial;
    }
    .estimated-cost-description {
      font: normal normal normal 16px/20px 'campton-book';
    }
    .estimated-cost-card-left {
      float: none;
      min-width: 204px;
      padding: 21px 27px 21px 27px;
      margin-left: 30px;
      margin-right: 30px;
    }
    .estimated-cost-card-right {
      float: none;
      min-width: 204px;
      padding: 21px 27px 21px 27px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .estimated-cost-backgroundimage-header {
      font: normal normal normal 22px/60px Arial;
    }
    .estimated-cost-backgroundimage-subheading {
      font: normal normal bold 22px/24px Arial;
    }
    .estimated-cost-disclaimer {
      font: normal normal normal 14px/18px 'campton-book';
    }
    .estimated-cost-print {
      font: normal normal normal 18px/24px 'campton-medium';
    }
    .estimated-cost-start-over {
      font: normal normal normal 18px/24px 'campton-medium';
    }
  }
}

@include media-breakpoint-down(sm) {
  .coverage-page {
    .plan-year-block {
      padding-top: 0px;
      margin-top: 23px;
      > div {
        font: normal normal normal 0.72rem/0.84rem 'campton-medium';
      }
    }
    .molina-select-wrapper {
      width: 220px;
    }
  }
  .mycoverage-modal {
    .estimated-cost-title {
      padding: 7px 0px 13px 0px;
      font: normal normal normal 18px/60px 'campton-book';
    }
    .estimated-cost-subeading {
      font: normal normal normal 18px/22px 'campton-bold';
    }
    .for-ZIP-text {
      padding: 0px 0px 0px 0px;
    }
    .estimated-cost-notzipcode {
      padding: 15px 0px 0px 0px;
      font: normal normal normal 13px/24px Arial;
    }

    .estimated-cost-card-left {
      float: none;
      padding: 21px 27px 21px 27px;
      margin-left: 30px;
      margin-right: 30px;
    }
    .estimated-cost-card-left {
      float: none;
      padding: 21px 27px 21px 27px;
      margin-left: 30px;
      margin-right: 30px;
    }
    .estimated-cost-description {
      font: normal normal normal 16px/20px 'campton-book';
    }
    .estimated-cost-backgroundimage-header {
      font: normal normal normal 22px/40px Arial;
    }
    .estimated-cost-backgroundimage-subheading {
      font: normal normal bold 22px/24px Arial;
    }
    .estimated-cost-disclaimer {
      font: normal normal normal 14px/18px 'campton-book';
      padding: 26px 0px 36px 0px;
    }
    .estimated-cost-start-over {
      padding: 27px 0px 10px 0px;
      font: normal normal normal 18px/24px 'campton-medium';
    }
  }
}

.word-break {
  word-break: break-word;
}
