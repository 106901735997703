//
// Material styles for form control - form outline
//

.form-control {
  min-height: auto;
  // padding-top: 5.28px;
  // padding-bottom: 3.28px;
  padding: 15px 16.5px 15px 16.5px;
  transition: all 0.1s linear;
  letter-spacing: 0.15px;
  font: normal normal normal 0.64rem/0.96rem Arial;
  border-radius: 4px;
  color: #000000;
  &:hover {
    border: 1px solid #00000099;
  }
  &:focus,
  &:active {
    box-shadow: none;
    transition: all 0.1s linear;
    border: 2px solid $black;
    padding: 14px 15.5px 14px 15.5px;
    // box-shadow: inset 0px 0px 0px 1px #1266f1;
  }
  &:disabled {
    border: 1.1px solid #bcbcbc;
  }
  &.form-control-sm {
    font-size: 0.775rem;
    line-height: 1.5;
  }
  &.form-control-lg {
    line-height: 2.15;
    border-radius: 0.25rem;
  }
}

.form-outline {
  position: relative;
  .form-control {
    min-height: auto;
    padding-top: $input-padding-top;
    padding-bottom: $input-padding-bottom;
    padding-left: $input-padding-left;
    padding-right: $input-padding-right;
    border: 0;
    background: transparent;
    transition: $input-transition;
    ~ .form-label {
      position: absolute;
      top: 0;
      left: $form-label-left;
      padding-top: $form-label-padding-top;
      pointer-events: none;
      transform-origin: 0 0;
      transition: $form-label-transition;
      color: $form-label-color;
      margin-bottom: 0;
    }
    ~ .form-notch {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      text-align: left;
      pointer-events: none;
      div {
        pointer-events: none;
        border: $border-width solid;
        border-color: $form-notch-div-border-color;
        box-sizing: border-box;
        background: transparent;
      }
      .form-notch-leading {
        left: 0;
        top: 0;
        height: 100%;
        width: $form-notch-leading-width;
        border-right: none;
        border-radius: $form-notch-leading-border-radius 0 0 $form-notch-leading-border-radius;
      }
      .form-notch-middle {
        flex: 0 0 auto;
        width: auto;
        max-width: calc(100% - #{$form-notch-middle-max-width});
        height: 100%;
        border-right: none;
        border-left: none;
      }
      .form-notch-trailing {
        flex-grow: 1;
        height: 100%;
        border-left: none;
        border-radius: 0 $form-notch-trailing-border-radius $form-notch-trailing-border-radius 0;
      }
    }
    &:not(.placeholder-active)::placeholder {
      opacity: 0;
    }
    &:focus,
    &.active {
      &::placeholder {
        opacity: 1;
      }
    }
    &:focus {
      box-shadow: none !important;
    }
    &:focus ~ .form-label,
    &.active ~ .form-label {
      transform: $input-focus-active-label-transform;
    }
    &:focus ~ .form-label {
      color: $input-focus-label-color;
    }
    &:focus ~ .form-notch .form-notch-middle,
    &.active ~ .form-notch .form-notch-middle {
      border-top: none;
      border-right: none;
      border-left: none;
      transition: $input-transition;
    }
    &:focus ~ .form-notch .form-notch-middle {
      border-bottom: $input-focus-border-width solid;
      border-color: $input-focus-border-color;
    }
    &:focus ~ .form-notch .form-notch-leading,
    &.active ~ .form-notch .form-notch-leading {
      border-right: none;
      transition: $input-transition;
    }
    &:focus ~ .form-notch .form-notch-leading {
      border-top: $input-focus-border-width solid $input-focus-border-color;
      border-bottom: $input-focus-border-width solid $input-focus-border-color;
      border-left: $input-focus-border-width solid $input-focus-border-color;
    }
    &:focus ~ .form-notch .form-notch-trailing,
    &.active ~ .form-notch .form-notch-trailing {
      border-left: none;
      transition: $input-transition;
    }
    &:focus ~ .form-notch .form-notch-trailing {
      border-top: $input-focus-border-width solid $input-focus-border-color;
      border-bottom: $input-focus-border-width solid $input-focus-border-color;
      border-right: $input-focus-border-width solid $input-focus-border-color;
    }
    &:disabled,
    &.disabled,
    &[readonly] {
      background-color: $input-disabled-background-color;
    }
    &.form-control-lg {
      font-size: $input-font-size-lg;
      line-height: $input-line-height-lg;
      padding-left: $input-padding-left-lg;
      padding-right: $input-padding-right-lg;
      ~ .form-label {
        padding-top: $form-label-padding-top-lg;
      }
      &:focus ~ .form-label,
      &.active ~ .form-label {
        transform: $input-focus-active-label-transform-lg;
      }
    }
    &.form-control-sm {
      padding-left: $input-padding-left-sm;
      padding-right: $input-padding-right-sm;
      padding-top: $input-padding-top-sm;
      padding-bottom: $input-padding-bottom-sm;
      font-size: $input-font-size-sm;
      line-height: $input-line-height-sm;
      ~ .form-label {
        padding-top: $form-label-padding-top-sm;
        font-size: $form-label-font-size-sm;
      }
      &:focus ~ .form-label,
      &.active ~ .form-label {
        transform: $input-focus-active-label-transform-sm;
      }
    }
  }

  &.form-white {
    .form-control {
      color: $form-white-input-color;
      ~ .form-label {
        color: $form-white-label-color;
      }
      ~ .form-notch {
        div {
          border-color: $form-white-notch-div-border-color;
        }
      }
      &:focus ~ .form-label {
        color: $form-white-input-focus-label-color;
      }
      &:focus ~ .form-notch .form-notch-middle {
        border-color: $form-white-input-focus-border-color;
      }
      &:focus ~ .form-notch .form-notch-leading {
        border-top: $input-focus-border-width solid $form-white-input-focus-border-color;
        border-bottom: $input-focus-border-width solid $form-white-input-focus-border-color;
        border-left: $input-focus-border-width solid $form-white-input-focus-border-color;
      }
      &:focus ~ .form-notch .form-notch-trailing {
        border-color: $form-white-input-focus-border-color;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .form-control {
    padding: 10px 16.5px 10px 16.5px;
    font: normal normal normal 0.64rem/0.96rem Arial;
    border-radius: 5px;
    &:hover {
      border: 1px solid #00000099;
    }
    &:focus,
    &:active {
      border: 2px solid #000000;
      padding: 9px 15.5px 9px 15.5px;
    }
    &:disabled {
      border: 1px solid #999;
      color: #000000;
    }
  }
}
@include media-breakpoint-down(lg) {
  .form-control {
    padding: 10px 16.5px 10px 16.5px;
    font: normal normal normal 0.72rem/0.88rem Arial;
  }
}

@include media-breakpoint-down(md) {
  .form-control {
    padding: 10px 16.5px 10px 16.5px;
    font: normal normal normal 16px/24px Arial;
  }
}
