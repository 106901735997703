.Registration {
  margin-top: 72px;
  margin-bottom: 59px;
  font-family: 'campton-medium';
}
.pl-3 {
  padding-left: 3px;
}
.verify-section {
  display: flex;
  justify-content: center;
  @include media-breakpoint-down(md) {
    justify-content: left;
  }
}

.Registration,
.Registration_new {
  font-family: 'campton-medium';
  .css-yk16xz-control {
    border-radius: 4px !important;
  }
  .field-adjust-width {
    width: 18%;
    margin-right: 18px;
    display: inline-block;
  }
  .margin-tooltip-mo {
    display: flex;
    margin-left: 30px;
  }
  .field-adjust-width-ssn2 {
    width: 16%;
    margin-right: 18px;
    display: inline-block;
  }
  .field-adjust-width-zipCode {
    width: 40%;
  }
  .member-width-id {
    width: 88%;
  }
  .field-adjust-width-ssn3 {
    width: 25%;
    display: inline-block;
    margin-right: 12px;
  }
  .field-adjust-width-medicare {
    width: 30%;
    margin-right: 6px;
    display: inline-block;
  }
  .field-adjust-width-medicare2 {
    width: 25%;
    margin-right: 5px;
    margin-left: 6px;
    display: inline-block;
  }
  .field-adjust-width-medicare3 {
    width: 30%;
    display: inline-block;
    margin-left: 6px;
  }
  .mb-2 {
    margin-bottom: 2px;
  }
  .pl-0 {
    padding-left: 0px;
  }
  .pr-0 {
    padding-right: 0px;
  }
  .remove-margin {
    margin-top: 0;
  }
  .font-fill-indetails {
    letter-spacing: 0.1px;
    font: normal normal normal 0.77rem/1.12rem 'Campton-medium';
  }
  .align-member-id-required {
    span {
      display: contents;
    }
  }
  .terms-and-policy p.text-danger {
    font: normal normal normal 14px/13px 'Campton-medium';
  }
  .step-3 {
    word-break: break-all;
  }

  .molina-select-wrapper .molina-select__control {
    margin-bottom: 1.6rem;
  }

  .btn-1 {
    background-color: #fff;
    color: #009ea0;
  }

  .info-icon {
    margin-left: 7px;
  }

  .sign-link {
    margin-left: 14px;
  }

  .step3-error-msg {
    font: normal normal normal 12px/14px 'Campton-medium';
    margin-top: 2px;
    margin-bottom: 20px;
    height: 21px;
    padding-left: 4px;
    color: #ff0000;
  }

  .step.active:after {
    color: black;
  }

  .MuiInputBase-input {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    padding-left: 16.5px !important;
    border: 1px solid #999999 !important;
    border-radius: 4px !important;
    font: normal normal normal 0.72rem/0.96rem 'Campton-medium';
    @include media-breakpoint-down(md) {
      font: normal normal normal 18px/24px 'Campton-medium';
    }
  }

  p.MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
    font: normal normal normal 12px/13px 'Campton-medium' !important;
    margin-left: 3px;
  }

  .MuiFormControl-marginNormal {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  .pwd-icon {
    height: 24px;
    width: 24px;
  }

  .pass-wrapper {
    position: relative;
    i {
      position: absolute;
      right: 5%;
      top: 50%;
    }
  }

  .check-green {
    margin-right: 6px;
  }

  .validation-msg {
    font: normal normal normal 0.77rem 'Campton-medium';
    color: #ff0000;
    opacity: 1;
    text-align: left;
    margin: 6px 0px 20px;
  }
  .card {
    width: 935px;
    box-shadow: 0px 3px 6px #0000001f;
    border-radius: 12px;
  }

  .card-title {
    font: normal normal normal 1.36rem/0.96rem 'campton-medium';
  }
  // .card-body {
  //   padding: 40px 0px 43px 0px;
  // }
  .progress {
    height: 4px;
    max-width: 43%;
    min-width: 12%;
    transform: translate(0px, -50px);
    display: inline-flex;
    margin: 0.625rem;
  }
  .step:after {
    width: 150px;
    display: block;
    transform: translate(-46px, 12px);
    font: normal normal 0.72rem/1.04rem Campton-medium;
    // color: #000000;
    content: attr(data-desc);
  }
  .step {
    width: 70px;
    height: 70px;
    display: inline-block;
    border: 5px solid;
    border-color: #e1e1e1;
    border-radius: 50%;
    color: #e1e1e1;
    font-weight: 600;
    text-align: center;
    line-height: 62px;
    font-size: x-large;
  }
  .step.active {
    color: #009ea0;
    // border-color: #009ea0;
    box-shadow: 0px 3px 6px #00000029;
    border: 4px solid #009ea0;
    opacity: 1;
  }
  .tick {
    background-color: #009ea0;
    border-color: #009ea0 !important;
  }
  .password-recovery {
    .card {
      width: 531px;
    }
    .card-body {
      // padding: 42px 62px 40px 63px;
      .row-title {
        color: #454545;
      }
    }
    .card-title {
      margin-bottom: 1.52rem;
    }

    input[type='radio'] {
      /* Hide original inputs */
      display: none;
    }
    input[type='radio'] + label:before {
      height: 50px;
      width: 50px;
      margin-bottom: 16px;
      content: ' ';
      display: block;
      border: 3px solid #000000;
      border-radius: 50%;
    }
    input[type='radio']:checked + label:before {
      background: escape-svg($molina-custom-radio-select-svg);
      border: none;
    }

    .radio-button {
      margin-top: 66px;
      text-align: -webkit-center;
    }

    label {
      font: normal normal normal 0.72rem/1.04rem 'Campton-medium';
    }

    .sec-code {
      margin-bottom: 30px;
      color: #000000;
      span {
        word-break: break-all;
      }
    }
    .recovery-btn {
      margin-top: 48px;
      text-align: center;
    }
    .grid-item {
      input[type='password'] {
        margin-bottom: 40px;
      }
    }

    .user-id {
      display: grid;
      text-align: center;
      span {
        font: normal normal bold 0.72rem/1.08rem 'Campton-medium';
      }
    }
    .security {
      margin-top: 29px;
    }
  }
  //content starting
  .member-info {
    p {
      font: normal normal bold 0.88rem/1.04rem 'Campton-medium';
    }
    padding: 55px 207px 0px 222px;

    .form-gap {
      margin-bottom: 40px;
    }
    .registration-btn {
      text-align: center;
      // margin-top: 34px;
    }

    .sub-text {
      text-align: right;
      font: normal normal normal 0.77rem/1.12rem 'Campton-medium';
      letter-spacing: 0.47px;
      color: #ef2e2e;
    }

    .sub-gap {
      margin-bottom: 27px;
    }
    .form-check-input {
      margin-right: 9px;
    }
    .form-input {
      padding: 15px 16.5px 15px 16.5px;
      font: normal normal normal 0.64rem/0.96rem 'Campton-medium';
      border-radius: 5px;
      border: 1px solid #999999;
      width: 92%;
      margin-right: 12px;
    }
    a {
      font-weight: bold;
    }
    .step1-reg {
      margin-top: 36px;
      font: normal normal normal 0.72rem/0.84rem 'Campton-medium';
      text-align: center;
      @include media-breakpoint-down(md) {
        font: normal normal normal 18px/24px 'Campton-medium';
        span {
          padding-left: unset;
          padding-right: unset;
        }
      }
    }
    .row-sub-title {
      margin-bottom: 0;
      margin-left: 12px;
      font: normal normal normal 0.56rem/0.64rem 'Campton-medium';
      letter-spacing: 0px;
      color: #454545;
    }
    .ml-8 {
      margin-left: 8px;
    }
    .ml-6 {
      margin-left: 6px;
    }
    .row-inline {
      display: flex;
      align-items: baseline;
    }
    .text-check {
      font: normal normal normal 0.72rem/1.08rem 'Campton-medium';
      margin-bottom: 1.36rem;
    }
  }
  .btn {
    padding: 0.4rem 0.96rem 0.44rem 0.96rem;
    font: normal normal normal 0.72rem/0.8rem 'Campton-medium';
    text-transform: uppercase;
    border: 1px solid #999999;
    box-shadow: none;
  }
  .registration-btn1 {
    text-align: center;
    // margin-top: 46px;
  }
  .ripple-surface1 {
    margin-right: 16px;
  }
  .security {
    display: flex;
    justify-content: center;
  }
  .security-code {
    font: normal normal normal 0.72rem/1.08rem 'Campton-medium';
    color: #454545;
    margin-bottom: 33px;
    span {
      margin: 0px 5px;
    }
  }
  .row-title {
    font: normal normal normal 0.77rem/1.12rem 'Campton-medium';
    padding-bottom: 9px;
    @include media-breakpoint-down(md) {
      font: normal normal normal 18px/21px 'Campton-medium';
    }
  }
}
.Registration {
  .member-info {
    padding: 55px 20% 0 27%;
  }
}
@include media-breakpoint-up(xxl) {
  .Registration,
  .Registration_new {
    .MuiInputBase-input {
      padding-top: 17px !important;
      padding-bottom: 16px !important;
    }
    .member-info {
      padding: 55px 170px 0px;
    }
    .step:after {
      //white-space: nowrap;
      display: grid;
      transform: translate(-70px, 12px);
      width: 200px;
    }
    // .mb-5 {
    //   margin-bottom: unset !important;
    // }
  }
}
@include media-breakpoint-down(xxl) {
  .Registration {
    .member-info {
      .form-input {
        padding: 10px 16.5px 10px 16.5px;
      }
    }
    .step {
      width: 45px;
      height: 45px;
      border: 4px solid;
      line-height: 37px;
      font-size: larger;
      vertical-align: middle;
    }
    .progress {
      transform: translate(0px, 0px);
    }
    .step:after {
      transform: translate(-56px, 12px);
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .Registration,
  .Registration_new {
    .member-info {
      padding: 55px 70px 0px 70px;
    }
    .field-adjust-width-ssn2 {
      width: 15%;
      margin-right: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .Registration,
  .Registration_new {
    .btn {
      font: normal normal normal 16px/21px 'campton-medium';
      padding: 12px 22.5px;
    }
    .field-adjust-width {
      width: 22%;
      margin-right: 20px;
    }
    .margin-tooltip-mo {
      margin-left: 0px;
    }
    .field-adjust-width-ssn2 {
      width: 18%;
      margin-right: 20px;
    }

    .field-adjust-width-ssn3 {
      width: 27%;
      margin-right: 14px;
    }
    .field-adjust-width-zipCode {
      width: 40%;
    }
    .card-title {
      font: normal normal normal 25px/30px 'campton-medium';
    }
    .verificationFailedPopup {
      text-align: left;
    }
    .password-recovery {
      .card-body {
        padding: 33px 24px 34px 25px;
      }
      .card-title {
        font: normal normal normal 22px/28px 'campton-medium';
        margin-bottom: 30px;
      }
      .radio-button {
        margin-top: 44px;
      }
      .recovery-btn {
        margin-top: 30px;
      }
      .security {
        margin-top: -13px;
      }
      .user-id {
        span {
          font: normal normal bold 18px/27px 'Campton-medium';
        }
      }
      label {
        font: normal normal normal 18px/26px 'Campton-medium';
      }
    }
    .step {
      width: 31px;
      height: 31px;
      border: 3px solid;
      line-height: 25px;
      font-size: small;
    }
    .step.active {
      img {
        height: 15px;
      }
    }
    .step:nth-child(n + 2) {
      margin: unset;
    }
    .step:after {
      font: normal normal normal 12px/15px 'Campton-medium';
      transform: translate(-29px, 8px);
      width: 78px;
    }
    .progress {
      transform: translateY(-2px);
      margin-right: 0.3rem;
      margin-left: 0.3rem;
    }
    // .security-code {
    //   font: normal normal normal 18px/27px 'Campton-medium';
    // }
    .member-info {
      padding: 37px 0px 0px 0px;
      p {
        font: normal normal bold 20px/23px 'Campton-medium';
        // white-space: nowrap;
      }
      .row-sub-title {
        font: normal normal normal 14px/16px 'Campton-medium';
        margin-top: 8px;
        margin-left: 0px;
      }
      .row-inline {
        display: block;
      }
      .sub-text {
        text-align: left;
      }
      .form-input {
        width: 84%;
      }
      .text-check {
        font: normal normal normal 18px/27px 'Campton-medium';
        margin-bottom: 1.36rem;
      }
    }
    .security {
      flex-direction: column;
      margin: 24px auto 0px;
      .btn {
        width: 186px;
        margin: 0 auto;
      }
      .ripple-surface1 {
        margin-top: 13px;
        order: 2;
      }
    }
  }
}


.errorDisplay > div{
  display: inline;
}