.my-resources {
  .page-heading {
    margin-bottom: 20px;
  }
}
.my-resources-page {
  .accordion-header button {
    text-align: left;
  }
  .mac-section {
    .card-title {
      margin-bottom: 1.6rem;
      font: normal normal 1.2rem/1.92rem 'Campton-medium';
    }
    .card-para {
      font: normal normal normal 0.8rem Arial;
      color: #454545;
    }
  }
  .list-desc-title {
    padding-top: 0.6rem;
  }
  .list-icon-download img {
    padding-left: unset;
  }

  .disabled-link {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .row-label-style {
    text-align: center;
    font: normal normal 18px 'Campton-medium';
    padding-bottom: 7px;
    color: #000000;
  }

  .cards-tab3 .accordion-button:not(.collapsed) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .last-card-tab3 .accordion-button:not(.collapsed) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .loder-position {
    position: relative;
  }

  .cards {
    border-radius: 00.625rem;
    margin-bottom: 1.5rem;
  }
}

@include media-breakpoint-up(xxl) {
  .my-resources-page {
    //Styles for Forms&Docs
    .title1 {
      color: #000000;
      margin-bottom: 0.25rem;
      padding-top: 1.125rem;
    }

    .title2 {
      color: #000000;
      margin-bottom: 0.625rem;
    }

    .hr-divider {
      margin-top: 2.375rem;
      margin-bottom: 1.688rem;
    }

    .cards {
      padding: 1.68rem;
      padding-left: 1.56rem;
      padding-right: 1.375rem;
      margin-bottom: 1.5rem;
      border-radius: 00.625rem;
    }

    .last-card-tab1 {
      padding: 1.68rem;
      padding-left: 1.56rem;
      // margin-bottom: 4rem;
      padding-right: 1.375rem;
      border-radius: 00.625rem !important;
    }

    // Styles for Helpful Links
    .card-titles {
      color: #000000;
    }

    .list-icon {
      position: absolute;
      right: 1.591rem;
      top: 1.733rem;
      width: 2rem;
    }

    // .cards-section-tab2 {
    //   padding-top: 3rem;
    // }

    .cards-tab2 {
      margin-bottom: 1.25rem;
      border-radius: 00.625rem;
      padding-left: 1.563rem;
      padding-bottom: 1.125rem;
    }

    .content-tab2 {
      padding-bottom: 0.375rem !important;
    }

    .last-card-tab2 {
      // margin-bottom: 9.628rem;
      border-radius: 00.625rem;
      padding-left: 1.563rem;
      padding-bottom: 1.125rem;
    }

    //Styles for My Molina FAQs

    .last-card-tab3 {
      // margin-bottom: 3.378rem;
      border-radius: 00.625rem;
    }

    .cards-tab3 {
      margin-bottom: 1.313rem;
      border-radius: 00.625rem;
    }

    .redirect-icon-tab3 {
      top: 0.7552rem;
    }

    // .cards-dropdown
    .accordion-item .accordion-link {
      padding: 1.375 * 0.64rem 1.313 * 0.64rem 1.375 * 0.64rem 1.438 * 0.64rem;
    }
  }
}

@include media-breakpoint-down(md) {
  // Forms&Docs---Mobile View
  .my-resources-page {
    .title1 {
      padding-bottom: 0.438rem;
      padding-top: 2.094rem;
    }

    .mac-section {
      .card-title {
        margin-bottom: 1.6rem;
        font: normal normal 1.2rem/1.92rem 'Campton-medium';
      }
      .card-para {
        font: normal normal normal 0.8rem Arial;
        color: #454545;
      }
    }

    .row-label-style {
      text-align: center;
      font: normal normal 12px 'Campton-medium';
      padding-bottom: 7px;
      color: #000000;
    }

    .title2 {
      padding-bottom: 0.438rem;
      margin-bottom: 0.625rem;
    }

    .cards-md {
      margin-bottom: 1.5rem;
      border-radius: 00.625rem;
      padding-left: 1rem;
      padding-top: 1.125rem;
      padding-right: 0.938rem;
      border-style: unset;
    }

    .hr-divider {
      margin-top: 2.781rem;
      margin-bottom: 1rem;
    }

    .card-titles {
      padding-bottom: 1.25rem;
      padding-right: 45px;
    }

    .last-card-tab1 {
      // margin-bottom: 10.031rem;
      margin-bottom: 1.5rem;
    }

    .fax-title {
      padding-top: 0.844rem;
      text-align: left;
      font: normal normal bold 16px/26px Arial;
      letter-spacing: 0.12px;
      color: #151515;
      opacity: 0.75;
    }

    .list-desc-title {
      font-weight: bold;
      padding-top: 0.844rem;
    }

    .list-desc-details {
      text-align: left;
      padding-bottom: 0.5rem;
      padding-top: unset;
    }

    .address-title {
      opacity: 0.75;
      padding-top: 0.5rem;
      text-align: left;
      font: normal normal bold 16px/18px Arial;
      letter-spacing: 0px;
      color: #151515;
    }

    .address-details {
      opacity: 0.75;
      padding-bottom: 0.25rem;
      text-align: left;
      font: normal normal normal 16px/18px Arial;
      letter-spacing: 0px;
      color: #151515;
    }

    .text-right {
      margin-bottom: 0.25rem;
    }

    //HelpFul Links---Mobile View

    .no-tab-title {
      padding-top: 1.781rem;
    }

    .content-md-tab2 {
      display: block;
      padding-bottom: 0rem !important;
      margin-bottom: 00.563rem !important ;
      margin-left: 0.125rem;
      // max-height: 2.25rem;
      // overflow: hidden;
      // word-break: break-word;
    }

    .cards-md-tab2 {
      border-radius: 00.625rem;
      padding: 1.125rem 1.062rem 1rem 1rem;
      margin-bottom: 1.313rem;
    }

    .redirect-icon {
      height: 1.375rem;
      width: 1.375rem;
      margin-bottom: 0.5rem;
    }

    .last-card-tab2 {
      // margin-bottom: 7.226rem;
      margin-bottom: 0rem;
    }

    // My Molina FAQs----Mobile View

    .accordion-item .accordion-link {
      width: 90%;
    }

    .list-icon-newtab > img {
      height: 1.5rem;
      width: 1.5rem;
    }

    .last-card-tab3 {
      margin-bottom: 0rem;
      border-radius: 00.625rem;
    }

    .cards-tab3 {
      margin-bottom: 1.188rem;
      border-radius: 00.625rem;
    }

    .document-plan-year .plan-year-block {
      margin-bottom: 12px;
    }
  }

  .tab-content .faqs-md {
    padding-top: 1.781rem;
  }
}

@include media-breakpoint-down(xxl) {
  .document-plan-year .plan-year-block {
    margin-top: 12px !important;
  }

  .last-card-tab1 {
    border-radius: 00.625rem !important;
  }
}

.myvidoes-coming-soon {
  font-size: 14px;
  width: 80px !important;
  font-weight: bold;
  line-height: normal;
}