@media print {
  @page {
    size: 330mm 427mm;
    margin: 25mm 25mm 15mm 25mm;
  }
  body {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
  //Common//
  #header,
  .footeerwidth,
  .footer-wapper,
  .home-link,
  .page-heading,
  .nav-tabs,
  .show-more,
  .popup-btn,
  .grecaptcha-badge {
    display: none !important;
  }
  .container {
    max-width: 1900px;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  //Allergies//
  .service-disc,
  .pagination,
  .service-disc1,
  .molina-select-wrapper,
  .print-record a {
    display: none !important;
  }

  //Lab Results
  .page-subheading,
  .medications,
  .desc {
    display: none !important;
  }
  .report-date {
    display: block !important;
    font: normal normal normal 25px/26px Arial;
    .report-generate {
      font: normal normal bold 25px/26px Arial;
      margin-right: 5px;
    }
  }
  //Print heading
  .print-heading {
    display: block !important;
    font: normal normal bold 28px/26px Arial;
    text-align: center;
  }

  // Medications
  #medicationDate .view-details a {
    display: none !important;
  }

  #medicationDate .details {
    width: 33% !important;
  }

  /* My Messages -- start */
  .message-filters,
  .sort-container,
  .alert-wrapper,
  .message-details-view,
  .message-left-panel,
  .message-inbox-panel,
  .attachment-box,
  .message-details .show {
    display: none !important;
  }
  .inbox-container {
    .message-details {
      margin-top: -55px;
      padding-top: 10px !important;
      height: auto;

      hr {
        border-top: 1px solid #bababa;
      }
    }
  }
  /* My Messages -- end */

  /* Claims History - Start */

  #ClaimsHistory .claim-items {
    display: none !important;
  }

  #ClaimsHistory .green-bg {
    background: #4ce56f 0 0 no-repeat padding-box !important;
    -webkit-print-color-adjust: exact;
    width: 40% !important;
  }
  #ClaimsHistory .orange-bg {
    background: #ffdb62 0 0 no-repeat padding-box !important;
    -webkit-print-color-adjust: exact;
    width: 40% !important;
  }
  #ClaimsHistory .red-bg {
    background: #ef7676 0 0 no-repeat padding-box !important;
    -webkit-print-color-adjust: exact;
    width: 40% !important;
  }
  #ClaimsHistory .yellow-bg {
    background: #ffff00 0 0 no-repeat padding-box !important;
    -webkit-print-color-adjust: exact;
    width: 40% !important;
  }

  /* My Coverage -- print */
  .coverage-page,
  .card-title,
  .back-link,
  .estimated-cost-print,
  .estimated-cost-start-over,
  .estimated-cost-notzipcode,
  .estimated-cost-zipcodeupdate,
  .modal-header,
  .print-parent,
  .links {
    display: none !important;
  }

  .mycoverage-modal {
    margin-top: -720px;
    // margin-left: -100px;
    padding-top: 0px !important;
  }
  .modal-content {
    box-shadow: none;
  }
}
