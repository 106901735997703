.selectMethod {
  border: 2px solid #bfbfbf;
  background-color: #fff;
  padding: 15px 20px;
}

.selectMethod:hover {
  border-color: #009ea0;
}

.optionsContainer {
  width: 80%;
  margin: auto;
 @media (max-width: 1696px) {
    min-width: 100%;
  }
}

.optionsContainer2 {
  width: 100%;
  margin-bottom: 3rem;
}

.editEmailMFA {
  margin-bottom: 1rem;
}

.editEmailLabel {
  margin-bottom: .3rem;
}

.codeButtons button{
  margin: 2rem .5rem 0 .5rem;
}

.changeEmailHeader {
  margin-bottom: 2rem;
}

.MFAHeader {
  font-size: 1.2rem;
  font-family: campton-semibold;
  @include media-breakpoint-down(md) {
    font-size: 25px;
  }
}

.MFAMethod {
  display: grid;
  grid-template-columns: 33% 33% 17% 17%;
  align-items: center;
  border: .1rem solid #e7e7e7;
  background-color: #fff;
  padding: 1rem;
  min-height: 4rem;
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
  @include media-breakpoint-down(md) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
}

.MFASegment {
  padding: 0.5rem;
}

.MFALabel {
  font-size: .77rem;
  font-family: campton-medium;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 17px;
  }
}

.MFAItem {
  font-size: .8rem;
  font-family: Arial;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.authenticatorApp {
  border-bottom: 0.15rem solid #aaa;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-bottom: .7rem;
}

.authenticatorAppSection {
  padding: .9rem ;
  padding-bottom: .7rem;
  margin-left: -2rem;
  margin-right: -2rem;
}

#QRCodeBox {
    border: .12rem solid #aaa;
    background-image: linear-gradient(to top, #d9d9d9, #fafafa);
    width: 16rem;
    height: 16rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.authenticatorAppLink {
    color: #009ea0 !important;
    cursor: pointer;
    font-size: .8rem !important;
}

.authenticatorAppCode {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.btn-molina {
  background-color: #009ea0;
  color: #fff;
}

.btn-molina:disabled {
  background-color: #20bec066
}

.error-message {
  font-size: .72rem;
  color: red;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.what-is-mfa {
  margin-bottom: 1rem;
}

.MFA-info {
  font-size: 16px;
  font-family: campton-book;
}

.mfa-link {
  color: #009ea0;
   cursor: pointer;
   font-size: .72rem;
   font-family: campton-medium;
}

.mfa-frame-top-padding {
  padding-top: 2.176rem;
}

.mfa-card-padding {
  padding: 2rem;
}

.mfa-style-line {
  border-top: 2px solid rgb(0, 158, 160);
  margin-bottom: 2rem;
}

.mfa-description {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.one-rem-top-margin {
  margin-top: 1rem;
}
.half-rem-top-margin {
  margin-top: .5rem;
}

.auth-title {
  margin-left: .7rem;
}

.selection-method-text {
  padding: 1rem;
}

.selection-method-instuctions {
  padding: 0.7rem;
}

.margin-one-rem {
  margin: 1rem;
}

.margin-top-one-rem {
  margin-top: 1rem;
}

.margin-bottom-one-rem {
  margin-bottom: 1rem;
}

.margin-bottom-two-rem {
  margin-bottom: 2rem;
}

.missing-code {
  color: red;
}

.MFA-disclaimer {
  font-size: 14px;
  font-family: Arial;
  @include media-breakpoint-down(md) {
    font-size: 13px;
  }
}

.MFA-success-message {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.margin-auto {
  margin: auto;
}

.MFA-right-Button {
  margin-left: .5rem;
}
.MFA-left-Button {
  color: #009ea0;
  margin-right: .5rem;
}

#updatePasswordContainer {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  margin: 1rem 0;
}

.center-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}

.update-password-margin {
  margin-bottom: 1rem;
}

.update-password-wrapper {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  position: relative;
  i {
    position: absolute;
    right: 2%;
    top: 23%
  }
}

.password-tooltip {
  font-family: campton-medium;
  font-size: 1rem;
  font-weight: 700;
}

.password-list {
  list-style-type: decimal;
  padding-left: 1rem
}

.grayscale-override img {
  filter: none !important;
}