//
// Base styles
//

.btn {
  font-family: $btn-font-family;
  //text-transform: uppercase;
  vertical-align: bottom;
  border: 0;
  box-shadow: $btn-box-shadow;
  font-weight: $btn-font-weight;
  @include button-size-mdb(
    $btn-padding-top,
    $btn-padding-right,
    $btn-padding-bottom,
    $btn-padding-left,
    $btn-font-size,
    $btn-line-height
  );
  font: normal normal normal 0.8rem/1.04rem "campton-medium";

  &:hover {
    box-shadow: $btn-hover-box-shadow;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    box-shadow: $btn-hover-box-shadow;

    &:focus {
      box-shadow: $btn-hover-box-shadow;
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    box-shadow: $btn-box-shadow;
    border: 0;
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
}

.btn-default {
  border: 1px solid #999;
  padding: 0.44rem 0.899rem;
  color: $teal;
  background-color: $white;
}
.btn-default {
  &:hover {
    border: 1px solid $teal;
    color: $teal;
  }
}
//
// Button block
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

//
// Outline option
//

[class*="btn-outline-"] {
  border-width: $btn-border-width;
  border-style: solid;
  box-shadow: none;
  @include button-outline-size-mdb(
    $btn-outline-padding-top,
    $btn-outline-padding-right,
    $btn-outline-padding-bottom,
    $btn-outline-padding-left
  );

  &:hover {
    box-shadow: none;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    box-shadow: none;
    text-decoration: none;
  }

  &:active,
  &.active {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    box-shadow: none;
  }

  &.btn-lg {
    @include button-outline-size-mdb(
      $btn-outline-padding-top-lg,
      $btn-outline-padding-right-lg,
      $btn-outline-padding-bottom-lg,
      $btn-outline-padding-left-lg
    );
  }

  &.btn-sm {
    @include button-outline-size-mdb(
      $btn-outline-padding-top-sm,
      $btn-outline-padding-right-sm,
      $btn-outline-padding-bottom-sm,
      $btn-outline-padding-left-sm
    );
  }
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant-mdb($value);
  }
}

//
// Alternate buttons outline
//

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant-mdb($value);
  }
}

//
// Button Sizes
//

.btn-lg {
  @include button-size-mdb(
    $btn-padding-top-lg,
    $btn-padding-right-lg,
    $btn-padding-bottom-lg,
    $btn-padding-left-lg,
    $btn-font-size-lg,
    $btn-line-height-lg
  );
}

.btn-sm {
  @include button-size-mdb(
    $btn-padding-top-sm,
    $btn-padding-right-sm,
    $btn-padding-bottom-sm,
    $btn-padding-left-sm,
    $btn-font-size-sm,
    $btn-line-height-sm
  );
}

// Link buttons
//
// Make a button look and behave like a link

.btn-link {
  box-shadow: none;
  text-decoration: none;

  &:hover {
    // box-shadow: none;
    // text-decoration: none;
    background-color: $btn-link-bgc;
    border: 1px solid $teal;
    padding: 0.7rem 1.356rem 0.7rem 1.356rem;
  }

  &:focus,
  &.focus {
    // box-shadow: none;
    // text-decoration: none;
    background-color: $btn-link-bgc-hover;
    border: 1px solid $teal;
    padding: 0.7rem 1.356rem 0.7rem 1.356rem;
  }

  &:active,
  &.active {
    // box-shadow: none;
    background-color: $btn-link-bgc-hover;
    border: 1px solid $teal;
    padding: 0.7rem 1.356rem 0.7rem 1.356rem;
    &:focus {
      // box-shadow: none;
      background-color: $btn-link-bgc-hover;
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    box-shadow: none;
  }
}

//
// Rounded option
//

.btn-rounded {
  border-radius: $btn-rounded-border-radius;
}

//
// Floating option
//

.btn-floating,
[class*="btn-outline-"].btn-floating {
  border-radius: $btn-floating-border-radius;
  padding: 0;
  position: relative;
}

.btn-floating {
  width: $btn-floating-width;
  height: $btn-floating-height;

  .fas,
  .far,
  .fab {
    width: $btn-floating-icon-width;
    line-height: $btn-floating-icon-line-height;
  }

  &.btn-lg {
    width: $btn-floating-width-lg;
    height: $btn-floating-height-lg;

    .fas,
    .far,
    .fab {
      width: $btn-floating-icon-width-lg;
      line-height: $btn-floating-icon-line-height-lg;
    }
  }

  &.btn-sm {
    width: $btn-floating-width-sm;
    height: $btn-floating-height-sm;

    .fas,
    .far,
    .fab {
      width: $btn-floating-icon-width-sm;
      line-height: $btn-floating-icon-line-height-sm;
    }
  }
}

[class*="btn-outline-"].btn-floating {
  .fas,
  .far,
  .fab {
    width: $btn-outline-floating-icon-width;
    line-height: $btn-outline-floating-icon-line-height;
  }

  &.btn-lg {
    .fas,
    .far,
    .fab {
      width: $btn-outline-floating-icon-width-lg;
      line-height: $btn-outline-floating-icon-line-height-lg;
    }
  }

  &.btn-sm {
    .fas,
    .far,
    .fab {
      width: $btn-outline-floating-icon-width-sm;
      line-height: $btn-outline-floating-icon-line-height-sm;
    }
  }
}
@include media-breakpoint-down(xxl) {
  .btn-default {
    padding: 0.43rem 0.899rem;
  }
}
@include media-breakpoint-down(lg) {
  .btn {
    padding: 12px 22.5px;
    &.btn-default {
      padding: 11px 22.5px;
    }
  }
}
