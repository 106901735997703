.MuiPickersToolbar-toolbar {
  background-color: #009ea0 !important;
  justify-content: flex-end !important;
}

.toolbar-Text {
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  margin-left: -11px;
  font-weight: normal;
}
.MuiButton-textPrimary {
  color: #009ea0 !important;
  font-style: bold !important;
}
.MuiPickersDay-daySelected {
  background-color: white !important;
  color: black !important;
  border: 1px solid #009ea0 !important;
}
.MuiPickersDay-daySelected:hover {
  background-color: white !important;
  border: 1px solid #009ea0 !important;
}
.MuiButton-label {
  color: #009ea0;
}
.MuiPickerSlideTransition-transitionContainers > * {
  color: black;
}

// .MuiInputBase-input {
// border: 32px !important;
// background-color: white !important;
// padding: 0 !important;
// }
.MuiInput-underline:not(.Mui-disabled)::before {
  border-bottom: none !important ;
}
.MuiInput-underline:not(.Mui-disabled)::after {
  border-bottom: none !important;
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: none !important;
}
.MuiTypography-subtitle1 {
  font-size: 1.4rem !important;
  margin-left: 192px !important;
  color: white !important;
  background-color: #009ea0 !important;
  line-height: 32px !important;
}
.MuiPickersYear-yearSelected {
  font-size: 33px !important;
  margin-left: 220px !important;
  color: #009ea0 !important;
}
.MuiTypography-h4 {
  margin-top: -36px !important;
  font-size: 1.4rem !important;
  border-bottom: 4px solid black;
  margin-left: -25px !important;
  padding-left: 25px !important;
  padding-right: 21px !important;
}
.MuiPickerBasePicker-containerLandscape {
  flex-direction: column !important;
}
.MuiPickersToolbar-toolbarLandscape {
  max-width: auto !important;
}
.MuiFormControl-marginNormal {
  width: 100%;
}
.MuiInputBase-root {
  .MuiInputBase-input.Mui-disabled {
    border: 1px solid #999;
    color: #000;
    background-color: #f2f2f2 !important;
  }
}
.MuiInputBase-input {
  border-radius: 4px !important;
  color: #000 !important;
  font: normal normal normal 0.64rem/0.96rem Arial !important;
  letter-spacing: 0.15px !important;
  min-height: auto !important;
  padding: 17.5px 16.5px !important;
  transition: all 0.1s linear !important;
  border: 1px solid #999 !important;
}
.Mui-error {
  .MuiInputBase-input {
    border: 2px solid #e1684c !important;
  }
}
.my-health-record {
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
  }
  .MuiInputBase-input {
    border: 32px !important;
    background-color: white !important;
    //padding: 0 !important;
    //height: 2.24rem;
    border: 1px solid #999999 !important;
    //padding-left: 16px !important;
    border-radius: 4px;
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .MuiInputBase-input::placeholder {
    color: #000000;
    opacity: 1;
  }
}
@include media-breakpoint-down(xxl) {
  .MuiInputBase-input {
    border-radius: 5px !important;
    padding: 12px 16.5px !important;
  }
}
@include media-breakpoint-down(md) {
  .MuiInputBase-input {
    border-radius: 5px !important;
    font: normal normal normal 16px/24px Arial !important;
    padding: 12px 16.5px !important;
  }
  .my-health-record {
    .MuiInputBase-root {
      margin-bottom: 30px;
    }
  }
}

.MuiPickersCalendarHeader-transitionContainer {
  height: 32px !important;
}
