.grid-3col-container.contactusdetails {
  flex-direction: column;
}
.contact-us-page {
  .helpline-block {
    margin-top: 2.4rem;
  }
  .page-heading {
    margin-bottom: 55px;
  }
  .got-question-section {
    .card-title {
      margin-bottom: 1.6rem;
      font: normal normal 1.2rem/1.92rem 'Campton-medium';
    }
    .card-para {
      font: normal normal normal 0.8rem Arial;
      color: #454545;
    }
  }
  .grid-title {
    font: normal normal 1.04rem/1.32rem 'Campton-medium';
    color: #000000;
    font-size: 15px;
    white-space: normal;
    opacity: 1;
    margin-bottom: 10px;
  }
  .grid-data,
  p {
    font: normal normal normal 0.96rem/1.12rem Arial;
    color: #454545;
    font-size: 15px;
    margin-bottom: 8px;
  }
  .grid-data1 {
    font: normal normal normal 0.8rem/1.2rem Arial;
    color: #454545;
    margin-bottom: 8px;
  }
  .grid-bold {
    font: normal normal bold 0.8rem/1.72rem Arial;
    //margin-top: 24px;
    color: #000000;
    span {
      font: normal normal bold 0.8rem/1.72rem Arial;
      display: block;
      font-size: 15px;
    }
  }
  .send-msg-section {
    margin-top: 50px;
  }
  .mr-13 {
    margin-right: 20px;
    margin-top: 4px;
  }

  .grid-2col-container {
    display: inline;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3col-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-4col-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-1col-container {
    display: inline-flex;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
  .row-title {
    font: normal normal normal 0.72rem/0.96rem Arial;
    letter-spacing: 0.22px;
    margin-bottom: 5px;
    margin-top: 20px;
    color: #000000;
    font-family: 'campton-semibold';
    font-size: 20px;
  }
  .padding-10 {
    padding: 10px;
  }
  .float-right {
    margin-top: 1.76rem;
  }
  .contact-note {
    span {
      font: normal normal bold 0.72rem/0.88rem Arial;
    }
    font: normal normal normal 0.72rem/0.88rem Arial;
    margin-top: 1.76rem;
  }
  .update-header h5 {
    margin-bottom: 20px;
  }
  .update-header hr {
    margin-top: 60px;
    margin-bottom: 25px;
  }
}

.contact-modal {
  .contact-btn,
  .contact-button {
    text-align: right;
    .btn:hover {
      background-color: #ffffff !important;
      color: #009ea0 !important;
    }
    .btn-default {
      margin-right: 14px;
    }
  }
  .continue-button {
    width: 148.992px;
    height: 44px;
  }
  .contact-btns {
    .btn:hover {
      background-color: #ffffff !important;
      color: #009ea0 !important;
    }
    .btn-default {
      margin-right: 14px;
    }
  }
  .cancel-button {
    width: 127px;
    height: 44px;
    border: 1px solid #999999;
  }
  .btns {
    text-align: right;
  }
  .contact-content {
    font: normal normal normal 0.88rem/1.04rem Arial;
    display: inline-block;
  }
  .contact-info {
    vertical-align: top;
    margin-right: 5px;
  }
  .contact-header {
    padding-top: 17px !important;
    display: inline-block;
  }
  .update-header h5 {
    color: #000000 !important;
    padding-bottom: 0.8rem !important;
    margin-bottom: unset;
  }
  .update-header p {
    display: inline-block;
  }
  .para {
    margin-left: 14px;
  }
  .para1 {
    margin-left: 40px;
  }
}

@include media-breakpoint-down(xxl) {
  .contact-us-page {
    .grid-4col-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@include media-breakpoint-down(xl) {
  .contact-us-page {
    .grid-bold {
      span {
        display: block;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .contact-us-page {
    .page-heading {
      margin-bottom: 24px;
    }
    .grid-2col-container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 43px;
    }
    .grid-3col-container {
      grid-template-columns: repeat(1, 1fr);
      display: inline-block;
    }
    .grid-4col-container {
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(1, 1fr);
    }
    .lg-card .card-title {
      margin-bottom: 1.16rem;
    }
    .got-question-section {
      .card-title {
        font: normal normal 25px/32px Campton-medium;
        margin-bottom: 24px;
      }
      .card-para {
        font: normal normal normal 16px/24px Arial;
      }
    }
    .grid-title {
      font: normal normal 600 16px/21px Campton-semibold;
      margin-bottom: 11px;
    }
    .mr-13 {
      width: 20px;
      height: 25px;
    }
    .contact-note {
      span {
        font: unset;
      }
      font-size: 16px;
      line-height: 24px;
    }
    .grid-data,
    .grid-bold {
      font: normal normal normal 16px/18px Arial;
      margin-bottom: 13px;
    }
    .grid-bold {
      font: normal normal normal 16px/18px Arial;
      margin-top: 0;
      color: #454545;
      span {
        font: normal normal normal 16px/30px Arial;
        display: block;
      }
    }
    .row-title {
      font-size: 18px;
      line-height: 21px;
    }
    .grid-data1 {
      font: normal normal normal 16px/30px Arial;
    }

    .helpline-block {
      margin-top: 28px;
    }
    .row-title {
      padding-bottom: 0.48rem;
    }
    .float-right {
      margin-top: 0.928rem;
      margin-bottom: unset;
    }
    .update-header p {
      font: normal normal normal 18px/27px Arial;
    }
  }
  .contact-modal {
    .contact-btns {
      margin-left: unset;
      position: relative;

      text-align: right;
    }
    .contact-btn {
      margin-left: unset;
      position: relative;
      left: 11px;
    }
    .contact-button {
      margin-left: unset;
      position: relative;
      margin-top: 30px;
    }

    .continue-button {
      width: 145.992px;
      height: 44px;
    }
    .contact-content {
      font: normal normal normal 18px/26px Arial;
    }
    .update-header h5 {
      font: normal normal 22px/28px Campton-medium !important;
      padding-bottom: unset;
    }
    .content {
      margin-bottom: 12px;
    }
    .header {
      margin-bottom: 0px !important;
      padding-bottom: unset !important;
    }

    .para,
    .para1 {
      margin-left: unset;
    }
    .para2 {
      display: flex !important;
    }
  }
  .modal-body {
    padding: 9px 23px 24px 20px;
  }
}
@include media-breakpoint-down(lg) {
  .contact-us-page {
    .mr-13 {
      height: 22px;
      width: 22px;
    }
    .grid-3col-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.img-pad {
  margin: 0 12px;
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
  .contact-us-page .grid-item:first-child {
    padding-bottom: 0.8rem;
  }
}

.form-select {
  width: 80%;
  border: 1px solid #7575756b;
  box-shadow: -9px -9px 8px #75757514;
}
