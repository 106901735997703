/* Account Settings*/
.mr15{
  margin-right: 15px;
  @include media-breakpoint-down(md) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
.account-settings {
  // padding-bottom: 25rem;

  .page-heading {
    margin-bottom: 20px;
  }
  .btn:hover {
    color: #009ea0 !important;
    background: #ffffff !important;
  }
  .update-button,
  .save-button {
    // background: #009ea0;
    // box-shadow: 0px 2px 2px #0000003d;
    // border-radius: 5px;
    // color: #ffffff;
    float: right;
    // font: normal normal 0.72rem/0.8rem Campton-medium;
    // letter-spacing: 0.36px;
  }
  .no-tab-title {
    padding-top: 2.176rem;
  }
  .notificationDate{ 
    font: normal normal 10px Campton-medium;
    text-align: right;
  }
  .myprofile-radio{
    margin-right: 1rem;
  }
  
  .list-group-item {
    padding: 2rem;
    margin-bottom: 2.08rem;
  }
  .textarea-control {
    margin-top: 1rem;
    resize: none;
  }
  .disabled-div {
    pointer-events: none;
  }
  .textarea-display {
    display: none;
  }
  .dropdown-list-width {
    width: 70%;
  }
  .langnavbox,
  .accbox,
  .accNotification{
    padding: 15px;
    margin: 0px;
  }
  .acc-title {
    text-align: left;
    font: bold normal 16px 'Campton-medium';
    padding-bottom: 7px;
    color: #000000;
    @include media-breakpoint-down(md) {
      font: bold normal 18px 'Campton-medium';
    }
  }
  .langnavbox .row-title{
    margin-top: 0px;
  }
  .hrline{
    height: 2px;
    margin-bottom: 20px;
  }
  .sub-title {
    text-align: left;
    font: normal normal 16px 'Campton-medium';
    padding-bottom: 7px;
    color: #575757;
    @include media-breakpoint-down(md) {
      font: bold normal 18px 'Campton-medium';
    }
  }
  .para-details {
    text-align: left;
    font: normal normal .72rem/.92rem 'Campton-medium';
    color: #575757;
    @include media-breakpoint-down(md) {
      font: bold normal 16px 'Campton-medium';
    }
  }
  @media (max-width: 991px) {
    .column-margin {
      margin: 20px 0;
    }
  }
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
    .dropdown-list-width {
      width: 80% !important;
    }
  }
  .tab-sub-title {
    font: normal normal 1.2rem/1.52rem Campton-medium;
    padding-bottom: 1.76rem;
    color: #000000;
  }
  .nav li.tab-space {
    min-width: 364px;
    text-align: center;
  }
  .field1 {
    justify-content: space-between;
  }
  .field2 {
    justify-content: space-between;
  }
  .common-input input:focus-visible {
    outline: none;
  }
  .common-input input {
    border: none;
    width: 100%;
    text-transform: capitalize;
  }
  .plan-content {
    font: normal normal normal 0.8rem/0.92rem Arial;
    color: #454545;
    padding-bottom: 1.76rem;
    word-break: break-word;
    .read-or-hide {
      color: $teal;
    }
  }
  .multiSelectEnhanced .optionContainer .option .checkbox{
    width: 25px;
    display: inline-block;
  }
  .btn-disclaimer-text {
    font: normal normal 0.72rem/0.92rem Campton-medium;
    letter-spacing: 0;
    margin-top: 1rem;
  }
  .disclaimer-text {
    font: normal normal 18px/23px campton-medium;
    margin-top: 15px;
  }
  .radio-divider {
    padding: 2rem;
  }
  .grid-2col-containers {
    display: grid;
    grid-gap: 2.08rem;
    grid-template-columns: repeat(1, 1fr);
    // padding-bottom: 1.5rem;
  }
  .label-radio {
    font: normal normal bold 0.8rem/0.15rem Arial;
    color: #000000;
    margin-left: 5px;
  }
  .account-active {
    width: 88px;
    background-color: #4ce56f;
    margin-top: 0.96rem;
    font: normal normal 0.72rem/1.44rem Campton-medium;
    text-align: center;
    border-radius: 5px;
  }
  .access-granted {
    margin-top: 20px;
    span {
      font: normal normal 0.72rem/0.92rem Campton-medium;
    }
    .account-active {
      display: inline-block;
      margin-right: 24px;
    }
  }
  .gap-active {
    padding-bottom: 1.44rem;
  }

  .row-details {
    span {
      border-right: 1px solid #454545;
      margin-right: 8.6px;
      padding-left: 6.6px;
      padding-right: 4.8px;
      word-break: break-all;
    }
  }
  .communication-preferences {
    .row-title {
      padding-bottom: 24px;
    }
    .molina-select__single-value--is-disabled {
      color: #000000;
    }
    .row-details {
      .form-check input {
        margin-left: 0px;
        margin-top: 9px;
      }
    }
    .preferences-para {
      padding-bottom: 34px;
      font: normal normal normal 0.88rem/0.96rem Arial;
      letter-spacing: 0.26px;
      color: #454545;
    }
    .edit {
      font: normal normal 0.88rem/1.12rem Campton-medium;
      margin-left: 18px;
    }
    .editcf {
      font: normal normal 0.88rem/1.12rem Campton-medium;
      margin-left: 18px;
      position: absolute;
      left: 1100px;
    }
    .custom-float {
      float: right;
    }
    // .custom-control {
    //   margin-left: 65px;
    // }
    .btn-add {
      font: normal normal 23px/28px Campton-medium;
      border: none;
      background: none;
      color: #009ea0;
    }
    .btn-disabled {
      color: #bcbcbc !important;
    }
    .content-disabled {
      color: #bcbcbc !important;
    }
    .add-content {
      font: normal normal normal 0.72rem/0.84rem Arial;
      color: #454545;
    }
    .field1 {
      padding-bottom: 1.5rem;
      justify-content: unset;
      position: relative;
    }

    .radio-check-btn {
      .error-msg {
        width: 100%;
        display: block;
      }

      img {
        margin-top: 14px;
        margin-left: auto;
        margin-right: auto;
      }

      .row-title {
        text-align: center;
        display: block;
      }

      .row-details {
        .form-check {
          text-align: center;
          padding-left: 0;
          input {
            margin-left: 0px;
            margin-top: 13px;
            margin-right: 0px;
            float: none;
          }
        }
      }
    }
    .preferences-btn {
      font: normal normal 18px/20px Campton-medium;
      text-align: right;
      .btn-default {
        margin-right: 14px;
      }
      .btn-primary {
        margin-right: 16px;
      }
      .btn-edit {
        background: #ffffff 0% 0% no-repeat padding-box;
        color: #009ea0;
        border-radius: 4px;
        border: 1px solid #999999;
      }
      .btn-cancel {
        color: #009ea0;
        margin-right: 16px;
        border: 1px solid #999999;
      }
      .btn-delete {
        font: normal normal 22px/28px Campton-medium;
        color: #009ea0;
        text-transform: uppercase;
      }
      .btn-save {
        background: #a2a2a2 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #0000003d;
        border-radius: 5px;
        color: #ffffff;
        margin-left: 0px;
      }
    }
  }
  .preferences-btn {
    font: normal normal 18px/20px Campton-medium;
    text-align: right;
    .btn-edit {
      background: #ffffff 0% 0% no-repeat padding-box;
      color: #009ea0;
      border-radius: 4px;
    }
    .btn-cancel {
      color: #009ea0;
      margin-right: 16px;
      border: 1px solid #999999;
    }
    .btn-delete {
      font: normal normal 22px/28px Campton-medium;
      color: #009ea0;
      text-transform: uppercase;
    }
    .btn-save {
      background: #a2a2a2 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      border-radius: 5px;
      color: #ffffff;
      margin-left: 14px;
    }
  }
  .manage-communication {
    .check-first {
      padding-top: 8px;
    }
    .manage-decription {
      font: normal normal normal 0.833rem/1.25rem Arial;
      letter-spacing: 0.24px;
      color: #454545;
      word-break: break-word;
    }
    .see-def {
      font: normal normal bold 0.833rem/1rem Arial;
      letter-spacing: 0.24px;
      color: #009ea0;
      box-shadow: none;
      margin-top: 10px;
    }
    .btn-save-new {
      background: #a2a2a2 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      border-radius: 5px;
      color: #ffffff;
      margin-left: 14px;
    }
    .btn-save {
      background-color: #009ea0;
    }
    .preferences-btn {
      margin-bottom: 69px;
    }
    .check-content p {
      font: normal normal normal 0.72rem/0.72rem Arial;
      color: #454545;
      margin-top: 10px;
    }
    .manage-content {
      margin-left: 42px;
      font: normal normal normal 0.88rem/0.96px Arial;
      letter-spacing: 0.21px;
    }
    .tab-sub-title {
      padding-bottom: 25px;
    }
    .tooltip-img {
      width: 24px;
      height: 24px;
      margin-left: 10px;
      margin-bottom: 7px;
    }
    .custom-tooltip-spending .custom-tooltip-spending-text {
      left: 15%;
    }
    .grid-space {
      margin-top: 45px;
    }
    .label-radio {
      display: grid;
    }
    .grid-2col-container {
      grid-template-columns: repeat(5, 1fr);
    }
    .hr-gap {
      margin-top: 25px;
    }
  }
  .data-sharing {
    .question-block {
      padding-bottom: 0.5rem;
      .tab-sub-title {
        padding-bottom: 1rem;
        font: normal normal 0.96rem/1.16rem Campton-medium;
      }
    }
    .sharing-btn {
      float: right;
      margin-bottom: 1.5rem;
    }
    .sharing-btn a {
      color: white;
    }
    .sharing-btn a:hover {
      color: #009ea0;
    }

    .save-button {
      float: left;
      margin-top: 1.2rem;
      margin-bottom: 1rem;
    }
    .data-sharing-access {
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      .row-title,
      .row-details {
        padding-bottom: 1rem;
        span {
          white-space: nowrap;
          border: none;
        }
      }
    }
    .sharing-note {
      font: normal normal normal 0.72rem/0.88rem Arial;
      margin-top: 1.76rem;
    }
    .form-check {
      .form-check-input {
        margin-top: 0px;
      }
    }
  }
}

.row-title {
  text-align: left;
  font: normal normal .72rem 'Campton-medium';
  padding-bottom: 7px;
  color: #575757;
  .name-space {
    margin-left: 6px;
  }
}
.row-details {
  text-align: left;
  font: normal normal .72rem 'Campton-medium';
  color: #575757;
}
// .custom-control input {
//   width: 24px;
//   height: 24px;
//   padding: 3px;
// }
// .custom-radio {
//   display: flex;
// }
// .userdata {
//   margin-left: 8px;
// }
.redirect {
  color: #ffffff !important;
}
.redirect:hover {
  color: #009ea0 !important;
}
.account-modal {
  .comm-btns {
    .btn:hover {
      color: #009ea0;
      background-color: #ffffff;
    }
    margin-left: 6.4rem !important;
  }
  .account-btns {
    .btn:hover {
      color: #009ea0;
      background-color: #ffffff;
    }
    .btn-default {
      margin-right: 16px;
    }
    text-align: right;
    .cancel-button {
      border: 1px solid #999999;
    }
  }
}

.comm-btns {
  text-align: end;
}

.access-modal {
  .dependent-header {
    margin-bottom: 7px !important;
    margin-left: 12px;
  }
  .para-dependent {
    margin-bottom: 44px !important;
    margin-left: 12px;
    word-break: break-word;
    .row-details {
      span {
        border-right: 1px solid #454545;
        margin-right: 8.6px;
        padding-left: 6.6px;
        padding-right: 4.8px;
        word-break: break-all;
      }
    }
  }
  .heading-main {
    margin-bottom: 20px !important;
  }
  .para-one {
    margin-bottom: 24px;
  }
  .modal-header .btn-close {
    padding: 0.48rem !important;
  }
  .access-btns {
    .btn:hover {
      color: #009ea0;
      background-color: #ffffff;
    }
    .btn-default {
      margin-right: 16px;
    }
    text-align: end;
    //margin-left: 13rem;
    .continue-button {
      height: 44px;
    }
    .cancel-button {
      height: 44px;
      min-width: 96px;
    }
  }
  // .radio-btns-access {
  //   margin-top: -3px;
  // }
  .row-title {
    line-height: 0.96rem;
  }
  .update-header h5 {
    line-height: 1.16rem;
  }
  .radio-btns {
    margin-left: 15px;
  }
  .cancel-button {
    border: 1px solid #999999;
    box-shadow: none;
  }

  .label-radio {
    display: inline;
  }
}

.verification-modal {
  .heading {
    font: normal normal 26px/33px campton-medium;
    color: #000000;
    margin-bottom: 20px;
  }
  .para {
    font: normal normal normal 20px/23px arial;
    color: #454545;
    margin-bottom: 32px;
    letter-spacing: 0.24px;
    padding-bottom: unset;
  }
  .verify-btns {
    margin-top: 50px;
    text-align: end;
    // .continue-button{
    //   left: unset;
    // }
  }
  // .cancel-button{
  //   border: 1px solid #999999;
  //   box-shadow: none;
  // }
  .code-heading {
    text-align: left;
    font: normal normal 26px/33px Campton-medium;
    color: #000000;
    // margin-bottom: 50px;
  }
  .recovery-btn {
    text-align: center;
    margin-top: 50px;
    .text-info {
      margin-right: 1rem;
      border: 1px solid #999999;
      box-shadow: none;
    }
  }
  .password-para {
    text-align: left;
    font: normal normal 20px/26px Campton-medium;
    color: #454545;
    margin-bottom: 38px;
  }
  .pass-user {
    text-align: left;
    font: normal normal 600 20px/26px Campton-medium;
    color: #000000;
    margin: 7px;
  }
  .password-label {
    text-align: left;
    font: normal normal normal 18px/24px Arial;
    letter-spacing: 0.22px;
    color: #000000;
    margin-bottom: 10px;
  }
  .top {
    margin-top: 24px;
  }
  .req-head {
    margin-top: 18px;
    text-align: left;
    font: normal normal bold 14px/18px Arial;
    letter-spacing: 0.17px;
    color: #000000;
    margin-bottom: unset;
  }
  .req-para {
    margin: unset;
    text-align: left;
    font: normal normal normal 14px/18px Arial;
    letter-spacing: 0.17px;
    color: #000000;
  }
  .pass-btns {
    margin-top: 44px;
    text-align: end;
    .cancel-button {
      border: 1px solid #999999;
      box-shadow: none;
    }
  }
}
.account-settings .common-input .b-bottom:last-child {
  border-bottom: none !important;
}

.managecomm-modal {
  .comm-para {
    text-align: left;
    font: normal normal normal 22px/26px Arial;
    letter-spacing: 0.26px;
    color: #000000;
    margin-bottom: unset;
    padding-bottom: 40px;
  }
  .managecomm-btns {
    text-align: end;
    .dist-btn {
      margin-right: 1rem;
    }
    .comm-save-btn {
      background: #009ea0 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      border-radius: 5px;
      width: 96px;
      height: 44px;
      font: normal normal 18px/23px Campton-medium;
      color: #ffffff;
    }
    .comm-close-btn {
      background: #009ea0 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      border-radius: 5px;
      width: 110px;
      height: 44px;
      font: normal normal 18px/23px Campton-medium;
      color: #ffffff;
    }
    .comm-cancel-btn {
      border: 1px solid #999999;
      border-radius: 4px;
      width: 127px;
      height: 44px;
      font: normal normal 18px/20px Campton-medium;
      color: #00a2ad;
      text-transform: uppercase;
    }
  }
}

.datasharing-modal {
  .head {
    font: normal normal 26px/33px campton-medium;
    color: #000000;
    margin-bottom: 20px;
    .confirm-info {
      vertical-align: top;
      margin-right: 5px;
    }
  }
  .datasharing-para {
    font: normal normal normal 22px/26px Arial;
    letter-spacing: 0.26px;
    color: #000000;
    margin-bottom: unset;
    padding-bottom: 40px;
  }
  .datasharing-btns {
    .btn:hover {
      color: #009ea0;
      background-color: #ffffff;
    }
    .btn-default {
      margin-right: 16px;
    }
  }
  .datasharing-btns {
    text-align: end;
    .dist-btn {
      margin-right: 1rem;
    }
    .data-save-btn {
      background: #009ea0 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      color: #ffffff;
    }
    .data-close-btn {
      border: 1px solid #999999;
      color: #00a2ad;
      text-transform: uppercase;
    }
    .data-save-btn,
    .data-close-btn {
      border-radius: 5px;
      width: 96px;
      height: 44px;
      font: normal normal 18px/23px Campton-medium;
    }
  }
  .agree-note {
    margin-top: 16px;
  }
  .agree {
    color: #009ea0;
    font: normal normal 26px/33px Campton-medium;
  }
}

@include media-breakpoint-down(md) {
  .mail-spacer {
    width: 80%;
  }
  .cancel-button-mysubs {
    padding: 12.5px 11px;
  }
  .account-settings {
    .b-bottom {
      border-bottom: none !important;
    }
    .account-active {
      font: normal normal 18px/36px Campton-medium;
    }-details
    .tab-sub-title {
      font: normal normal 22px/28px Campton-medium;
      padding-bottom: unset;
    }
    .no-tab-title {
      padding-top: 1.96rem;
    }
    .row-details {
      font: normal normal normal 16px/21px Arial !important;
      letter-spacing: 0.18px;
      // padding-top: 0.5rem;
      // padding-bottom: 1rem;
    }
    .list-group-item {
      //padding: 1.92rem 1.28rem 1.28rem 1.28rem;
      margin-bottom: 1.2rem;
    }
    .row1 {
      padding-bottom: unset;
    }
    .plan-content {
      font: normal normal normal 14px/17px Arial;
      opacity: 0.8;
    }
    .read-or-hide {
      font: normal normal bold 18px/21px Arial;
      color: #00a2ad;
      margin-top: 9px;
    }
    .btn-disclaimer-text {
      font: normal normal 18px/23px campton-medium;
      margin-top: 15px;
      margin-bottom: 50px;
    }
    // .grid-2col-containers {
    //   display: unset;
    // }
    .label-radio {
      font: normal normal normal 18px/22px Arial;
      padding-top: 1.08rem;
      color: #454545;
    }
    .radio {
      margin-left: -1.54rem !important;
    }
    // .grid-item {
    //   margin-bottom: 0.64rem;
    // }
    .field1 {
      padding-bottom: unset;
    }
    .update-button,
    .save-button {
      position: absolute;
      bottom: 0;
      right: 15px;
      margin-bottom: 24px;
      max-width: 84%;
      // font-size: 18px;
      // line-height: 20px;
    }
    .button-space {
      margin-bottom: 70px !important;
    }
    .myprofile-radio {
      input {
        margin-top: 1.08rem;
      }
    }
    // .custom-radio {
    //   display: initial;
    // }
    // .custom-control input {
    //   height: 20px;
    //   width: 20px;
    //   vertical-align: sub;
    // }
    .radio-divider {
      padding: 2rem;
      .row-title {
        display: block;
        padding-top: 0.32rem;
        padding-bottom: 0.64rem;
      }
    }
    .gap-active {
      margin-bottom: 0.96rem;
      .row-title {
        width: auto;
      }
    }
    .account-active {
      margin-top: 0.75rem;
    }
    .userdata {
      margin-bottom: 65px;
    }
    .access-granted {
      margin-top: 0 !important;
      position: unset !important;
      span {
        display: block;
        margin-top: 20px;
        font-size: 18px !important;
        line-height: 23px;
      }
    }
    .access-granted .account-active {
      margin-top: 0.384rem;
      font: normal normal 18px/36px Campton-medium;
    }
    .personal-details-btns {
      position: absolute;
      bottom: 0;
      right: 15px;
      width: 100%;
      text-align: right;
    }
    .communication-preferences {
      .tab-sub-title {
        font: normal normal 22px/28px Campton-medium;
      }
      // .list-group-item {
      //   //padding: 1.984rem 0.704rem 1.28rem 1.28rem;
      // }
      .btn-delete {
        font: normal normal 22px/28px Campton-medium !important;
      }
      .field1 > div:first-child > .row-title:first-child {
        padding-top: 45px;
      }

      .preferences-para {
        padding-top: 25px;
        padding-bottom: unset;
        font: normal normal normal 16px/27px Arial;
      }
      .row-title {
        font: normal normal normal 18px/21px Arial;
        padding-bottom: 12px;
      }
      .radio-btn {
        padding-bottom: 7px;
      }
      .row-details {
        margin-bottom: 16px;
        .form-check input {
          margin-left: 0px;
          margin-top: unset;
          margin-right: 0px;
          z-index: 0;
        }
      }
      .input-width {
        width: unset;
      }
      // .custom-control input {
      //   height: 24px;
      //   width: 24px;
      //   padding: 3px;
      // }
      // .edit {
      //   margin-left: unset;
      //   float: right;
      // }

      .radio-check-btn.width-80 {
        .row-title {
          width: 80% !important;
          margin-left: 0%;
        }
      }
      .radio-check-btn.phone,
      .radio-check-btn.email {
        position: unset !important;
      }
      .radio-check-btn {
        .row-title {
          display: inline-block;
          width: 80%;
          text-align: left;
        }
        .row-details {
          padding-top: 2.3rem;
          float: right;
          margin-right: 0px;
        }
        .error-msg p {
          text-align: left !important;
        }
      }
      .preferences-btn .btn {
        margin-bottom: 15px;
      }
    }
    .manage-communication {
      .check-first {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .see-def {
        margin-top: 38px;
        margin-left: -20px;
        font: normal normal bold 18px/21px Arial;
        color: #00a2ad;
      }
      .manage-decription {
        font: normal normal normal 18px/27px Arial;
        letter-spacing: 0.17px;
      }
      .label-radio {
        padding-top: unset;
        color: #000;
      }
      .check-content p {
        font: normal normal normal 14px/18px Arial;
        margin-left: 34px;
      }
      .grid-2col-container {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 24px;
      }
      .grid-space {
        margin-top: 30px;
      }
      .manage-content {
        font: normal normal normal 18px/24px Arial;
        margin-left: 0px;
        margin-top: 25px;
        display: block;
      }
    }
  }
  .data-sharing {
    .sharing-btn {
      margin-bottom: 1.5rem;
      float: unset !important;
    }
    .save-button {
      position: initial;
    }
    .form-check-inline {
      display: block;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
    .label-radio {
      padding-top: unset;
    }
    .row-details span {
      padding-left: unset;
    }
  }
  .account-modal {
    .account-btns {
      margin-left: unset;
      position: relative;
    }
    .btn-default {
      margin-right: 16px;
    }
    .comm-btns {
      margin-left: 5.12rem !important;
      .btn-default {
        margin-right: 16px;
      }
    }
  }
  .modal-body {
    padding: 9px 23px 24px 20px;
  }
  .access-modal {
    .dependent-header {
      margin-bottom: 7px !important;
      margin-left: 16.5px;
      font: normal normal 22px/28px campton-medium !important;
      color: #000000 !important;
    }
    .para-dependent {
      margin-bottom: 21.26px !important;
      margin-left: 16.5px;
      font: normal normal normal 0.72rem/0.84rem arial !important;
      color: #575757 !important;
    }
    .heading-main {
      margin-bottom: 20px !important;
      font: normal normal 22px/28px campton-medium !important;
      color: #000000 !important;
    }
    .para-one {
      padding-bottom: 24px !important;
      font: normal normal normal 18px/27px arial !important;
      color: #454545 !important;
    }
    .access-btns {
      text-align: end;
      //margin-left: 4rem;
      .btn-default {
        margin-right: 16px;
      }
    }

    .radio-btns {
      margin-left: unset;
      margin-top: 6px;
    }
  }
  .row-title {
    font: normal normal 22px/28px 'Campton-medium';
  }
  .row-details {
    font: normal normal normal 18px/21px 'Arial';
  }
  .verification-modal {
    .heading {
      font: normal normal 22px/28px campton-medium;
      color: #000000;
      margin-bottom: 20px;
    }
    .para {
      font: normal normal normal 18px/27px arial;
      color: #454545;
      margin-bottom: 12px;
    }
    .verify-btns {
      margin-top: 24px;
      // text-align: end;
    }
    .email-bold {
      font: normal normal bold 18px/27px arial;
    }
    .code-heading {
      text-align: left;
      font: normal normal 22px/28px Campton-medium;
      color: #000000;
      // margin-bottom: 30px;
    }
    .recovery-btn {
      // text-align: center;
      margin-top: 30px;
      .text-info {
        margin-right: 1rem;
        border: 1px solid #999999;
        box-shadow: none;
      }
    }
    .password-para {
      text-align: left;
      font: normal normal bold 18px/21px Arial;
      color: #000000;
      margin-bottom: 38px;
    }
    .password-label {
      text-align: left;
      font: normal normal normal 18px/21px Arial;
      color: #000000;
      margin-bottom: 10px;
    }
    .top {
      margin-top: 43px;
    }
    .req-head {
      margin-top: 30px;
      text-align: left;
      font: normal normal bold 18px/18px Arial;
      color: #000000;
      margin-bottom: unset;
    }
    .req-para {
      margin-top: 12px;
      text-align: left;
      font: normal normal normal 18px/18px Arial;
      color: #454545;
    }
    .pass-btns {
      margin-top: 40px;
      // text-align: end;
      // .cancel-button{
      //   border: 1px solid #999999;
      //   box-shadow: none;
      // }
    }
  }
  .managecomm-modal {
    .comm-para {
      font: normal normal normal 18px/27px Arial;
      color: #454545;
    }
  }
}
@include media-breakpoint-down(xl) {
  .account-settings {
    .b-bottom {
      border-bottom: 1px solid lightgray;
    }
    .manage-communication {
      .grid-2col-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .access-granted {
      position: absolute;
      right: 40px;
      margin-top: -50px;
    }
    .nav-tabs .nav-link {
      font: normal normal bold 14px/12px Arial;
    }
    // .grid-2col-containers {
    //   display: unset;
    // }
    // .grid-item {
    //   margin-bottom: 0.64rem;
    // }
    .row-title {
      padding-bottom: 12px;
      padding-top: 24px;
    }
    .field1 {
      padding-bottom: unset;
    }
    .plan-content {
      padding-bottom: 0.192rem;
    }

    .communication-preferences {
      .radio-btn {
        padding-bottom: 7px;
      }
      .preferences-para {
        padding-bottom: unset;
      }
      .btn-delete {
        right: 0;
        border: none;
        position: absolute;
        top: -9px;
        font: normal normal 0.88rem/1.12rem Campton-medium;
        color: #009ea0;
        box-shadow: none;
        padding-right: 8px;
      }

      .radio-check-btn.width-80 {
        .row-title {
          width: 80%;
          margin-left: 10%;
        }
      }
      .radio-check-btn {
        img {
          margin-top: 11px;
          bottom: -3px;
          right: 0;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          position: absolute;
        }
        .error-msg p {
          text-align: center;
          margin-bottom: 0;
        }
      }
      .radio-check-btn + .phone {
        img {
          bottom: -3px;
        }
      }
      .radio-check-btn.phone,
      .radio-check-btn.email {
        position: relative;
        margin-top: 24px;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .account-settings {
    .access-granted {
      right: 32px;
      margin-top: -45px;
      span {
        font-size: 13px;
      }
    }
    .data-sharing {
      .sharing-btn {
        margin-bottom: 1.5rem;
      }
    }
    .communication-preferences {
      .radio-btn {
        padding-bottom: 7px;
      }
      .radio-check-btn {
        img {
          top: 67px;
        }
      }
      .radio-check-btn.width-80 {
        .row-title {
          width: 100%;
          margin-left: 0%;
        }
      }
    }
    .data-sharing {
      .label-radio {
        line-height: 1.2rem;
      }
    }
  }
  .access-modal {
    .row-title {
      font-size: 1rem;
      line-height: 1.12rem;
    }
    // .radio-btns-access {
    //   margin-top: -3px;
    // }
  }
}
@include media-breakpoint-down(xxl) {
  .account-settings {
    .tab-content .tab-sub-title {
      word-break: break-word;
    }
    .tab-content .row-title {
      word-break: break-word;
    }
    .nav li.tab-space {
      min-width: unset;
    }
    .communication-preferences {
      .radio-check-btn img {
        margin-top: 12px;
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .account-settings {
    .communication-preferences {
      .input-width {
        width: 260px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .access-modal {
    .radio-btns-access {
      margin-top: 4px;
    }
    .row-title {
      font: normal normal 22px/28px 'Campton-medium';
    }
    .radio-btns-access {
      //margin-top: 2px;
      margin-left: -1.5rem !important;
    }
  }
  .account-settings {
    .data-sharing {
      .label-radio {
        line-height: 21px;
      }
    }
    .communication-preferences {
      .radio-btn {
        padding-bottom: 7px;
      }
    }
    
  }
}
.txtpt12{ 
  font: normal normal 14.4px 'Campton-medium';
  color: #575757;
  @include media-breakpoint-down(md) {
    font: normal normal 12px 'Campton-medium';
  }
 }