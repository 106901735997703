.my-health-record {
  .MuiInputBase-input {
    font: normal normal normal 0.64rem/0.8rem Arial;
    letter-spacing: 0.19px;
    color: #706b6b;
  }

  .page-heading {
    margin-bottom: 20px;
    display: inline-block;
  }

  .back-to-list {
    margin-top: 6px;
    margin-bottom: 45px;
    font: normal normal 0.72rem/0.92rem 'Campton-normal';
  }

  .show {
    color: #000;
  }

  .claims-history {
    .list-desc-details {
      word-break: initial;
    }
  }

  .page-subheading {
    text-align: left;
    font: normal normal 0.96rem/1.24rem 'Campton-medium';
    margin-bottom: 31px;
    color: #000000;
  }

  .form-input {
    border: 1px solid #999999;
    border-radius: 4px;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    height: 56px;
  }

  .molina-select__control,
  .molina-select__control:hover {
    //height: 2.24rem;
    border-radius: 4px !important;
  }

  .start-date,
  .end-date {
    p {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }

  .label {
    font: normal normal normal 0.72rem/0.84rem Arial;
    color: #454545;
  }

  .calendar-img {
    margin-left: 6px;
  }

  .btn-search {
    text-align: center;
    font: normal normal 18px/20px 'Campton-medium';
    letter-spacing: 1.38px;
    // color: #ffffff;
    // background: #009ea0 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #0000003d;
    border-radius: 5px;
    height: 2rem;
    width: 152px;
    text-transform: uppercase;
  }

  .search-btn {
    margin-top: 25px;
  }

  .desc {
    font: normal normal normal 0.72rem/0.84rem Arial;
    display: flex;
    align-items: center;
    color: #454545;
    justify-content: flex-end;
  }

  .medication-details {
    margin-bottom: 60px;

    .list-group-item {
      padding: 50px;
    }

    .field1 {
      padding-bottom: 44px;
    }
  }

  .desc1 {
    font: normal normal normal 0.8rem/1.04rem Arial;
    letter-spacing: 0.24px;
    color: #454545;
  }

  .form-select {
    border: 1px solid #999999;
    border-radius: 4px;
    height: 56px;
  }

  .print-record {
    display: inline-block !important;
    align-items: center;
    font: normal normal normal 0.72rem/0.84rem Arial;
    margin-left: 24px;
  }

  .nav-link {
    padding: 0.96rem 1.2rem;
  }

  .col-list-title {
    font: normal normal normal 0.88rem/1.12rem 'campton-medium';
    padding-bottom: 25px;
  }

  .eob-doc {
    position: relative;
    bottom: 25px;
    color: #009ea0;
    font: normal normal normal 0.72rem/1rem Arial;
  }

  .eob-doc-abs {
    position: relative;
    color: #009ea0;
    font: normal normal normal 0.72rem/1rem Arial;
  }

  .eob-doc-sm {
    letter-spacing: 0.18px;
    color: #009ea0;
    width: 100%;
    font: normal normal normal 16px/26px Arial !important;
  }

  .eob-dochide {
    display: none !important;
  }

  .label-list {
    .col-list-title {
      padding-bottom: 0px;
    }
  }

  .list-group {
    .details {
      display: inline-flex;
    }
  }

  .list-desc-title {
    font: normal normal 0.72rem/0.92rem 'campton-medium';
    font-weight: bolder;
    color: #000000;
    opacity: inherit;
  }

  .list-desc-details {
    font: normal normal normal 0.72rem/1rem Arial;
    color: $gray-1;
    opacity: inherit;
    padding-top: 5px;
  }

  .tab-content {
    p {
      font: normal normal normal 0.8rem/1.04rem Arial;
    }

    // margin-bottom: 102px;
    .no-tab-title {
      padding-top: 1.76rem;
    }

    .list-group-item {
      padding: 1.2rem;
      margin-bottom: 0.8rem;

      .custom-tooltip-spending img {
        padding-left: 5px;
      }
    }

    .list-group-item:last-child {
      margin-bottom: 0px;
    }
  }

  .no-tab-title {
    .no-records {
      color: #ff0000;
      margin-bottom: -14px;
      font: normal normal normal 0.8rem/1.12rem Arial;
    }
  }

  .no-date-filter {
    .pagination {
      margin-top: unset;
    }
  }

  .show-more {
    font: normal normal normal 0.88rem/1.12rem 'campton-medium';
    text-align: center;
    display: block;
    margin-top: 44px;
    cursor: pointer;
  }

  .service-disc {
    font: normal normal normal 0.8rem/1.04rem Arial;
    word-break: keep-all;
    margin-top: 2rem;
    margin-bottom: 8px;
    letter-spacing: 0.24px;
    color: #454545;

    a {
      display: inline-block;
    }
  }

  .service-disc1 {
    font: normal normal bold 0.8rem/1.04rem Arial;
    word-break: keep-all;
    margin-top: -8px;
    letter-spacing: 0.24px;
    color: #454545;
  }

  .contact-us {
    font: normal normal normal 0.72rem/0.84rem Arial;
  }

  .history-status {
    font: normal normal 16px/30px 'campton-medium';
    width: 115px;
    border-radius: 5px;
    text-align: center;
    padding: 5px 0px;
    margin-top: -7px;
    position: absolute;
  }

  .contact-img {
    font: normal normal normal 0.72rem/0.84rem Arial;
    margin-bottom: 1px;
  }

  // .nav-item {
  //   border-right: 1px solid #f5f5f7;
  // }
  .green-bg {
    background: #4ce56f 0% 0% no-repeat padding-box;
  }

  .orange-bg {
    background: #ffdb62 0 0 no-repeat padding-box;
  }

  .red-bg {
    background: #ef7676 0% 0% no-repeat padding-box;
  }

  .yellow-bg {
    background: #ffff00 0% 0% no-repeat padding-box;
  }

  .lightgrey-bg {
    background: #a2a2a2 0% 0% no-repeat padding-box;
  }

  .view-details {
    display: flex;
    align-items: flex-start;
    font: normal normal normal 0.72rem/0.84rem Arial;
    padding-left: 0;
    padding-right: 0;
    margin-top: -1px;
  }

  .row-title {
    color: #212121;
  }

  .row-details {
    font: normal normal normal 0.88rem/1.04rem Arial;
    color: #575757;
  }

  .medications {
    margin-bottom: 29px;
  }

  .modal {
    .form-check {
      font: normal normal normal 0.8rem/23px Arial;
    }

    .modal-content {
      min-width: 690px;
    }
  }

  .custom-tooltip-spending {
    font-size: 18px;

    .custom-tooltip-spending-text {
      left: -140px;
      top: 80%;
      min-width: 190px;
    }
  }

  .claim-items {
    display: flex;
    align-items: center;
    margin-top: -20px;
  }

  .referrals-tab {
    .col-list-title {
      span {
        display: block;
        font-family: campton-book;
      }
    }
  }

  .cl-history-status-padding {
    padding-left: 0px !important;
    margin-top: 11px;
  }

  .top-negetive-margin {
    margin-top: -12px;
  }

  .ch-eob-icon > div:first-child {
    position: absolute;
  }

  // careplan Start
  .carePlanBody {
    font-family: sans-serif;
    text-align: center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid black;
  }

  .column-header {
    font-size: 16px;
    font-weight: bold;
    background-color: #009ea0;
    color: #fff;
    text-align: center;
    padding: 20px;
    white-space: nowrap;
    border-bottom: 1px solid black;
  }

  .column-content {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-right: 1px black;
  }

  .column-carePlan-content {
    width: 100%;
    height: 100%;
    border-right: 1px black;
  }

  .carePlanbtn {
    background-color: #009ea0;
    border: 1px solid black;
    padding: 8px;
    margin-top: 8px;
    color: #fff;
    font: normal normal 13px/20px Campton-medium;
    text-align: center;
    white-space: nowrap;
    width: 190px;
  }

  .column-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .column-content > div {
    height: 50px;
  }

  //careplanEnd
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .my-health-record {
    .desc {
      justify-content: flex-start;
    }

    .healthrcd-drop {
      margin-top: 1.5rem;
    }

    .search-btn {
      margin-top: 2.7rem;
    }

    // .btn-search {
    //   height: 2.5rem;
    // }
    .contact-img {
      margin-bottom: 1px;
      height: 20px;
    }

    .history-status {
      margin-top: 15px;
    }

    .list-desc-title {
      padding-top: 10px;
    }

    .eobDisclaimer {
      text-align: center !important;
      margin-top: 10px;
    }

    .claim-items {
      display: flex;
      align-items: center;
      margin-top: -5px;
    }

    
  }

  .cl-history-status-padding {
    padding-left: 0px;
    margin-top: -12px !important;
  }

  .Eob-icon-top-justify {
    margin-top: -20px !important;
  }
}

@include media-breakpoint-down(xxl) {
  .my-health-record {
    .MuiInputBase-input {
      font: normal normal normal 0.64rem/0.8rem Arial;
    }

    .nav-link {
      padding: 20px 15px;
    }

    .form-input {
      width: 80%;
    }

    .list-group {
      .details {
        display: block;
      }
    }

    .print-record {
      margin-bottom: 10px;
    }

    .service-disc1 {
      font: normal normal bold 18px/27px Arial;
    }

    .contact-us {
      font: normal normal 0.8rem/1.04rem 'campton-medium';
    }

    .desc {
      font: normal normal normal 14px/16px Arial;
      text-align: right;
    }

    .desc1 {
      font: normal normal normal 16.5px/27px Arial;
    }

    .show-btn,
    .close-btn {
      text-align: center;
      font: normal normal 0.72rem/0.92rem 'campton-medium';
      letter-spacing: 0.9px;
      margin-top: 20px;
      text-transform: uppercase;

      img {
        margin-bottom: 5px;
      }
    }

    .eobDisclaimer {
      text-align: right;
      letter-spacing: 0.26px;
      font: normal normal normal 0.8rem/1.04rem Arial;
    }
  }

  .top-negetive-margin {
    margin-top: -21px !important;
  }
}

@include media-breakpoint-down(xl) {
  .my-health-record {
    .history-status {
      width: 100px;
      height: 30px;
      padding: 0;
    }

    .list-desc-title,
    .list-desc-details {
      padding-top: 10px;
      width: 50%;
    }

    .referrals-tab {
      .col-list-title > div:first-child {
        width: 50%;
        padding-right: 10px;
      }

      .list-desc-title {
        width: 50%;
        padding-right: 10px;
        line-height: 18.4px;
      }
    }

    .medication-view-details {
      margin-top: 6px;
      display: block;
      text-align: center;
      font: normal normal 0.72rem/0.92rem Campton-medium;
      letter-spacing: 0.9px;
      color: #000000;
      text-transform: uppercase;
      margin-top: 25px;

      a {
        font: normal normal 0.72rem/0.92rem 'campton-medium';
      }
    }

    .medication-details {
      .field1 {
        padding-bottom: unset;
      }
    }
  }

  .search-btn {
    margin-top: 25px;
  }

  .btn-search {
    height: 1.98rem;
  }

  .contact-img {
    font: normal normal normal 0.72rem/0.84rem Arial;
    margin-bottom: 2px;
  }

  .history-status {
    position: unset !important;
  }
}

@include media-breakpoint-down(lg) {
  .my-health-record {
    .btn-search {
      height: 2.5rem;
      margin-top: 2px;
    }

    .back-to-list-icon {
      height: 0.8rem;
      margin: 0.1rem 0 0 -0.92rem;
      position: absolute;
    }

    .MuiInputBase-input {
      font: normal normal normal 0.72rem/0.88rem Arial !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .my-health-record {
    .molina-select__control,
    .molina-select__control:hover {
      height: auto;
    }

    .close-btn,
    .show-btn {
      font: normal normal normal 16px/20px Campton-medium;
    }

    .form-tab {
      padding: 0px 0px 16px 0px !important;
    }

    .modal {
      .form-check {
        font: normal normal normal 20px/23px Arial;
      }
    }

    .form-input {
      width: 87%;
    }

    .pagination {
      margin-top: 23px;
      margin-bottom: 32px;
    }

    .no-date-filter {
      .pagination {
        margin-top: 2px;
      }
    }

    .medications {
      margin-bottom: 0;
    }

    .history-status {
      width: 131px;
      margin-top: 0px;
      height: 36px;
      font: normal normal 18px/36px 'campton-medium';
    }

    .history-details {
      padding-top: 10px;
      padding-bottom: 2px;
    }

    .col-list-title {
      font: normal normal 23px/26px 'Campton-medium';
      padding-bottom: 5px;
    }

    .btn-search {
      float: right;
      width: 124px;
      height: 42px;
    }

    .back-to-list {
      margin-bottom: 24px;
      font: normal normal 24px/31px 'Campton-medium';
    }

    .media {
      font: normal normal normal 24px/31px 'campton-medium';

      img {
        margin-top: 5px;
      }
    }

    .view-details {
      display: block;
      text-align: center;
      font: normal normal 0.72rem/0.92rem Campton-medium;
      letter-spacing: 0.9px;
      color: #000000;
      text-transform: uppercase;
      margin-top: 25px;

      a {
        font: normal normal 0.72rem/0.92rem 'campton-medium';
      }
    }

    // .molina-select__control {
    //   height: 56px;
    // }

    .tab-content {
      .no-tab-title {
        padding-top: 12px;
      }

      .list-group-item {
        border-radius: 10px;
        padding: 2rem; //50px 32px 25px;
        margin-bottom: 30px;

        .plan-content {
          margin-bottom: unset;
        }
      }

      .no-list-title {
        .list-group-item {
          padding-top: 28px;
        }
      }

      .service-disc {
        font: normal normal normal 18px/27px Arial;
      }

      .ohio-disc {
        font: normal normal normal 18px/27px Arial;
      }
    }

    .search-btn {
      display: block;
      margin-bottom: 17px;
      margin-top: 17px;
    }

    .print-record {
      display: none !important;
    }

    .form-input {
      margin-bottom: 1.43rem;
    }

    .list-desc-title {
      padding-top: 22px;
      font: normal normal normal 22px/28px 'campton-medium';
    }

    .list-desc-details {
      letter-spacing: 0.18px;
      color: #454545;
      padding-top: 12px;
      padding-bottom: 0;
      width: 100%;
    }

    .medication-details {
      margin-bottom: 30px;

      .tab-sub-title {
        font: normal normal 22px/28px 'Campton-medium';
        padding-bottom: 3px;
      }

      .row-title {
        padding-top: 22px;
        font: normal normal normal 22px/28px 'campton-medium';
      }

      .row-details {
        font: normal normal normal 0.72rem/0.84rem Arial;
        padding-top: 12px;
      }

      .field1 {
        padding-bottom: unset;
      }
    }

    .show-more {
      margin-top: 40px;
    }

    .service-disc {
      margin-top: 33px;
      letter-spacing: 0px;

      .disc-bld {
        margin-top: 20px;
      }
    }

    .desc {
      margin-top: 8px;
      margin-bottom: 12px;
    }

    .page-subheading {
      font: normal normal 0.88rem/1.12rem 'Campton-medium';
    }

    .service-disc1 {
      letter-spacing: 0px;
    }

    .btn-search {
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.36px;
      color: #ffffff;
    }

    .contact-img {
      height: 23px;
      margin-bottom: 1px;
    }

    .label {
      color: #000000;
    }

    .desc {
      justify-content: start !important;
    }

    .claim-items {
      margin-top: 20px;
      // font: normal normal 0.88rem/1.12rem 'Campton-medium';
      color: #000000;
    }

    .eobDisclaimer {
      margin-top: 10px;
      letter-spacing: 0.26px;
      font: normal normal normal 0.8rem/1.04rem Arial;
      text-align: left;
    }

    .referrals-tab {
      .col-list-title {
        > div:first-child {
          width: 100%;
          padding-right: 0px;
        }

        > span:first-child {
          display: inline-block;
        }

        span {
          font-family: campton-book;
          font-size: 20px;
          line-height: 35px;
        }
      }

      .list-desc-title {
        width: 100%;
        padding-right: 0px;
        line-height: 25px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .my-health-record {
    .details {
      .list-desc-details {
        padding-top: 5px;
      }
    }

    .form-input {
      width: 84%;
    }

    .btn-search {
      font: normal normal 23px/20px 'Campton-medium';
      height: 2.2rem;
    }

    .search-btn {
      margin-top: 33px;
    }

    .contact-img {
      margin-bottom: 2px;
      height: 30px;
    }

    .tab-content {
      p {
        font: normal normal normal 0.8rem/1.04rem Arial;
      }
    }

    .service-disc {
      .disc-bld {
        font-weight: bold;
      }
    }

    .eobDisclaimer {
      text-align: right;
      letter-spacing: 0.26px;
      font: normal normal normal 0.8rem/1.04rem Arial;
    }

    .Eob-icon-top-justify {
      margin-top: -18px;
    }

    // .icon-top-justify {
    //   margin-top: -18px !important;
    // }
    .cl-history-status {
      margin-top: -11px !important;
    }

    .view-details {
      margin-top: 0px;
    }

    .back-to-list-icon {
      height: 0.8rem;
      margin: 0 0 0 -0.92rem;
      position: absolute;
    }
  }
}

// min-width:960
@include media-breakpoint-up(lg) {
  .my-health-record {
    .cl-history-status {
      margin-top: 11px !important;
    }

    .EOB-top-justify {
      align-items: flex-start;
      margin-top: 4px !important;
    }

    .Eob-icon-top-justify {
      align-items: flex-start;
    }
  }

  .top-negetive-margin {
    margin-top: -12px;
  }

  .back-to-list-icon {
    height: 0.8rem;
    margin: 0.1rem 0 0 -0.92rem;
    position: absolute;
  }

  .btn-search {
    margin-top: 2px;
  }
}

@include media-breakpoint-up(md) {
  .my-health-record {
    .referrals-tab {
      .col-list-title {
        > span:first-child {
          display: inline-block;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .my-health-record {
    .Eob-icon-top-justify {
      align-items: flex-start;
      margin-top: 4px;
    }
  }
}

@media (max-width: 1036px) {
  .wid {
    flex: 0 0 100% !important;
  }
}

@media (max-width: 767.98px) {
  .my-health-record {
    // careplan Start
    .carePlanBody {
      font-family: sans-serif;
      text-align: center;
      width: 100%;
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border: 1px solid black;
    }

    .column-header {
      font-size: 8px;
      font-weight: bold;
      background-color: #009ea0;
      color: #fff;
      text-align: center;
      padding: 9px;
      white-space: initial;
      border-bottom: 1px solid black;
    }

    .column-content {
      width: 83%;
      height: 50px;
      padding: 10px;
      border-right: 1px black;
      font-size: 10px;
    }

    .column-carePlan-content {
      width: 100%;
      height: 100%;
      border-right: 1px black;
      padding-bottom: 10px;
    }

    .carePlanbtn {
      background-color: #009ea0;
      border: 1px solid black;
      padding: 0px;
      margin-top: 8px;
      color: #fff;
      font: normal normal 8px/20px Campton-medium;
      white-space: initial;
      text-align: center;
      width: 76px;
      height: 35px;
    }

    .column-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .column-content > div {
      height: 50px;
      font-size: 10px;
    }

    //careplanEnd
  }
}

// @media (min-width: 768px) and (max-width: 959px) {
//   .my-health-record {
//     .btn-search {
//       height: 2.5rem;
//     }
//     .back-to-list-icon {
//       height: 0.8rem;
//       margin: 0.1rem 0 0 -0.92rem;
//       position: absolute;
//     }
//     .molina-select__control {
//       font: normal normal normal 0.8rem/0.8rem Arial;
//     }
//     .MuiInputBase-input {
//       font: normal normal normal 0.8rem/0.96rem Arial !important;
//     }
//   }
// }
