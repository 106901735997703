.doctor-home {
  .page-heading {
    padding-bottom: 2rem;
  }
  .custom-btn .btn {
    color: #009ea0;
    background: #fff;
    text-transform: uppercase;
  }
  .change-doctor-btn .btn {
    text-transform: uppercase;
  }
  .rate-doctor-btn .btn{
	text-transform: uppercase;
  }
  .mydoctor-content {
    //padding-bottom: 5.625rem;

    .doctorappontmentborder {
      background: #fff 0 0 no-repeat padding-box;
      border: 1px solid #999;
      border-radius: 5px;
      height: 44px;
      opacity: 1;
      padding-top: 11px;
      width: 332px;
    }
    .doctorappontment {
      color: #009ea0;
      font: normal normal normal 16px/20px "campton-medium";
      letter-spacing: 0.36px;
      opacity: 1;
      text-align: center;
      padding-left: 18px;
    }
    .doctorinfo-card {
      border-radius: 12px;
    }

    .clickchangedoctor {
      text-align: center;
      font: normal normal normal 18px/20px "campton-medium";
      letter-spacing: 0.36px;
      color: #ffffff;
      background: #009ea0 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 2px #0000003d;
      border-radius: 5px;
      opacity: 1;
      margin-left: 16px;
      width: 233px;
      height: 44px;
    }
    .custom-container {
      display: grid;
      grid-template-columns: 70% 30%;
      .btn {
        text-transform: uppercase;
      }
    }
    .field-wapper {
      display: grid;
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
	.field-wapper-1 {
      display: grid;
      grid-template-columns: 20% 20% 20% 40%;
    }
    .list-group-item {
      height: 406px;
    }
    .field1 {
      margin-top: 28px;
    }
    .field2 {
      margin-top: -10px;
    }
    .doctor-row-title {
      width: 210px;
    }
    .docbusinesshours {
      text-align: left;
      font: normal normal bold 12px/21px Arial;
      letter-spacing: 0px;
      color: #009ea0;
      opacity: 1;
      padding-top: 14px;
    }

    // desktop
    @include media-breakpoint-up(lg) {
      .row-details {
        margin-bottom: 20px;
      }
      .doctor-title {
        font: normal normal normal 1.2rem/1.52rem "campton-medium";
        letter-spacing: 0px;
        color: #212121;
      }
      .doctor-row-details {
        font: normal normal normal 0.8rem/1.04rem Arial;
        color: #454545;
      }
      .docbusinesshours {
        font: normal normal bold 0.72rem/0.84rem Arial;
      }
      .custom-container {
        display: grid;
        grid-template-columns: 70% 30%;
		margin-block-end:10px;
      }
      .field-wapper {
        display: grid;
      }
      .hospital-details {
        position: relative;
        bottom: 210px;
      }
      .hospital-address-details {
        position: relative;
        bottom: 210px;
      }
    }
    @include media-breakpoint-down(xxl) {
      .custom-container {
        grid-template-columns: 70% 30%;
      }
    }
    @include media-breakpoint-between(lg, md) {
      .hospital-details {
        position: relative;
        bottom: 270px;
      }
      .hospital-address-details {
        position: relative;
        bottom: 270px;
      }
    }
    @include media-breakpoint-only(md) {
      .hospital-details {
        position: relative;
        bottom: 270px;
      }
      .hospital-address-details {
        position: relative;
        bottom: 270px;
      }
    }
    //laptop
    @include media-breakpoint-down(xl) {
      .doctor-title {
        font: normal normal normal 1.2rem/1.52rem "campton-medium";
        letter-spacing: 0px;
        color: #212121;
      }
      .doctorappontmentborder {
        width: 250px;
        height: 60px;
        text-align: center;
      }
      .docbusinesshours {
        font: normal normal bold 0.72rem/0.84rem Arial;
      }
      .doctor-row-details {
        font: normal normal normal 0.8rem/1.04rem Arial;
        color: #454545;
      }
      .field-wapper {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
    //tablet
    @include media-breakpoint-down(lg) {
      .custom-container {
        display: grid;
        grid-template-columns: 100%;
      }
      .field-wapper {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
    //mobile
    @include media-breakpoint-down(md) {
      .doctor-title {
        font: normal normal normal 25px/32px "campton-medium";
        margin-bottom: 25px;
      }
      .field1 {
        margin-top: 0px;
      }
      .row-title {
        padding-bottom: 7px;
      }
      .row-details {
        font: normal normal normal 18px/21px Arial;
      }
      .doctor-row-details {
        font: normal normal normal 18px/21px Arial;
        color: #454545;
      }
      .custom-container {
        display: grid;
        grid-template-columns: 100%;
      }
      .field-wapper {
        display: grid;
        grid-template-columns: 100%;
      }
      .field-wapper-1 {
        display: grid;
        grid-template-columns: 100%;
      }
    }
  }

  .mydoctor-cost {
    font: normal normal normal 0.8rem/0.92rem Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-bottom: 130px;
  }
  @include media-breakpoint-down(xl) {
    .custom-btn {
      margin-bottom: 24px;
    }
    .change-doctor-btn {
      margin-bottom: 14px;
    }
	.rate-doctor-btn {
      margin-bottom: 12px;
    }
  }
  //out-tab
  @include media-breakpoint-down(lg) {
    .row-title {
      padding-bottom: 0rem;
    }
    .row-details {
      margin-bottom: 20px;
    }
    .doctorappontmentborder {
      background: #fff 0 0 no-repeat padding-box;
      border: 1px solid #999;
      border-radius: 5px;
      height: 44px;
      opacity: 1;
      padding-top: 11px;
      width: 303px;
      margin-bottom: 14px;
      margin-left: 8px;
    }
    .doctorappontment {
      color: #009ea0;
      font: normal normal normal 14px/20px "campton-medium";
      letter-spacing: 0.36px;
      opacity: 1;
      text-align: center;
      padding-left: 10px;
    }

    .businesshoursfont {
      font: normal normal bold 0.72rem/1.2rem Arial;
    }
    .businesshoursweekfont {
      font: normal normal normal 0.72rem/0.84rem Arial;
      letter-spacing: 0px;
      color: #454545;
    }
    .businesspopupfont {
      text-align: left;
      font: normal normal normal 0.88rem/1.12rem "campton-medium";
      letter-spacing: 0.26px;
      color: #000000;
      opacity: 1;
      padding-bottom: 32px;
    }
    .custom-btn {
      .btn {
        font: normal normal normal 14px/20px "campton-medium";
        color: #009ea0;
      }
    }
    .custom-container {
      display: grid;
      grid-template-columns: 100%;
    }
    .field-wapper {
      display: grid;
      grid-template-columns: 100%;
    }
  }
  //mobile -out
  @include media-breakpoint-down(md) {
    .doctorappontmentborder {
      background: #fff 0 0 no-repeat padding-box;
      border: 1px solid #999;
      border-radius: 5px;
      height: 44px;
      opacity: 1;
      width: 303px;
      margin-bottom: 14px;
      margin-left: 8px;
    }
    .doctorappontment {
      color: #009ea0;
      font: normal normal normal 14px/20px "campton-medium";
      letter-spacing: 0.36px;
      opacity: 1;
      text-align: center;
      padding-left: 11px;
    }

    .businesshoursfont {
      font: normal normal bold 18px/30px Arial;
    }
    .businesshoursweekfont {
      font: normal normal normal 18px/21px Arial;
      letter-spacing: 0px;
      color: #454545;
    }
    .businesspopupfont {
      text-align: left;
      font: normal normal normal 22px/28px "campton-medium";
      letter-spacing: 0.26px;
      color: #000000;
      opacity: 1;
      padding-bottom: 32px;
    }
    .custom-btn {
      .btn {
        padding: 12px 17px;
        font: normal normal normal 14px/20px "campton-medium";
        color: #009ea0;
      }
    }
    .mydoctor-cost {
      font: normal normal normal 16px/24px Arial;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      padding-bottom: 161.5px;
    }
    .custom-container {
      display: grid;
      grid-template-columns: 100%;
    }
    .field-wapper {
      display: grid;
      grid-template-columns: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    .businesshourspopup {
      top: 10px;
      left: 324px;
      width: 706px;
      height: 494px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px #00000040;
      opacity: 1;
      padding: 20px;
    }
    .businesspopupfont {
      text-align: left;
      font: normal normal bold 0.88rem/1.2rem Arial;
      letter-spacing: 0.26px;
      color: #000000;
      opacity: 1;
      padding-bottom: 32px;
    }

    .businesshoursweekfont {
      text-align: left;
      font: normal normal normal 0.88rem/1.04rem Arial;
      letter-spacing: 0px;
      color: #454545;
      opacity: 1;
    }

    .businesshoursfont {
      text-align: left;
      font: normal normal bold 0.88rem/1.2rem Arial;
      letter-spacing: 0.26px;
      color: #000000;
      opacity: 1;
    }
  }
}
