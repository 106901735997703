.mgBackgroundIDMedicaid {
  margin-top: -15%;
  padding-left: 6%;
}
.famis-id-card-color {
  color: #39a29b;
}
.mgReferTextIDMEdicaid {
  margin-top: 17%;
}
.memberIdServicesIDMedicaid {
  padding-left: 5%;
}
.font-id-footer {
  font: normal normal normal .8rem/.92rem Arial;
}
.memberILServicesIDMedicaid {
  padding-left: 5%;
}
.inline-flex {
  display: inline-flex;
}

.td-width {
  width: 60% !important;
}

.id-card-dual-mi {
  label {
    width: 38%;
  }
}
.IDRXValues {
  margin-top: 16%;
  padding-left: 20%;
}
.ILDualTopContent {
  margin-top: -7%;
}
.ILRXValues {
  margin-top: 15%;
}
.medicaidIDBackView {
  font-size: 112%;
  line-height: 117%;
  padding-left: 3%;
}
.IDcardBackText {
  font: normal normal 11px / normal Campton-medium;
}
.IDcardBackText > ul > li:nth-child(even) {
  padding-bottom: 10px;
}
.medicareAZ {
  padding-left: 52%;
}
.medicaidNVright {
  margin-top: 20px;
  padding-left: 5px;
}
.medicaidNVleft,
.medicaidNVright {
  font: normal normal 14px / normal Campton-medium;
}
.medicaidNVleft > ul > li:not(:last-child),
.medicaidNVright > ul > li:not(:last-child),
.medicareAZ > ul > li:not(:last-child) {
  padding-bottom: 5px;
}
.azheight {
  height: 25px;
}
.azportal-title {
  position: relative;
  top: 20px;
}
.azTextRight {
  text-align: right;
  color: #00a4b3;
  padding-right: 20px;
  position: relative;
  bottom: 15px;
}
#memberIdCardNYLTC {
  .width-25-ltc {
    width: 25%;
  }
  .inline-flex {
    display: inline-flex;
  }
}
@include media-breakpoint-down(md) {
  .medicaidNVleft,
  .medicaidNVright {
    font: normal normal 9px / normal Campton-medium;
  }
  .mrgncardright {
    margin-top: -15%;
    padding-left: 6%;
    font-size: 8px;
    line-height: 119%;
  }
  .mgBackgroundIDMedicaid {
    margin-top: -4%;
    padding-left: 6%;
    font-size: 8px;
  }
  .memberIdServicesIDMedicaid {
    padding-left: 5%;
    font-size: 8px;
    line-height: 111%;
  }
  .IDRXValues {
    margin-top: 16%;
    padding-left: 20%;
  }
  .ILDualTopContent {
    margin-top: -7%;
    font-size: 12px;
    line-height: 134%;
  }
  .memberILServicesIDMedicaid {
    padding-left: 5%;
    font-size: 8px;
    line-height: 100%;
  }
  .ILRXValues {
    line-height: 132%;
    font-size: 8px;
    margin-top: -10%;
  }
  .medicaidIDBackView {
    font-size: 8px;
    line-height: 117%;
    padding-left: 3%;
  }
  .mgReferTextIDMEdicaid {
    margin-top: 17%;
  }
  .medicaidNVright {
    margin-top: 2px;
    padding-left: 0px;
  }
  .azportal-title {
    position: relative;
    top: auto;
  }
}
.card-f-style .myMolinaIDCardText {
  font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
}
.member-id-card .background-idcard.member-id-medicare-ut {
  padding: 5.8rem 1rem 1.1rem 0;
}
.KYaddress {
  padding-left: 80px;
}
.frontviewimageKY {
  min-height: 393px !important;
  padding: 3.7rem 1rem 1.1rem 0 !important;
}
.rowHeight {
  margin-top: -5px;
}
.contentFromTop {
  margin-top: 15px;
}
.contentFromTopFivePx {
  margin-top: 5px;
}
.contentFromTopTwentyPx {
  margin-top: 20px;
}
#memberNYMLTCID {
  margin-left: 3rem;
}
.contentFromTopFiftyPx {
  margin-top: 50px;
}
.member-id-card {
  // margin-bottom: 45px;
  .img-fluid {
    width: 100%;
  }
  #id-card-container.container-fluid {
    position: relative;
  }
  .table-ut-shiftright {
    padding-left: 68%;
  }
  .table-ca-shiftright {
    padding-left: 8%;
  }
  .table-ut-bottom {
    padding-left: 424px;
    color: #00a4b3;
    font-size: '14px';
  }
  .table-il-bottom {
    padding-left: 403px;
    color: #00a4b3;
    font-size: '14px';
  }
  .table-il-plan {
    color: #d2e26d;
    font-size: 14px;
  }
  .idcard-OH-medicaid-color {
    color: #1434a4;
  }
  #memberIdCardMS {
    .table-font-size {
      font-size: 13px;
    }
  }
  .table-font-size {
    font-size: 14px;
  }
  
  .table-tr-height-az td {
    height: 22px;
  }
  @include media-breakpoint-down(md) {
    .table-tr-height-az td {
      height: 15px;
    }
    #memberIdCardSC,
    #memberIdCardBackMs {
      .table-font-size {
        font-size: 8px;
      }
    }
    .table-font-size {
      font-size: 11px;
    }
    .IDcardBackText {
      font: normal normal 8px / normal Campton-medium;
    }
  }
  .table-font-size-back {
    font-size: 12px;
    line-height: 13px;
  }
  .rural-heading {
    padding-left: 4rem;
  }
  // .wa-cascade-logo {
  //   width: 16%;
  //   height: 92px;
  //   margin-top: 18px;
  //   margin-left: 35%;
  // }
  .background-idcard {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 1;
    background-position: center;
    font: normal normal 0.7rem/0.8rem 'Campton-medium';
    // font: normal normal 0.8vw/0.9vw 'Campton-medium';
    width: 100%;
    // padding: 8% 1% 1% 1%;
  }
  .no-id-card {
    font: normal bold 1.5rem/1.8rem 'Campton-light';
    padding: 2rem;
  }
  #memberCurrentID {
    margin-left: 3rem;
  }
  td {
    // width: 57%;
    width: 50%;
    padding-right: 5px;
    vertical-align: top;
  }
  .table-bold-seperator {
    border-left: 2px solid grey;
    padding-left: 10px;
  }
  .table-bold-seperatorNV {
    margin-right: 30px;
  }
  .table-second-heading {
    color: #00a2b1;
    text-decoration: none;
  }
  .table-second-heading-ky {
    color: #00a2b1;
    text-decoration: none;
  }
  .table-costsummary {
    padding-top: 10px;
    padding-right: 10px;
  }
  .table-costsummary2 {
    padding-right: 10px;
  }
  .member-id-card-back {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    font: normal normal 0.7rem/0.8rem 'Campton-medium';
    // font: normal normal 0.8vw/0.9vw 'Campton-medium';
    padding: 1% 2% 6% 2%;
    width: 100%;
    margin: auto;
  }
  .member-id-card-back-text {
    padding: 0rem 1rem 0rem 1rem;
  }
  .mt-93-oh {
    margin-top: -93px;
  }
  .mt-50-oh {
    margin-top: 50px;
  }
  .ml-14-oh {
    margin-left: 14px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mt-15-oh {
    margin-top: 15px;
  }
  .pt-20-oh {
    padding-top: 20px;
  }
  .gainwell-icon-adjust {
    font-size: 11px;
    padding-left: 35px;
    margin-top: -13px;
  }
  .member-id-card-back-heading {
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
  }
  .member-id-card-back-seperator {
    padding-top: 7px;
  }
  .page-heading {
    margin-bottom: 1.56rem;
  }
  .plan-year-block {
    padding-top: 5px;
    .plan-year-space {
      width: 15%;
    }
    > div {
      display: inline-block;
      font: normal normal normal 0.88rem/1.12rem Campton-medium;
      letter-spacing: 0px;
      color: #212121;
      select {
        width: 138 * 0.04rem;
        margin-left: $spacer * 1.563;
        font: normal normal normal 0.8rem/0.8rem Arial;
        letter-spacing: 0.19px;
        border-radius: 4px;
        padding: 0.72rem 0.86rem 0.64rem 0.86rem;
      }
    }
    .molina-select__menu {
      font: normal normal normal 0.8rem/0.8rem Arial;
    }
  }
  .molina-select-wrapper {
    min-width: 138px;
    //margin-left: 25px;

    .molina-select__control {
      border-radius: 4px !important;
      padding-left: 25px;
    }

    .fa-caret-down {
      font-size: 20px;
    }
  }
  .mrgn-top {
    margin-top: 100px;
    padding-left: 10px;
  }
  .margn-top-100 {
    margin-top: 100px;
  }
  .mt70 {
    margin-top: 70px;
  }
  .line-height-mi {
    font: normal 12px/24px 'Campton-medium';
  }
  .address-height-mi {
    font: italic 12px/20px 'Campton-medium';
  }
  .molina-select__control {
    font: normal normal normal 0.8rem/0.8rem Arial;
  }
  .form-select {
    font: normal normal normal 0.8rem/0.8rem Arial;
    border-radius: 4px;
  }
  .select-gap {
    margin-bottom: 57px;
  }
  .plan-name-id-card {
    font-size: 14px;
    color: #00a4b3;
  }
  .btn {
    // border: 1px solid #999999;
    // background: $white 0% 0% no-repeat padding-box;
    // color: $primary;
    // border-radius: 4px;
    width: auto;
    //height: 2.16rem;
    //padding: 0.4rem 1rem 0.4rem 1rem;
    font: normal normal normal 0.8rem/1.08rem 'campton-medium';
    white-space: nowrap;
    display: inline-block;
  }
  // .request-id-modal-btn {
  //   // margin-left: -2rem;
  // }
  // .btn-primary {
  //   color: $white;
  //   background-color: $primary;
  // }
  .space-btw {
    margin-left: 10px;
  }
  .mbl-label {
    font: normal normal normal 0.72rem/0.84rem Arial;
    margin-bottom: 12px;
  }
  .text-align-center {
    padding-top: 25px;
    p {
      font: normal normal normal 0.88rem/1.12rem 'campton-medium';
      margin-bottom: 29px;
    }
  }
  .text-justify {
    text-align: justify;
  }
  .select-gap {
    .form-select {
      width: 285px;
      padding: 0.68rem 0.72rem;
    }
  }
  .tx-member-idcard {
    td {
      width: 70%;
      padding-right: 5px;
    }
  }
  .print-options-modal {
    .id-print {
      width: auto;
      margin-bottom: 12px;
      .popup2 {
        .modal-title {
          padding-bottom: 11px;
        }
        .modal-body {
          p {
            display: none;
          }
        }
        .img-gap {
          margin-top: 34px;
        }
        .modal-footer {
          padding-top: 38px;
        }
      }
      .modal-header {
        padding: 24px;
        .modal-mbl-title {
          display: none;
        }
      }
      .modal-body {
        padding: 2px 50px;
        p {
          margin-bottom: 18px;
        }
        .new-id-print1 {
          margin-top: 36px;
        }
        .form-check {
          margin-top: 24px;
          margin-bottom: 0px;
        }
        .id-details {
          font: normal normal bold 0.88rem/1.04rem Arial;
          margin-top: 36px;
          p {
            margin-top: 11px;
          }
        }
      }
      .modal-footer {
        padding: 44px 50px 50px;
        .btn {
          //padding: 11px 11px 11px;
          float: right;
        }
        .btn-primary {
          margin-left: 16px;
          line-height: 20px;
        }
      }
      .modal-title {
        padding-bottom: 1rem;
      }
    }
    .id-disclaimer {
      font-family: Campton-medium;
    }
  }
}
#memberIdCardNY {
  .background-idcard {
    tr {
      td:first-child {
        width: 67%;
      }
    }
  }
}
#memberIdCardIL,
#memberIdCardSC,
#memberIdCardMS {
  .background-idcard {
    tr {
      td {
        width: 68%;
      }
    }
  }
}
.request-new-id-header {
  font: normal normal normal 1.04rem/1.32rem 'campton-medium';
  padding-left: 1.3rem;
  padding-top: 2rem;
  .hide-element {
    display: none !important;
  }
}

.request-new-id-body {
  .request-new-id-body-title {
    font: normal normal normal 1rem/1.5rem 'Arial';
    margin-bottom: 30px;
  }
  .id-details-request-id {
    font: normal normal bold 0.88rem/1.04rem Arial;
    margin-top: 36px;
    > p {
      margin-top: 11px;
      margin-bottom: 11px;
    }
  }
  .form-check {
    margin-bottom: 15px;
  }
}

@include media-breakpoint-up(xxl) {
  .azheight {
    height: 5rem;
  }

  .member-id-card {
    .myid-filters {
      margin-left: -3%;
    }
    .plan-year-block {
      .plan-year-space {
        width: 18%;
      }
    }
    // .request-id-modal-btn {
    //   // margin-left: 1rem;
    // }
    .member-id-card-back {
      background-size: 100% 100%;
      padding: 0.5rem 0.3rem 2rem 0.3rem;
      min-height: 380px;
      margin: auto;
      width: 100%;
    }

    .chp-back-id {
      padding: 1.5rem 0.3rem .25rem 1.3rem;
      min-height: 410px;
    }
    .wa-cascade-logo {
      width: 17%;
      height: 92px;
      position: absolute;
      left: 77%;
      top: 3%;
      // margin-top: 18px;
      // margin-left: 35%;
    }
    .rural-heading {
      padding-left: 4.8rem;
    }
    #memberCurrentID {
      margin-left: 1rem;
    }
    .medicare-id-card-spacer {
      height: 3.5rem;
      td {
        width: 51%;
      }
    }
    td {
      width: 50%;
      padding-right: 5px;
    }
    .background-idcard {
      background-size: 100% 100%;
      //padding: 5rem 0.5rem 1.3rem 0rem;
      padding: 7rem 1.1rem 3rem 0;
      min-height: 380px;
      width: 100%;
      margin: auto;
    }
    .misc-headings-first-card-UT {
      justify-content: center;
      padding: 0px 5px 0.4rem 5px;
      font: normal normal 0.6rem/0.7rem 'Campton-medium';
      margin-top: -54px;
      // margin-right: -93px;
      margin-right: -17px;
    }
    .misc-headings-first-card-OH {
      justify-content: center;
      padding: 0px 5px 0.4rem 5px;
      font: normal normal 0.6rem/0.7rem 'Campton-medium';
      margin-top: -75px;
      margin-right: -56px;
    }
    .misc-headings-first-card-TX {
      justify-content: center;
      padding: 0px 5px 0.4rem 5px;
      font: normal normal 0.6rem/0.7rem 'Campton-medium';
      margin-top: -69px;
      margin-right: -39px;
    }
    .print-options-modal {
      .id-print {
        // padding-right: 0;
        .modal-footer {
          .btn-primary {
            margin-right: -20px;
          }
        }
      }
    }
    .id-front-header {
      height: 5rem;
    }
    .id-front-logo-header {
      position: absolute;
      // margin-top: -6%;
      left: 2px;
      width: 40%;
      min-width: 299px;
      max-width: 309px;
    }
    .id-front-logo-header-Passport {
      position: absolute;
      // margin-top: -6%;
      left: 2px;
      width: 40%;
      padding: 10px;
      min-width: 299px;
      max-width: 330px;
    }
    .id-front-logo-header-logo-Passport {
      position: absolute;
      // margin-top: -6%;
      left: 2px;
      width: 40%;
      padding: 10px;
      min-width: 299px;
      max-width: 330px;
    }

    .cvs-caremark {
      width: 30%;
      // float: right;
      // margin-top: -5%;
      // margin-right: 21px;
      position: absolute;
      left: 64%;
      bottom: 10px;
    }
    .p-l-20 {
      padding-left: 32%;
    }
    .p-l-20-ky {
      padding-left: 7%;
    }
    .mymolina-disclaimer-ky {
      color: #00a2b1;
      vertical-align: bottom;
    }
    .card-adj {
      padding-top: 0rem;
      margin-bottom: 30px;
      padding-left: 25px;
      padding-right: 25px;
      // padding-bottom: 40px;

      .card {
        background-color: transparent;
        box-shadow: none;
        padding-bottom: 10px;
        height: 70px;
        margin-top: 5px;
        position: relative;
      }
      .id-card-logo-hide {
        display: none;
      }
      .ca-header {
        position: absolute;
      }
      .california-dental {
        margin-left: 10px;
      }
      .misc-headings {
        margin-top: 1%;
        font: normal normal 0.6rem/0.65rem 'Campton-medium';
      }
      .misc-headings-left {
        float: left;
        padding: 1.5rem 2px 1.5rem 1.3rem;
        font: normal normal 0.6rem/0.65rem 'Campton-medium';
        margin-top: -21px;
      }
    }
    .popup2 .card-adj .misc-headings-left {
      margin-top: -40px;
    }
  }
  .card-f-style label {
    // font: normal normal 0.5rem/0.6rem 'Campton-medium';
    font: normal normal 0.6rem/0.65rem 'Campton-medium';
  }
  // .p-l-20 {
  //   padding-left: 20px;
  // }
  .p-r-20 {
    padding-right: 20px;
  }
  .mymolina-disclaimer {
    color: #00a2b1;
    vertical-align: top;
  }
}

@include media-breakpoint-down(xxl) {
  #memberIdCardFl {
    .background-idcard {
      min-height: 308px;
      padding: 4.5rem 1rem 1.1rem 0;
    }
    .member-services-id {
      padding-top: 5px;
    }
  }
  .member-id-card {
    .plan-care-name {
      color: darkgreen;
      font-size: 12px;
    }
    .plan-year-block {
      padding-top: 0px;
      margin-top: -5px;
      > div {
        select {
          letter-spacing: 0.19px;
          // padding: 18px 18px 16px 18px;
        }
      }
      .plan-year-space {
        width: 20%;
      }
    }
    .rural-heading {
      padding-left: 4.8rem;
    }
    // table {
    //   width: 100% !important;
    // }
    .bg-front {
      // padding: 2.5rem 0.8rem 0rem 0.8rem !important;
      padding: 0.1rem 0.6rem 0rem 0.6rem !important;
      min-height: 400px;
      position: relative;
    }
    .id-front-header {
      height: 4.2rem;
      position: relative;
    }
    .id-front-logo-header {
      position: absolute;
      // margin-top: -10% ;
      left: 2px;
      width: 46%;
      max-width: 230px;
      min-width: 225px;
    }
    .id-front-logo-header-Passport {
      position: absolute;
      // margin-top: -10%;
      left: 2px;
      width: 46%;
      max-width: 230px;
      min-width: 225px;
      padding: 10px;
    }
    .id-front-logo-header-logo-Passport {
      position: absolute;
      // margin-top: -10%;
      left: 2px;
      width: 46%;
      max-width: 250px;
      min-width: 225px;
      padding: 23px 15px;
    }

    .bg-back {
      min-height: 400px;
      position: relative;
    }
    .card-content-id-back {
      margin: 1rem 0.8rem 1.5rem 0.8rem;
    }
    .id-card-back-disclaimer {
      margin: 1rem 0.1rem 0rem 0.1rem;
      font: normal normal 0.6rem/0.65rem 'Campton-medium';
      position: absolute;
    }
    .p-l-20 {
      padding-left: 24%;
    }
    .p-l-20-ky {
      padding-left: 5%;
    }
    .mymolina-disclaimer-ky {
      color: #00a2b1;
      vertical-align: bottom;
    }
    .member-id-card-back {
      background-size: 100% 100%;
      padding: 0rem 0rem;
      min-height: 380px;
      font: normal normal 0.7rem/0.75rem 'Campton-medium';
      // font: normal normal 1vw/1.1vw 'Campton-medium';
      margin: auto;
      width: 100%;
    }
    .member-id-card-back-text {
      padding: 1rem 1rem 1.7rem 1rem;
    }
    .chp-back-id {
      padding: 3.5rem 0.3rem .25rem 1.3rem;
      min-height: 380px;
    }
    #memberCurrentID {
      margin-left: 1rem;
    }
    .background-idcard {
      background-size: 100% 100%;
      padding: 6.5rem 1rem 1.1rem 0rem;
      // font: normal normal 0.5rem/0.6rem 'Campton-medium';
      font: normal normal 0.7rem/0.75rem 'Campton-medium';
      // font: normal normal 1vw/1.1vw 'Campton-medium';

      min-height: 380px;
      width: 100%;
      margin: auto;
    }
    .background-id-row {
      padding: 5.5rem 1rem 1.1rem 0rem;
      .plan-name-id-card {
        font-size: 0.6rem;
        color: #00a4b3;
        margin-left: 1rem;
      }
    }
    .height-adjustment-pcp {
      height: 32px;
    }
    #frontidcardimage {
      padding-top: 8.6rem;
    }
    .frontviewimageKY {
      min-height: 345px !important;
      padding: 5.5rem 1rem 1rem 0 !important;
    }
    .backcardNV {
      line-height: 15px;
    }
    .seperatorNV {
      padding-left: 70px;
    }
    table.KYtable td {
      padding-top: 4px;
      width: 68%;
    }
    #frontidcardimageNV {
      padding: 8.8rem 1.1rem 5.1rem 0px;
      min-height: 380px;
    }
    @include media-breakpoint-down(md) {
      #frontidcardimageNV {
        min-height: 280px;
        padding: 8.8rem 0.5rem 1rem 0;
      }
    }
    .member-id-card table.NVtable td {
      width: 68%;
      padding-top: 2px;
    }

    .misc-headings-first-card-UT {
      justify-content: center;
      padding: 0px 0px 0.4rem 0rem;
      margin-top: -36px;
      font: normal normal 0.6rem/0.65rem 'Campton-medium';
      // margin-right: -70px;
      margin-right: -10px;
    }
    .misc-headings-first-card-OH {
      justify-content: center;
      padding: 0px 0px 0.4rem 0rem;
      font: normal normal 0.6rem/0.65rem 'Campton-medium';
      margin-top: -39px;
      margin-right: -34px;
    }
    .misc-headings-first-card-TX {
      justify-content: center;
      padding: 0px 0px 0.4rem 0rem;
      font: normal normal 0.6rem/0.65rem 'Campton-medium';
      margin-top: -37px;
      margin-right: -24px;
    }
    .id-card-spacer {
      height: 2.6rem;
    }
    td {
      width: 50%;
      padding-right: 5px;
    }
    // .print-options-modal {
    //   .id-print {
    //     // padding-right: 0;
    //   }
    // }
    .cust-padding {
      padding-right: 80px;
    }
    .cvs-caremark {
      width: 32%;
      position: absolute;
      left: 64%;
      bottom: 10px;
    }
    .wa-cascade-logo {
      height: 3.3rem;
      // margin-left: 33%;
      // margin-top: 11px;
      width: 18%;
      position: absolute;
      left: 80%;
      top: 18%;
      max-width: 99px;
      min-width: 80px;
    }
    .card-adj {
      padding: 0.5rem 5px 0px !important;
      // padding-top: 3rem;
      margin-bottom: 20px;
      // padding-left: 0px;
      // padding-right: 0px;
      .card {
        background-color: transparent;
        box-shadow: none;
        padding-bottom: 10px;
        height: 50px;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;
      }
      .id-card-logo-hide {
        display: none;
      }
      .ca-header {
        position: absolute;
      }

      .california-dental {
        margin-top: 1%;
        width: 40%;
        margin-left: 0px;
        max-width: 130px;
        min-width: 115px;
      }
      .covered-california {
        margin-top: 1%;
        width: 30%;
        max-width: 100px;
        min-width: 85px;
      }
      .misc-headings {
        font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
        margin-top: 2%;
        position: absolute;
        left: 50%;
      }
      .misc-headings-left {
        // float: left;
        // padding: 2rem 2px 0rem 0.4rem;
        position: absolute;
        left: 0.8rem;
        top: 30%;
        font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
        // margin-top: -35px;
        margin-top: 0;
        padding: 0;
      }
    }
    .card-padding-front {
      padding: 5.5rem 5px 0px !important;
    }
    .card-padding-back {
      padding: 2.5rem 5px 0px !important;
    }
  }
  .card-f-style label {
    // font: normal normal 0.43rem/0.5rem 'Campton-medium' !important;
    font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
  }
  // .p-l-20 {
  //   padding-left: 20px;
  // }
  .p-r-20 {
    padding-right: 20px;
  }
  .mymolina-disclaimer {
    color: #00a2b1;
  }
  .request-new-id-body {
    .form-check-label {
      line-height: 1rem;
    }
  }
}

@include media-breakpoint-down(xl) {
  .member-id-card {
    .plan-year-block {
      margin-bottom: 0px;
      .plan-year-space {
        width: 30%;
      }
    }
    // .request-id-modal-btn {
    //   // margin-left: 1rem;
    // }
    .rural-heading {
      padding-left: 4.8rem;
    }
    .member-id-card-back {
      background-size: 100% 100%;
      min-height: 430px;
      width: 100%;
      margin: auto;
      font: normal normal 0.7rem/0.8rem 'Campton-medium';
      // font: normal normal 1.5vw/1.5vw 'Campton-medium';
    }
    .member-id-card-back-text {
      padding: 1rem 1rem 1.7rem 1rem;
    }
    .background-idcard {
      background-size: 100% 100%;
      padding: 8rem 2rem 1.5rem 0rem;
      margin: auto;
      font: normal normal 0.7rem/0.8rem 'Campton-medium';
      // font: normal normal 1.5vw/1.5vw 'Campton-medium';
      min-height: 430px;
      width: 100%;
    }
    .misc-headings-first-card-UT {
      justify-content: center;
      padding: 0px 0rem 0.4rem 0rem;
      margin-top: -57px;
      font: normal normal 0.6rem/0.7rem 'Campton-medium';
      // margin-right: -79px;
      margin-right: -9px;
    }
    .misc-headings-first-card-OH {
      justify-content: center;
      padding: 0px 0rem 0.4rem 0rem;
      font: normal normal 0.6rem/0.7rem 'Campton-medium';
      margin-top: -53px;
      margin-right: -30px;
    }
    .misc-headings-first-card-TX {
      justify-content: center;
      padding: 0px 0rem 0.4rem 0rem;
      font: normal normal 0.6rem/0.7rem 'Campton-medium';
      margin-top: -55px;
      margin-right: -22px;
    }
    #memberCurrentID {
      margin-left: 1rem;
    }
    .medicare-table {
      margin-bottom: 1.3rem;
    }
    .medicare-id-card-spacer {
      td {
        width: 52%;
      }
    }
    td {
      // width: 52%;
      width: 50%;
      padding-right: 5px;
    }
    .btn {
      width: 100%;
      white-space: inherit !important;
      height: auto;
    }
    // .print-options-modal {
    //   .id-print {
    //     // padding-right: 0;
    //   }
    // }
    .id-front-header {
      height: 5.1rem;
      position: relative;
    }
    .id-front-logo-header {
      position: absolute;
      // margin-top: -5%;
      left: 2px;
      width: 36%;
      min-width: 240px;
      max-width: 250px;
    }
    .id-front-logo-header-Passport {
      position: absolute;
      // margin-top: -5%;
      left: 2px;
      width: 36%;
      min-width: 240px;
      max-width: 250px;
      padding: 10px;
    }
    .id-front-logo-header-logo-Passport {
      position: absolute;
      // margin-top: -5%;
      left: 2px;
      width: 36%;
      min-width: 240px;
      max-width: 250px;
      padding: 23px 15px;
    }

    .cvs-caremark {
      width: 25%;
      position: absolute;
      left: 72%;
      bottom: 10px;
    }
    .p-l-20 {
      padding-left: 13%;
    }
    .p-l-20-ky {
      padding-left: 4%;
    }
    .mymolina-disclaimer-ky {
      color: #00a2b1;
      vertical-align: bottom;
    }
    .card-adj {
      padding: 0rem 15px 0px !important;
      .card {
        background-color: transparent;
        box-shadow: none;
        padding-bottom: 10px;
        height: 64px;
        margin-top: 0px;
        margin-bottom: 5px;
        position: relative;
      }
      .id-card-logo-hide {
        display: none;
      }
      .ca-header {
        position: absolute;
      }
      .california-dental {
        margin-top: 1%;
        width: 40%;
        margin-left: 0px;
        min-width: 130px;
        max-width: 140px;
      }
      .covered-california {
        margin-top: 1%;
        width: 30%;
        min-width: 120px;
        max-width: 130px;
      }
      .misc-headings {
        margin-top: 0%;
        font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
        position: absolute;
        left: 50%;
        top: 15%;
      }
      .misc-headings-left {
        // float: left;
        // padding: 0px 2px 0rem 0.5rem;
        // margin-top: 7px;
        position: absolute;
        top: 30%;
        left: 0.8rem;
        margin-top: 0;
        padding: 0;
        font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
      }
    }
    .wa-cascade-logo {
      height: 3.5rem;
      // margin-left: 37%;
      // margin-top: -15px;
      width: 15%;
      position: absolute;
      left: 80%;
      top: 14%;
      min-width: 100px;
      max-width: 105px;
    }
    .bg-front {
      // padding: 4rem 0.1rem 0rem 0.1rem !important;
      padding: 0rem 0.1rem 0rem 0.1rem !important;
      background-size: 100% 100%;
      width: 100%;
      margin: auto;
      min-height: 403px;
      position: relative;
    }
    .bg-back {
      background-size: 100% 100%;
      width: 100%;
      margin: auto;
      min-height: 403px;
      position: relative;
    }
    .card-content-id-back {
      margin: 1rem 0.8rem 1.5rem 0.8rem;
    }
    .id-card-back-disclaimer {
      margin: 1rem 0.1rem 0rem 0.1rem;
      font: normal normal 0.6rem/0.65rem 'Campton-medium';
      position: absolute;
    }
    .TXChipShift {
      margin-top: 1.5rem;
    }
  }
  .card-f-style label {
    // font: normal normal 0.5rem/0.6rem 'Campton-medium' !important;
    font: normal normal 0.6rem/0.65rem 'Campton-medium' !important;
  }
  // .p-l-20 {
  //   padding-left: 20px;
  // }
  .p-r-20 {
    padding-right: 20px;
  }
  .mymolina-disclaimer {
    color: #00a2b1;
    vertical-align: top;
  }
}
@include media-breakpoint-down(lg) {
  .member-id-card {
    .background-idcard {
      min-height: 400px;
      background-size: 100% 100%;
      padding: 9rem 0rem 1.5rem 0rem;
      font: normal normal 0.7rem/0.8rem 'Campton-medium';
      width: 100%;
      margin: auto;
    }
    #memberIdCardMS {
      .background-idcard {
        padding: 7rem 0rem 1.5rem 0rem;
        font: normal normal 0.8rem/0.9rem 'Campton-medium';
      }
    }
    #memberIdCardUT {
      td {
        font-size: 12px;
      }
      .plan-care-name {
        font-size: 12px;
      }
    }
    .background-idcard.member-id-medicare-ut {
      padding: 6.2rem 1rem 1.1rem 0;
    }
    .oh-line-height {
      line-height: 12px;
    }
    .background-id-mi {
      min-height: 380px;
    }
    .background-id-row {
      padding: 6.5rem 0rem 1.5rem 0rem;
    }
    .height-adjustment-pcp {
      height: 34px;
    }
    .rural-heading {
      padding-left: 6.3rem;
    }
    .wa-cascade-logo {
      // width: 16%;
      // height: 72px;
      // position: absolute;
      // left: 77%;
      // top: 3%;
      height: 4rem;
      left: 79%;
      position: absolute;
      top: 16%;
      width: 16%;
      max-width: 105px;
      min-width: 100px;

      // margin-top: -16px;
      // margin-left: 35%;
    }
    .misc-headings-first-card-UT {
      padding: 0px 21px 0.4rem 21px;
      font: normal normal 0.8rem/0.9rem 'Campton-medium';
      justify-content: center;
      margin-top: -38px;
      // margin-right: -33px;
      margin-right: -28px;
    }
    .misc-headings-first-card-OH {
      padding: 0px 21px 0.4rem 21px;
      font: normal normal 0.8rem/0.9rem 'Campton-medium';
      justify-content: center;
      margin-top: -43px;
      margin-right: -47px;
    }
    .misc-headings-first-card-TX {
      padding: 0px 21px 0.4rem 21px;
      font: normal normal 0.8rem/0.9rem 'Campton-medium';
      justify-content: center;
      margin-top: -43px;
      margin-right: -38px;
    }
    #memberCurrentID {
      margin-left: 1rem;
      margin-bottom: 1.3rem;
    }
    .id-front-header {
      height: 5.7rem;
      position: relative;
    }
    .id-front-logo-header {
      position: absolute;
      // margin-top: -5%;
      left: 2px;
      width: 40%;
      min-width: 200px;
      max-width: 240px;
    }
    .id-front-logo-header-Passport {
      position: absolute;
      // margin-top: -5%;
      left: 2px;
      width: 40%;
      min-width: 200px;
      max-width: 240px;
      padding: 10px;
    }
    .id-front-logo-header-logo-Passport {
      position: absolute;
      // margin-top: -5%;
      left: 2px;
      width: 40%;
      min-width: 200px;
      max-width: 240px;
      padding: 23px 15px;
    }

    .card-adj {
      padding: 0.5rem 15px 0px !important;
      .card {
        background-color: transparent;
        box-shadow: none;
        padding-bottom: 10px;
        height: 50px;
        margin-top: 0px;
        margin-bottom: 5px;
        position: relative;
      }
      .id-card-logo-hide {
        display: none;
      }
      .ca-header {
        position: absolute;
      }
      .california-dental {
        margin-top: 0%;
        width: 40%;
        margin-left: 0px;
        min-width: 100px;
        max-width: 130px;
      }
      .covered-california {
        margin-top: 0%;
        width: 30%;
        min-width: 100px;
        max-width: 120px;
      }
      .misc-headings {
        padding: 0px 0px 1.4rem 0px;
        margin-top: -1%;
        font: normal normal 0.8rem/0.9rem 'Campton-medium' !important;
        position: absolute;
      }
      .misc-headings-left {
        // float: left;
        padding: 0;
        margin-top: 0;
        position: absolute;
        left: 0.8rem;
        top: 0%;
        font: normal normal 0.8rem/0.9rem 'Campton-medium' !important;
      }
    }
    .card-padding-front {
      padding: 6.5rem 15px 0px !important;
    }
    // .request-id-modal-btn {
    //   // margin-left: 1rem;
    // }
    .member-id-card-back {
      min-height: 400px;
      background-size: 100% 100%;
      font: normal normal 0.8rem/0.9rem 'Campton-medium';
      width: 100%;
      margin: auto;
    }
    #memberCurrentID {
      margin-left: 1rem;
    }
    .member-id-card-back-text {
      padding: 1rem 1rem 1.7rem 1rem;
    }
    .medicare-table {
      table {
        width: 97%;
      }
    }

    .medicare-id-card-spacer {
      td {
        width: 51%;
      }
    }
    td {
      width: 50%;
      padding-right: 5px;
    }
    // .print-options-modal {
    //   .id-print {
    //     // padding-right: 0;
    //   }
    // }
    .bg-front {
      // padding: 6rem 0.1rem 0rem !important;
      padding: 0rem 0.1rem 0rem !important;
      background-size: 100% 100%;
      margin: auto;
      width: 100%;
      position: relative;
    }
    .bg-back {
      padding: 1rem 1rem 1.5rem 1rem !important;
      background-size: 100% 100%;
      margin: auto;
      width: 100%;
      position: relative;
    }
    .card-content-id-back {
      margin: 1rem 1rem 1.5rem 1rem;
    }
    .id-card-back-disclaimer {
      margin: 1rem 0.1rem 0rem 0.1rem;
      font: normal normal 0.8rem/0.9rem 'Campton-medium';
      position: absolute;
    }
    .plan-year-block {
      .plan-year-space {
        width: 32%;
      }
    }
  }
  .card-f-style label {
    // font: normal normal 0.5rem/0.6rem 'Campton-medium' !important;
    font: normal normal 0.8rem/0.9rem 'Campton-medium' !important;
  }
  .card-f-style .myMolinaIDCardText {
    font: normal normal 0.8rem/0.9rem 'Campton-medium' !important;
  }
  // .p-l-20 {
  //   padding-left: 20px;
  // }
  .p-r-20 {
    padding-right: 20px;
  }
  .mymolina-disclaimer {
    color: #00a2b1;
    vertical-align: top;
  }
  .p-l-20-ky {
    padding-left: 7%;
  }
  .mymolina-disclaimer-ky {
    color: #00a2b1;
    vertical-align: bottom;
  }
  .request-new-id-body {
    .form-check-label {
      line-height: 1.16rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .seperatorNVdata .member-id-card #frontidcardimageNV {
    padding: 86px 3px 20px 0px;
  }
  .table-font-size-back-il {
    font-size: 12px;
    line-height: 13px;
  }
  .member-id-card {
    .mbl-label {
      font: normal normal normal 18px/21px Arial;
    }
    #memberIdCardUT {
      td {
        font-size: 6px;
      }
      .plan-care-name {
        font-size: 6px;
      }
    }
    .mt-93-oh {
      margin-top: -44px;
    }
    .id-card-new {
      td {
        line-height: 13px;
        font-size: 8px;
      }
      .background-id-mi {
        font-size: 8px;
      }
      .line-height-mi {
        font: normal 8px/16px 'Campton-medium';
      }
      .address-height-mi {
        font: italic 8px/15px 'Campton-medium';
      }
      .mt70 {
        margin-top: 45px;
      }
      // .member-id-card-back {
      //   min-height: 400px;
      // }
      // .background-idcard{
      //   min-height: 400px;
      // }
      .background-id-row {
        tr {
          td:first-child {
            width: 50%;
          }
        }
        .medicare-id-card-spacer {
          td {
            width: 53%;
          }
        }
        #viewIdCardDualMI.margn-top-100 {
          margin-top: 85px;
        }
        .margn-top-100 {
          margin-top: 70px;
        }
        .mrgn-top {
          margin-top: 60px;
        }
        div {
          line-height: 13px;
          font-size: 8px;
        }
        .plan-name-id-card {
          color: #00a4b3;
          margin-left: 1rem;
          font-size: 8px;
        }
        .height-adjustment-pcp {
          height: 19px;
        }
      }
      .background-idcard {
        padding: 5.8rem 0.5rem 1.3rem 0;
      }
    }
    .btn {
      width: 100%;
      white-space: inherit !important;
      height: auto;
    }
    .medicare-table {
      width: 95%;
    }
    // table {
    //   width: 100% !important;
    // }
    .member-id-card-back {
      font: normal normal 0.4rem/0.5rem 'Campton-medium';
      background-size: 100% 100%;
      min-height: 205px;
      margin: auto;
      width: 100%;
    }
    // .request-id-modal-btn {
    //   // margin-left: 0rem;
    // }
    .member-id-card-back-text {
      padding: 0.5rem 0.5rem 1.7rem 1rem;
    }
    .member-id-card-back-text-TXChip {
      padding: 0.5rem 2rem 1.7rem 1rem;
    }
    #memberCurrentID {
      margin-left: 1rem;
      margin-bottom: 0px;
    }
    .background-idcard {
      font: normal normal 12px / normal 'Campton-medium';
      // font: normal normal 0.6rem/0.65rem 'Campton-medium';
      padding: 3.5rem 0.5rem 1rem 0;
      // margin-bottom: 10px;
      background-size: 100% 100%;
      min-height: 205px;
      width: 100%;
      margin: auto;
    }
    .il-font {
      font: normal normal 0.6rem/0.5rem 'Campton-medium';
    }
    
    #memberIdCardIL 
    {
      .il-font {
        tr {
          td {
            width: 66%;
          }
        }
      }
    }

    .font-id-value {
      font: normal normal 0.5rem/0.55rem 'campton-light' !important;
    }
    .member-id-card #frontidcardimageNV {
      background-size: 100% 100%;
      font: normal normal 12px / normal Campton-medium;
      margin: auto;
      min-height: 205px !important;
      padding: 6.1rem 0.5rem 1.3rem 0;
      width: 100%;
    }
    #memberCurrentID.azIdCardFont {
      font: normal normal 9px/12px Campton-medium;
    }
    .member-id-card-back-text .azIdCardFont {
      font: normal normal 8px/8px Campton-medium;
    }
    .member-id-card table.NVtable td {
      width: 75%;
      padding-right: 5px;
    }

    .background-id-mi {
      min-height: 300px;
    }
    .wa-cascade-logo {
      height: 2.5rem;
      // margin-left: 76%;
      // margin-top: -50px;
      width: 20%;
      max-height: 40px;
      position: absolute;
      left: 77%;
      top: 11%;
      max-width: 70px;
      min-width: 50px;
    }
    .p-l-20 {
      padding-left: 27%;
    }
    .p-l-20-ky {
      padding-left: 7%;
    }
    .mymolina-disclaimer-ky {
      color: #00a2b1;
      vertical-align: bottom;
    }

    #POPUPPrintConfirm .bg-back,
    #POPUPPrintConfirm .bg-front {
      min-height: 226px;
    }
    .background-idcard.member-id-medicare-ut {
      padding: 4.4rem 1rem 1.1rem 0;
    }
    .member-id-medicare-ut {
      td {
        width: 57%;
      }
    }
    .misc-headings-first-card-UT {
      justify-content: center;
      font: normal normal 0.3rem/0.4rem 'Campton-medium';
      padding: 0px 7px 0.5rem 7px;
      margin-top: -18px;
      // margin-right: -47px;
      margin-right: -13px;
    }
    .misc-headings-first-card-OH {
      font: normal normal 0.3rem/0.4rem 'Campton-medium';
      justify-content: center;
      padding: 0px 7px 0.5rem 7px;
      margin-top: -18px;
      margin-right: -25px;
    }
    .misc-headings-first-card-TX {
      font: normal normal 0.3rem/0.4rem 'Campton-medium';
      justify-content: center;
      padding: 0px 7px 0.5rem 7px;
      margin-top: -23px;
      margin-right: -21px;
    }
    td {
      padding-right: 5px;
    }
    .rural-heading {
      padding-left: 2.38rem;
    }
    .plan-year-block {
      padding-top: 0px;
      margin-bottom: 25px;
      > div {
        font: normal normal normal 18px/21px 'Campton-medium';
        select {
          font: normal normal normal 20px/20px 'Campton-medium';
          margin-left: $spacer;
          width: 100%;
        }
      }
      > div:nth-child(1) {
        width: 29%;
      }
      > div:nth-child(2) {
        width: 71%;
        display: inline-flex;
      }
    }
    .page-heading {
      margin-bottom: 23px;
    }
    .drp-year {
      margin-bottom: 25px;
    }
    .btn-popup {
      width: 100%;
      height: 54px;
    }
    .select-gap {
      .form-select {
        width: 100%;
        font: normal normal normal 18px/20px Arial;
        letter-spacing: 0.17px;
        color: #454545;
      }
    }
    .molina-select-wrapper {
      min-width: 220px;
    }
    .molina-select__control {
      font: normal normal normal 20px/20px Arial;
      letter-spacing: 0.19px;
    }
    .print-options-modal {
      .modal-footer .btn,
      .modal-footer .btn-primary {
        font: normal normal normal 16px/12px campton-medium;
      }
      .id-print {
        .modal-dialog {
          margin-left: 24px;
          margin-right: 23px;
        }
        .btn {
          white-space: nowrap;
        }
        .popup2 {
          .form-select {
            font: normal normal normal 16px/20px Arial;
          }
          .img-gap {
            margin-top: 8px;
          }
          .modal-footer {
            padding-top: 24px;
          }
        }
        .modal-title {
          display: none;
        }
        .modal-body {
          padding: 0 14px;
          .form-check {
            margin-top: 16px;
          }
          .id-details {
            font: normal normal bold 0.72rem/0.84rem Arial;
            margin-top: 28px;
          }
        }
        .modal-header {
          padding: 24px 14px;
          .modal-mbl-title {
            display: block;
            margin-bottom: 0;
            font: normal normal normal 20px/24px 'campton-medium';
          }
        }
        .modal-footer {
          padding: 30px 14px 24px 70px;
        }
      }
    }
    .text-align-center {
      padding: unset;
      width: auto;
      margin-top: 20px;
      p {
        font: normal normal normal 18px/22px 'campton-light';
        margin-bottom: 11px;
      }
      .id-back {
        margin-top: 30px;
      }
    }
    .select-gap {
      margin-bottom: 26px;
    }
    .card-f-style label {
      font: normal normal 0.4rem/0.44rem 'Campton-medium' !important;
    }
    .card-f-style .myMolinaIDCardText {
      font: normal normal 0.4rem/0.44rem 'Campton-medium' !important;
    }
    // .p-l-20 {
    //   padding-left: 10px;
    // }
    .p-l-20-ky {
      padding-left: 7%;
    }
    .mymolina-disclaimer-ky {
      color: #00a2b1;
      vertical-align: bottom;
    }
    .p-r-20 {
      padding-right: 10px;
    }
    .mymolina-disclaimer {
      color: #00a2b1;
      vertical-align: top;
    }
    .id-front-header {
      height: 3.9rem;
      position: relative;
    }
    .id-front-logo-header {
      left: 2px;
      // max-width: 182px;
      position: absolute;
      width: 39%;
      bottom: 14%;
      min-width: 135px;
      max-width: 140px;
      top: 0%;
    }
    .id-front-logo-header-Passport {
      left: 2px;
      // max-width: 182px;
      position: absolute;
      width: 39%;
      bottom: 14%;
      min-width: 135px;
      max-width: 140px;
      top: 0%;
      padding: 7px;
    }
    .id-front-logo-header-logo-Passport {
      left: 2px;
      // max-width: 182px;
      position: absolute;
      width: 39%;
      bottom: 14%;
      min-width: 135px;
      max-width: 140px;
      top: 0%;
      padding: 13px 15px;
    }
    .cvs-caremark {
      width: 30%;
      position: absolute;
      bottom: 5px;
      left: 64%;
    }
    .card-adj {
      // padding: 4.5rem 0px 0px 0px;
      padding: 0.5rem 0.5px 0.5px 0.5px !important;
      margin-bottom: 10px;
      .card {
        background-color: transparent;
        box-shadow: none;
        padding-bottom: 10px;
        height: 25px;
        margin-top: -3%;
        margin-bottom: 2%;
        position: relative;
      }
      .id-card-logo-hide {
        display: none;
      }
      .ca-header {
        position: absolute;
      }
      .california-dental {
        margin-left: 0px;
        max-width: 70px;
        min-width: 60px;
      }
      .covered-california {
        max-width: 70px;
        min-width: 50px;
      }
      .misc-headings {
        padding: 0px 0px 1rem 0px;
        // margin-top: 7px;
        margin-top: -1%;
        font: normal normal 0.4rem/0.44rem 'Campton-medium' !important;
        position: absolute;
      }
      .misc-headings-left {
        // margin-top: -35px;
        font: normal normal 0.4rem/0.44rem 'Campton-medium' !important;
        left: 1rem;
        position: absolute;
        margin-top: 0;
        padding: 0;
      }
    }
    .card-padding-front {
      padding: 6.5rem 15px 0px !important;
    }
    .bg-front {
      // padding: 1rem 1.5rem 1rem 1.5rem !important;
      padding: 0rem 0.5rem 0.5rem 0.5rem !important;
      background-size: 100% 100%;
      width: 100%;
      margin: auto;
      min-height: 205px;
      position: relative;
    }

    .bg-back {
      background-size: 100% 100%;
      width: 100%;
      margin: auto;
      min-height: 205px;
      padding: 1rem 0rem 1.5rem 0rem !important;
      position: relative;
    }
    .card-content-id-back {
      margin: 0.7rem 0.5rem 0.5rem 0.5rem;
    }
    .id-card-back-disclaimer {
      margin: 1rem 0.1rem 0rem 0.1rem;
      font: normal normal 0.4rem/0.44rem 'Campton-medium';
      position: absolute;
    }
  }
  .request-new-id-header {
    padding-left: 0.5rem;
  }
  .request-new-id-body {
    .form-check-label {
      line-height: 1.2rem;
    }
  }
}

.c-border-bottom {
  border-bottom: 1px solid grey;
}

.border-right {
  border-right: 1px solid grey;
}
.card-f-top {
  padding-bottom: 5px;
  margin-bottom: 5px;
  .m-l {
    margin-left: 0.4rem;
  }
}
.text-align-right {
  text-align: right;
}
.line-height-zero {
  line-height: 0;
}
.m-b-20 {
  margin-top: 5px;
  margin-bottom: 20px;
}
.m-b-5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bg-front {
  // padding: 2rem 0.1rem 0rem 0.1rem !important;
  //background-image: url('../img/id/2022/card_front.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: auto;
  min-height: 500px;
  position: relative;
}
.bg-back {
  //background-image: url('../img/id/2022/card_back.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: auto;
  min-height: 500px;
  position: relative;
}

.card-content-id-back {
  margin: 1rem 1.1rem 1.5rem 1.1rem;
}
.id-card-back-disclaimer {
  margin: 1rem 0.1rem 0rem 0.1rem;
  font: normal normal 0.6rem/0.8rem 'Campton-medium';
  position: absolute;
}
.disclaimer {
  position: absolute;
  bottom: 10px;
}
.card-adj {
  padding-top: 5.5rem;
  margin-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
  .card {
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 10px;
    height: 40px;
    margin-top: 0px;
    margin-bottom: 5px;
    position: relative;
  }
  .id-card-logo-hide {
    display: none;
  }
  // padding-bottom: 40px;
  .misc-headings {
    justify-content: center;
    padding: 0px 2px 1rem 2px;
    font: normal normal 0.6rem/0.8rem 'Campton-medium';
    margin-top: 15px;
  }
  .misc-headings-left {
    float: left;
    padding: 0px 2px 0.4rem 1.7rem;
    font: normal normal 0.6rem/0.8rem 'Campton-medium';
    margin-top: -47px;
  }
}
.ca-notice,
.idcard-back-img-vision {
  height: 1rem;
  float: left;
}
.p-t-xxs {
  padding-top: 0.43rem;
}
.card-f-style label:nth-child(1) {
  margin-right: 3px;
}
.p-l-20 {
  padding-left: 32%;
}
.p-l-20-ky {
  padding-left: 7%;
}
.mymolina-disclaimer-ky {
  color: #00a2b1;
  vertical-align: bottom;
}
.p-r-20 {
  padding-right: 20px;
}
.mymolina-disclaimer {
  color: #00a2b1;
  vertical-align: top;
}
@include media-breakpoint-down(md) {
  .ca-notice {
    height: 1.5rem;
  }
}
#POPUPPrintConfirm {
  .bg-back,
  .bg-front {
    min-height: 414px;
  }
  .card-f-style label {
    font: normal normal 0.4rem/0.56rem Campton-medium;
  }
  // .p-l-20 {
  //   padding-left: 20px;
  // }
  .p-r-20 {
    padding-right: 20px;
  }
  .mymolina-disclaimer {
    color: #00a2b1;
    vertical-align: top;
  }
  .card-adj {
    padding: 4rem 25px 40px;
    .card {
      background-color: transparent;
      box-shadow: none;
      padding-bottom: 10px;
      height: 40px;
      margin-top: 0px;
      margin-bottom: 5px;
      position: relative;
    }
    .id-card-logo-hide {
      display: none;
    }
  }
  .card-adj .misc-headings {
    margin-top: -40px;
  }
}
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .member-id-card .table-bold-seperatorNV {
    float: right;
    margin-right: 15px;
  }
  .member-id-card #frontidcardimage {
    padding-top: 5.6rem;
  }
}
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .member-id-card #frontidcardimage {
    padding-top: 10.3rem;
  }
  .member-id-card .table-bold-seperatorNV {
    float: right;
    margin-right: 34px;
  }
  #memberIdCardSC {
    .table-margin-top-tablet {
      margin-top: 2rem;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .member-id-card {
    .mrgn-top {
      margin-top: 0;
      padding-left: 0;
    }
    .white-bg {
      background-color: #fff;
    }
    .id-card-new .background-id-row .mrgn-top {
      margin-top: 0;
    }
    .mob-width-40-oh {
      width: 40%;
      float: left;
    }
    .mob-width-60-oh {
      width: 60%;
      float: left;
    }
    .mob-width-64-oh {
      width: 64%;
      float: left;
    }
    .mob-width-35-oh {
      width: 35%;
      float: left;
    }
    .mob-width-33-oh {
      width: 33%;
      float: left;
    }
    .gainwell-icon-adjust {
      font-size: 7px;
      margin-top: -26px;
      padding-left: 35px;
    }
  }
}

.member-id-card .ny-chp {
  font: normal normal 0.65rem / normal 'Campton-medium';
  padding: 5.5rem 1rem 1.1rem 0;
}
#memberIdCardNY .ny-chp tr td {
  padding-bottom: 5px;
}
.member-id-card .ny-chp-back {
  font: normal normal 11px / normal 'Campton-medium';
}
@include media-breakpoint-down(md) {
  .member-id-card .ny-chp {
    font: normal normal 9px / normal Campton-medium;
    padding: 3.5rem 1rem 1.1rem 0;
    width: 98%;
  }
  #memberIdCardNY .ny-chp tr td {
    padding-bottom: 0px;
  }
  .member-id-card .ny-chp-back {
    font: normal normal 6px / normal 'Campton-medium';
  }
}

.card-padding-front {
  padding: 5.5rem 5px 0px !important;
}
.card-padding-back {
  padding: 2.5rem 5px 0px !important;
}
.id-card-back-footer{
  position:relative
}

.row-space {
  padding-bottom: 5px !important;
}

.font-id-value {
  font: normal normal 0.65rem/0.75rem 'campton-light' !important;
}

.member-id-card-semibold {
  font-family: 'campton-light' !important;
}

.font-id-field {
  font-size: calc(100% - 1px);
}

.address-il {
  line-height: 1.2;
}

.idcard-back-img {
  height: 1.5rem
}
//NM  back Memberid card CSS -implementation
.member-id-card-nm {
  min-height: 88% !important;
}

.member-id-card-txt-NM
{
  margin-right: -69%;
  margin-left: 38%;
  margin-bottom: 11%;
}
