.accordion-button {
  &::after {
    // font-weight: $font-weight-bold;
    // font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free';
    // content: '\f078';
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    //@include transition($accordion-icon-transition);
  }
  &:not(.collapsed) {
    color: $accordion-button-active-color;

    &::after {
      background-image: escape-svg($accordion-button-active-icon);
      transform: none;
    }
  }
}
