.molina-loginheader-logo > span {
  border-left: 1px solid #707070;
  letter-spacing: 0px;
  color: #000000;
  font: normal normal 600 1.04rem/1.32rem 'campton-semibold';
  padding-left: 15px;
}
.font-weight-bold {
  font: normal normal bold 16px/16px Arial;
}
.molina-header1 {
  box-shadow: 0px 3px 6px #00000029;
}
.login-header-icon {
  width: 88px;
  height: 29px;
}
.molina-loginheader-logo > img {
  height: 36px;
  margin-right: 15px;
  margin-top: -5px;
  margin-left: 4.64rem;
}

.selected-lang {
  margin-right: 4.08rem;
}

@include media-breakpoint-down(md) {
  .molina-loginheader-logo > img {
    margin-right: 10px;
    height: 28px;
    margin-left: 45px;
  }
  .molina-loginheader-logo > span {
    padding-left: 10px;
    font-size: 15px;
  }
  .mbl-none {
    display: none;
  }
  .selected-lang {
    display: none;
  }
}
