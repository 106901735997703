/* toggle styles starts here */
.container-fluid {
  position: relative;
}
.toggleblock {
  height: 40px;
  position: relative;
  top: 10px;
}
.plantext {
  font-size: 16px;
  padding-right: 10px;
  position: relative;
  top: 15px;
}
.switch-button {
  background-color: #ebebeb;
  width: 208px;
  height: 45px;
  text-align: center;
  font-size: 16px;
  transform: translate3D(0%, 0%, 0);
  cursor: pointer;
  border-radius: 50px;
}
.switch-button-case {
  background: none;
  width: 46%;
  height: 100%;
  color: #454545;
  position: relative;
  left: 0%;
  border: none;
}
.switch-button .active {
  color: #454545;
  background-color: #6f495f;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  margin: 2%;
  height: 80%;
  padding: 0%;
  z-index: -1;
  border-radius: 50px;
}
.switch-button .active-case {
  color: #ebebeb;
}
/* toggle styles ends here */
@include media-breakpoint-down(md) {
  .toggleblock {
    height: 40px;
  }
  .switch-button {
    font-size: 11px;
    height: 30px;
    width: 140px;
  }
  .plantext {
    top: 10px;
  }
}
