// Your custom variables

/* .businesshourspopup {
  top: 10px;
  left: 324px;
  width: 706px;
  height: 494px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000040;
  opacity: 1;
  padding: 20px;
}
.businesspopupfont {
  text-align: left;
  font: normal normal bold 22px/30px Arial;
  letter-spacing: 0.26px;
  color: #000000;
  opacity: 1;
  padding-bottom: 32px;
}

.businesshoursweekfont {
  text-align: left;
  font: normal normal normal 22px/26px Arial;
  letter-spacing: 0px;
  color: #454545;
  opacity: 1;
}

.businesshoursfont {
  text-align: left;
  font: normal normal bold 22px/30px Arial;
  letter-spacing: 0.26px;
  color: #000000;
  opacity: 1;
}

.doctorinformationhead {
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001F;
  border-radius: 12px;
  opacity: 1;
  margin-left: 100px;
  width: 1450px;
  height: 364px;
  margin-bottom: 5px;
}

.doctorinformationpart1 {
padding-top: 30px;
padding-bottom: 5px;
padding-right: 30px;
padding-left: 50px;
}

.doctorheadmain {
  display: flex;
}

.doctorinformationpart2 {
padding-left:15px;
}
.doctorinformation {
  text-align: left;
  font: normal normal 600 1.2rem/1.52rem Campton;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
}
.doctorappontmentborder {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #999;
  border-radius: 5px;
  height: 44px;
  opacity: 1;
  padding-top: 11px;
  width: 344px;
  margin-left: 188px;
}

.docinformationdetail {
  height: 20px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  padding-bottom: 20px;
  padding-top: 3px;
  padding-left: 35px;
}

.doctorappontment {
  color: #009ea0;
  font: normal normal medium 18px/20px Campton;
  letter-spacing: .36px;
  opacity: 1;
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
}

.doctorbackheight {
  height: 406px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.docinforheading {
  text-align: left;
  font: normal normal medium 22px/28px Campton;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
  padding-top: 30px;
  padding-left: 35px;
  }
.clickchangedoctor {
    text-align: center;
    font: normal normal medium 18px/20px Campton;
    letter-spacing: 0.36px;
    color: #FFFFFF;
    background: #009EA0 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #0000003D;
    border-radius: 5px;
    opacity: 1;
    margin-left: 16px;
    width: 218px;
    height: 44px;
  }

.docbusinesshours {
  text-align: left;
  font: normal normal bold 12px/21px Arial;
  letter-spacing: 0px;
  color: #009EA0;
  opacity: 1;
  padding-top: 30px;
  padding-left: 35px;
} */

//graceperiod data
.graceperiod {
  background: var(--unnamed-color-ffdb62) 0% 0% no-repeat padding-box;
  background: #ffdb62 0% 0% no-repeat padding-box;
  padding: 4.5px 7px;
  border-radius: 5px;
  font: normal normal 600 22px/28px 'campton-semibold';
  color: #000;
}

.graceperiodinline {
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal 600 22px/28px Campton;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

//popupdata

.popupdatawidth {
  background-color: #fefefe;
  margin: auto;
  padding: 36px;
  border: 1px solid #888;
  width: 470px;
}

.footer-linespace {
  display: table;
  .footer-img-size {
    margin-right: 16px;
    margin-top: -7px;
    display: table-cell;
  }
  div {
    display: table-cell;
  }
}
.datafontalignbutton {
  margin-left: 105px;
  margin-top: 20px;
  width: 150px;
  height: 42px;
  background: var(--unnamed-color-009ea0) 0% 0% no-repeat padding-box;
  background: #009ea0 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  opacity: 1;
  box-sizing: content-box;
  border: none;
}

.onhover {
  cursor: pointer;
}
.popupbackgroundnone {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.close {
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  text-align: right;
}
.account-font {
  color: var(--unnamed-color-000000);
  font-size: 24px;
  padding-bottom: 19px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
}
.datafontalign {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 20px;
}

.datafontalignbutton .dataalign {
  text-align: center;
  font: normal normal 18px/43px Arial;
  letter-spacing: 0.36px;
  color: #ffffff;
  text-transform: capitalize;
  // padding-left: 25px;
  opacity: 1;
}

// footer css

.footer-wapper .footer-title {
  color: #000;
  letter-spacing: 0;
  opacity: 1;
  padding-bottom: 24px;
  text-align: left;
  font: normal normal normal 0.72rem/0.92rem Arial;
}

.footer-none {
  display: none;
}
.field-container {
  background-color: #e5f8f8;
  padding-top: 58px;
  position: none;
  bottom: 0;
  width: 100%;
}
.footer-wapper .link-width {
  padding-top: 2px;
  display: grid;
}
.footer-wapper {
  margin-top: 175px;
  .mb-4 {
    margin-bottom: 3.5rem !important;
  }
}

.footer-wapper .font-phno {
  padding-left: 36px;
  text-align: left;
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-bottom: 9px;
}
.footer-wapper .font-weekday {
  padding-left: 38px;
  padding-bottom: 9px;
  text-align: left;
  font: normal normal normal 0.64rem/0.8rem Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  // width: 290px;
}
.footer-wapper .font-weekday-sun > div > div {
  padding-top: 5px;
}
.footer-wapper .font-paragraph {
  text-align: left;
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  // width: 322px;
}

.footer-wapper .font-paragraph-footer {
  text-align: left;
  font: normal normal normal 0.72rem/.84rem Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  // width: 322px;
}

.footer-wapper .font-logo {
  text-align: left;
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 20px;
  opacity: 1;
  // width: 315px;
}
.footer-wapper .custom-field-wapper {
  width: 320px;
}
.footer-wapper .text-decorate {
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 2px;
  font: normal normal normal 0.64rem/0.96rem Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.footer-wapper .link-design {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: #000000;
  display: inline-block;
  padding-left: 6px;
}
.footer-wapper .datacolor {
  color: #000000;
}
.footer-wapper .footer-linespace {
  padding-bottom: 25px;
  text-align: left;
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.footer-wapper .footerlinespace1 {
  padding-bottom: 24px;
  font-family: 'campton-semibold';
  text-align: left;
  font: normal normal 600 0.72rem/0.92rem Campton;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  .custom-tooltip-text {
    visibility: hidden;
    width: 225px;
    white-space: pre-line;
    background-color: #e0e0e0;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 128%;
    left: 15%;
    box-shadow: 0px 3px 6px #00000040;
    text-align: initial;
    padding-bottom: 10%;
    padding-left: 10px;
    padding-right: 10px;
    &::after {
      content: '';
      position: absolute;
      bottom: 98%;
      left: 7%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent rgba(224, 224, 224) transparent;
    }
  }
  &:hover {
    .custom-tooltip-text {
      visibility: visible;
    }
  }
}
@include media-breakpoint-down(xxl) {
  // .footer-wapper .font-phno {
  //   font-size: 16px;
  // }
}

/* @include media-breakpoint-up(md) {
  .doctorbackheight {
    margin-bottom: 30px;
    margin-top: 20px;
}
.doctorappontmentborder {
  margin-bottom: 10px;
}

} */

@include media-breakpoint-down(md) {
  /* .doctorappontmentborder {
    margin-left: 5px;
    margin-top: 13px;
    margin-bottom: 26px;
}

  .doctorinformationhead {
    width: 307px;
    height: 847px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 1px #0000001F;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .docinforheadingmob {
    width: 229px;
    height: 32px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 32px;
    padding-top: 20px;
    padding-bottom: 50px;
    font-size: 23px;
   
}

.docinforheading {
  height: 28px;
  text-align: left;
  font: normal normal medium 22px/28px Campton;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 32px;
}

.docinformationdetail {
  height: 20px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  padding-left: 32px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.docbusinesshours {
  text-align: left;
  font: normal normal bold 12px/21px Arial;
  letter-spacing: 0px;
  color: #009EA0;
  opacity: 1;
  padding-left:32px;
}


.doctorappontment {
  height: 16px;
  text-align: center;
  letter-spacing: 0.28px;
  color: #009EA0;
  opacity: 1;
  font-size: 14px;
  padding-left: 16px;
}

.clickchangedoctor {
  margin-top: 20px;
  width: 181px;
  height: 44px;
  background: #009EA0 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  opacity: 1;
}


.businesshourspopup {
  left: 24px;
    width: 328px;
    height: 540px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #00000040;
    opacity: 1;
    padding: 12px;
    top: 10px;
}

.businesshoursweekfont {
  width: 64px;
  height: 20px;
  text-align: left;
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: #454545;
  opacity: 1;
}

.businesshoursfont {
  height: 44px;
  text-align: left;
  font: normal normal bold 15px/30px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
} */

  .text-decorate:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
  .accnt-setting:hover,
  .link-design:hover,
  .prnt-hlth-rec:hover,
  .imp-plan-doc:hover {
    transform: scale(1.05);
    transition: 0.3s;
    display: inline-block;
  }
  .footer-wapper {
    margin-top: 60px;
    padding-top: 20px;
    padding-right: 27px;
    padding-left: 27px;
    position: initial;
    bottom: 0;
  }

  .footer-wapper .custom-field-wapper {
    padding-bottom: 25px;
    max-width: 375px;
  }
  .footer-wapper .footer-width {
    justify-content: center;
  }
  .footer-wapper .footer-linespace {
    padding-bottom: 25px;
    text-align: left;
    font: normal normal normal 18px/21px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .footer-wapper .footerlinespace1 {
    padding-bottom: 25px;
    text-align: left;
    font: normal normal 600 0.72rem/0.92rem Campton;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .footer-wapper .font-phno {
    padding-left: 36px;
    text-align: left;
    font: normal normal normal 18px/21px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-bottom: 7px;
  }
  .footer-wapper .font-paragraph {
    text-align: left;
    font: normal normal normal 16px/21px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 286px;
  }

  .footer-wapper .font-paragraph-footer {
    text-align: left;
    font: normal normal normal 15px/18px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    // width: 322px;
  }

  .footer-wapper .font-weekday {
    padding-left: 38px;
    text-align: left;
    font: normal normal normal 16px/20px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-top: 6px;
  }
  .footer-wapper .text-decorate {
    text-align: left;
    text-decoration: underline;
    text-underline-offset: 2px;
    font: normal normal normal 16px/28px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .footer-wapper .font-logo {
    padding-top: 22px;
    text-align: left;
    font: normal normal normal 0.72rem/0.84rem Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  // popup styles

  .datafontalign {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    opacity: 1;
    line-height: 20px;
  }

  .popupdatawidth {
    background-color: #fefefe;
    margin: auto;
    padding: 22px;
    border: 1px solid #888;
    width: 320px;
  }
  .datafontalignbutton {
    margin-left: 60px;
  }
  .graceperiod {
    font: normal normal 600 18px/28px 'campton-semibold';
  }
}

.flex-parent {
  display: flex !important;
  flex-wrap: wrap !important;

  .flex-child {
    // box-sizing: border-box;
    // flex: 19%;
    width: 25%;
    word-break: break-word;
    padding: 0 15px;
    margin-bottom: 3.4rem;
  }
}

@include media-breakpoint-down(xl) {
  .flex-parent {
    .flex-child {
      width: 50%;
    }
  }
}

@include media-breakpoint-down(md) {
  .flex-parent {
    .flex-child {
      width: 100%;
      margin-bottom: 3.5rem !important;
    }
  }
}
// Nm Implementation
.NM-text-decoration
{
  text-decoration: underline !important;
}