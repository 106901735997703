.header-alerts-carousel {
  padding-top: 20px;
}

.alert-wrapper {
  .arrow-size-xl {
    height: 50px !important;

    @include media-breakpoint-down(xxl) {
      height: 40px !important;
    }
    @include media-breakpoint-down(lg) {
      height: 35px !important;
    }
    @include media-breakpoint-down(md) {
      height: 35px !important;
    }
  }
  .carousel-btn-alert-next {
    position: absolute;
    top: 28%;
    margin: auto;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      top: 37%;
    }
    @include media-breakpoint-down(md) {
      top: auto !important;
    }
  }
  .carousel-btn-alert-prev {
    position: absolute;
    top: 30%;
    margin: auto;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      top: 37%;
    }
    @include media-breakpoint-down(md) {
      top: auto !important;
      left: 1%;
    }
  }

  // .alert-light {
  //   padding-left: 13px;
  //   margin-top: 18px;
  //   margin-left: 5px;
  //   margin-right: 18px;
  // }

  // @include media-breakpoint-up(xl) {
  //   .alert-light {
  //     margin-left: 15px;
  //   }
  // }

  .alert {
    // padding: 0rem 0rem;
    // padding-left: 10px;
    box-shadow: 0px 3px 1px #0000001f;
    border: 1px solid #d3d3d3;
  }
  .card-link {
    font: normal normal 0.72rem/0.92rem 'campton-book';
    letter-spacing: 0px;
    color: #575757;
    padding-left: 8px;
  }
  .card-link-read-more {
    letter-spacing: 0px;
    // padding-left: 8px;
    color: #009ea0;
    cursor: pointer;
    padding-left: 5px;
  }
  .card-link-read-more:hover {
    color: #007e80;
  }
  .renew-title {
    // width: 91%;
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal 0.72rem/0.92rem 'campton-medium';
    letter-spacing: 0.72px;
    color: #000000;
    padding-top: 27px;
    padding-left: 8px;
    padding-right: 8px;
    word-break: break-word;
    @include media-breakpoint-down(xxl) {
      padding-top: 20px;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .renew-discription {
    color: #009ea0;
    text-align: left;
    font: normal normal 0.72rem/0.92rem 'campton-medium';
    padding-right: 13px;
  }
  .alert-continued-title {
    color: #000000;
    text-align: left;
    font: normal normal 0.72rem/0.92rem 'campton-medium';
    padding-left: 5px;
    padding-right: 13px;
  }
  .card-link-learn-more {
    font: normal normal 0.72rem/1rem 'campton-book';
    letter-spacing: 0px;
    color: #575757;
    // padding-left: 13px;
    display: inline-block;
  }
  .red-rect {
    width: 8px;
    height: inherit;
    background: var(--unnamed-color-f25f42) 0% 0% no-repeat padding-box;
    background: #f25f42 0% 0% no-repeat padding-box;
  }
  .blue-rect {
    width: 8px;
    height: inherit;
    background: var(--unnamed-color-009ea0) 0% 0% no-repeat padding-box;
    background: #009ea0 0% 0% no-repeat padding-box;
  }

  .renew-icon {
    width: 27px;
    height: 27px;
  }
  .renew-wrapper {
    display: flex;
  }
  .icon-wrapper {
    // padding-left: 25px;
    padding-top: 18px;
  }
  .alert-title {
    padding-right: 12px;
    text-transform: uppercase;
  }
  .alert-title-noCaps {
    padding-right: 12px;
  }
  .alert-text-zoom:hover {
    display: inline-block;
    text-decoration: underline;
    transition: 0.3s;
    transform: scale(1.05);
    @include media-breakpoint-down(md) {
      display: inline;
    }
  }
  .btn-alert-modal {
    background: $white escape-svg($molina-close-btn-xl-svg) 0px 0px no-repeat padding-box;
    height: 24px;
    width: 25px;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;

    @include media-breakpoint-down(xl) {
      right: 10px;
      top: 10px;
    }
    @include media-breakpoint-down(md) {
      right: 9px;
      top: 10px;
    }
  }
  .btn-alert-modal span {
    display: none;
  }
}

.inline-status {
  padding: 4.5px 7px;
  background: $green;
  border-radius: 5px;
  font: normal normal 600 0.88rem/1.12rem 'campton-semibold';
  color: $black;
}

.inline-status-grace-prd-ylw {
  background: $yellow;
  padding: 4.5px 7px;
  border-radius: 5px;
  font: normal normal 600 0.88rem/1.12rem 'campton-semibold';
  color: #000000;
  margin-left: 5px;
}

.inline-status-terminated {
  background: $red;
  padding: 4.5px 7px;
  border-radius: 5px;
  font: normal normal 600 0.88rem/1.12rem 'campton-semibold';
  color: #000000;
  margin-left: 5px;
}

.inline-status-pending {
  background: #d8d8d8;
  padding: 4.5px 7px;
  border-radius: 5px;
  font: normal normal 600 0.88rem/1.12rem 'campton-semibold';
  color: #000000;
  margin-left: 5px;
}
.home-title a:hover {
  color: $gray-1;
}
.home-title {
  font: normal normal normal 0.88rem/1.12rem 'campton-medium';
  color: $gray-1;
  position: relative;

  .inner-spinner {
    margin-left: 0;
  }

  .card-link {
    font: normal normal normal 0.88rem/1.12rem 'campton-book';
    color: $gray-1;
  }
}

/* .link-icon {
  padding: 0px 0px 2px 5px;
} */

.link-icon2 {
  height: 26px;
  width: 25px;
}

.track-more-details .link-icon2 {
  margin-bottom: 3px;
  margin-left: 3px;
}
.home-page {
  .cards-list,
  .trackmyspending {
    margin-bottom: $spacer * 5.938;
    .card {
      border-radius: 10px;
    }
    // .lg-card .card-body {
    //   padding: 39px 32px 25px 32px;
    // }
  }
  .learnmoreonclick {
    margin-left: 5px;

    .img-link-icon {
      margin-bottom: 2px;
      padding-left: 6px;
    }
  }
  .molina-card {
    border-radius: 10px;
  }
  // .coverage-width {
  //   width: 107px;
  // }
  .btn-pay-on {
    padding-bottom: 5px;
  }
  .btn-autopay {
    margin-top: 26px;
  }
  .bill-subtitle {
    padding-top: 5px;
  }
  .mask {
    cursor: pointer;
  }
}
.lang-info-container {
  width: 100%;
  height: 200px;
  background: #e0e0e0 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 175px;
  margin-bottom: -175px;

  .card-link {
    font: normal normal 0.72rem/0.92rem 'campton-book';
    letter-spacing: 0px;
    color: #000000;
    padding-left: 8px;
  }

  .lang-link-wrapper {
    // margin-left: 18px;
    padding-top: 25px;
  }

  .lang-info-heading {
    font: normal normal 25px/30px 'campton-medium';
    padding-top: 26px;
    // margin-left: 50px;
  }
  .lang-info-text {
    font: normal normal 18px/21px 'campton-medium';
    padding-top: 15px;
    letter-spacing: -0.16px;
    color: #000000;
    opacity: 1;
    // margin-left: 50px;
  }
  .img-link {
    padding-right: 8px;
  }
}

.account-font {
  .text-padding {
    padding-left: 3px;
  }
  .text-alignment {
    padding-right: 3px;
  }
  .card-link {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    text-decoration: underline;
  }
  .phoneState {
    border: 1px solid black;
    font-size: 12px;
    margin: 10px;
    text-align: center;
    padding: 5px;
  }
  .phoneStateHeading {
    border: 1px solid black;
    font-size: 12px;
    margin: 10px;
    text-align: center;
    padding: 5px;
  }
}

.card-details-block,
.card-details-block2 {
  margin-bottom: $spacer * 2.063;
}

.card-desc-label {
  font: normal normal normal 0.77rem/1.12rem 'campton-medium';
  letter-spacing: 0px;
}

.card-desc-value {
  font: normal normal normal 0.8rem/0.92rem Arial;
  letter-spacing: 0.2px;
  color: $text-color-muted;
}

.card-bill-label {
  font: normal normal normal 0.88rem/1.04rem 'campton-medium';
  letter-spacing: 0px;
}
.card-bill-label2 {
  font: normal normal normal 0.88rem/1.12rem 'campton-medium';
  letter-spacing: 0px;
  color: $black;
}

.card-bill-label3 {
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: $black;
  border-left: 1px solid $black;
  padding-left: 14px;
  margin-left: 10px;
}
.card-bill-box {
  padding-top: 26px;
}
.card-bill-txt {
  padding-top: 6px;
}
.card-bill-value {
  font: normal normal normal 0.88rem/1.04rem Arial;
  letter-spacing: 0px;
  color: $text-color-muted;
}

.card-bill-value2 {
  font: normal normal normal 0.72rem/0.84rem Arial;
  letter-spacing: 0px;
  color: $text-color-muted-light;
}

.card-bill-link {
  font: normal normal normal 0.88rem/1.12rem 'campton-book';
  letter-spacing: 0px;
  color: $text-color-muted !important;
}

.card-bill-msg {
  font: normal normal normal 0.72rem/0.92rem 'campton-medium';
  letter-spacing: 0.9px;
  color: $black;
  text-transform: uppercase;
  opacity: 0.45;
  padding-top: 33px;
  text-align: center;
}

.card-msg-subject {
  font: normal normal normal 0.88rem/1.04rem Arial;
  position: relative;
  display: inline-block;
  width: 100%;
  .img-molina-teal,
  .img-molina-mail,
  .img-molina-mail-read {
    float: left;
  }

  div:nth-child(2) {
    vertical-align: middle;
    max-width: 70%;
    display: block;
    display: -webkit-box;
    margin: 0 auto;
    float: left;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.card-msg-body {
  font: normal normal normal 0.72rem/1.2rem Arial;
  letter-spacing: 0.32px;
  color: $color-292929;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin-left: 42.5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
}
/* .messages-row .link-wrapper{
  padding-top:6px;
} */
.link-wrapper .link-icon2 {
  height: 12px;
  margin-left: 4px;
  width: 10px;
  color: #575757 !important;
}
.molina-card .card-link-cta-block-2col {
  position: absolute;
  bottom: 0.75rem;
  left: auto;
  right: auto;
  width: 90%;
  //text-align: -webkit-center;
  .molina-card-link {
    text-align: center;
  }
  .molina-card-link:first-child {
    padding-right: 12px;
    margin-bottom: 0.25rem;
  }
  .card-link {
    display: inline-block;
  }
  .card-width {
    width: 127%;
    display: table-row;
  }
  // .link-icon2 {
  //   vertical-align: bottom;
  // }
}

.paperless-billing {
  padding-top: $spacer * 0.7;
  padding-bottom: $spacer;
}

.paperless-billing-icon {
  height: 0.96rem;
  margin-right: 5px;
  margin-bottom: 8px;
}
// .messages-row {
//   margin-bottom: $spacer * 3;
// }
.messages-row .row:first-child {
  margin-top: 0rem;
  margin-bottom: 0.3rem;
}

.messages-row .row {
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
}

.messages-row hr {
  width: 94%;
  margin: $spacer * 1.188 0 $spacer * 1.188 auto;
}

.messages-row .messages-dt {
  float: right;
  font: normal normal normal 0.88rem/1.04rem Arial;
  letter-spacing: 0px;
  color: $text-color-muted;
}

.messages-row .card-link {
  margin-left: 1.7rem;
  margin-bottom: 0rem;
  font: normal normal normal 0.72rem/1.04rem 'campton-book';
  color: $text-color-muted;
  padding-top: 5px;
}

.messages-title {
  display: inline-flex;
}

// .messages-title > div {
// }

.messages-count {
  color: $white;
  background: $count-circle-bg-color 0% 0% no-repeat padding-box;
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 50%;
  padding-top: 10px;
  font: normal normal bold 0.72rem/0.52rem Arial;
  height: 1.24rem;
  width: 1.24rem;
}

.track-msg {
  font: normal normal normal 0.72rem/0.92rem 'campton-book';
  padding-top: 7px;
}

.carousel-item-block .card {
  display: table;
  padding: 10px 15px;
  width: 100%;
  line-height: 1.12rem;
}

.carousel-item-block img {
  float: right;
}

.carousel-item-block img[alt*='Accordant Care'] {
  width: 42px;
}

.carousel-item-block .carousel-icon {
  height: 1.6rem;
}

.carousel-item-block .card .carousel-icon-sm {
  height: 35px;
}

.carousel-item-block .card div {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.carousel-item-block .card div:nth-child(2) {
  padding-left: 10px;
  font-family: 'campton-semibold';
  font-size: $font-size-base;
  color: $black;
  text-align: left;
  div {
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 205px;
    display: block;
    display: -webkit-box;
  }
}

.links {
  padding-bottom: 20px;
}
.slick-slider {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.slick-slide img {
  display: initial !important;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  display: none !important;
}

.carousel-item-block {
  padding: 15px !important;
}
.col-md-12.col-sm-12.pt-2:hover,
.card-link-cta-block .card-link:hover,
.card-link-cta-block-2col .card-link:hover {
  transform: scale(1.15);
  transition: 0.3s;
}

.carousel-item-block:hover {
  transform: scale(1.15);
  transition: 0.3s;
  .card div:nth-child(2) > div {
    display: block;
    overflow: unset;
    text-overflow: unset;
  }
}

@include media-breakpoint-down(md) {
  .carousel-item-block:hover {
    transform: none;
    .card div:nth-child(2) > div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
    }
  }
  .alert-wrapper {
    .carousel-btn-alert-next {
      position: relative;
      top: 27%;
      margin: auto;
      cursor: pointer;
      // padding-left: 20px;
    }
  }
}

.carousel-btns {
  float: right;
}
.carousel-btns > a {
  cursor: pointer;
}
.carousel-btns > a img {
  height: 1.6rem;
}

.slick-dots li.slick-active button:before {
  color: $teal !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: $teal !important;
  @include media-breakpoint-down(xxl) {
    font-size: 7px !important;
  }
}

@include media-breakpoint-up(xxl) {
  .home-page {
    .home-title {
      margin-top: $spacer * 3.75;
      margin-bottom: $spacer * 3.75;
      position: relative;
    }
    .btn-pay-on {
      display: flex;
    }
    .paperless-billing .card-bill-label3 {
      display: flex;
    }
    .icon-data {
      word-break: break-all;
    }
    .track-more-details {
      margin-left: 19px;
    }
    .card-bill-link .link-icon {
      padding-left: 3px;
    }

    .cards-list {
      > div:nth-child(1) {
        padding-right: 34.5px;
      }

      > div:nth-child(2) {
        padding-right: 34.5px;
        padding-left: 34.5px;
      }

      > div:nth-child(3) {
        padding-left: 34.5px;
      }
    }

    .cards-list,
    .trackmyspending {
      margin-bottom: $spacer * 5.938;
    }
    .paperless-billing {
      margin-top: 20px;
    }
    /*   .card-bill-link {
      font: normal normal 0.72rem/0.84rem Arial;
    } */
  }
  // .home-helpful-links {
  //   margin-bottom: $spacer * 5.938;
  // }

  .card-bill-label3 {
    margin-bottom: -6px;
    .link-icon {
      padding-left: 4px;
      padding-bottom: 2px;
    }
  }
  .carousel-item-block .card {
    width: 280px;
    height: 94px;
    border-radius: 10px;
  }
  .carousel-item-block .carousel-icon {
    height: 42px;
    margin-top: 12px;
  }
  .carousel-item-block .card div:nth-child(2) {
    font-size: 0.96rem;
    line-height: 1.12rem;
  }
  .alert-wrapper {
    .link-icon {
      padding-left: 6px;
      margin-bottom: 1px;
      height: 12px;
    }

    .carousel-btn-alert-next img,
    .carousel-btn-alert-prev img {
      height: 34px;
    }
  }
  .alert-wrapper .card-link {
    padding-left: 10px;
  }
  .alert-wrapper .icon-wrapper {
    padding-top: 17px;
  }
}
@include media-breakpoint-down(xxl) {
  .messages-row .card-link {
    margin-left: 1.84rem !important;
  }
  .blue-rect {
    margin-left: -12px;
  }
  .red-rect {
    margin-left: -12px;
  }
  .alert-wrapper .link-icon {
    padding-left: 6px;
    margin-bottom: 0px;
    height: 8px;
  }
  .link-icon2 {
    height: 24px;
    width: 24px;
  }
  .carousel-item-block .card {
    height: 80px;
    min-width: 230px;
    width: 100%;
    border-radius: 10px;
  }
  .carousel-item-block .carousel-icon {
    margin-top: 8px;
  }
  .carousel-item-block .card div:nth-child(2) > div {
    max-width: 165px;
  }
  .home-page {
    .home-title {
      margin-top: $spacer * 1.875;
      margin-bottom: $spacer * 1.875;
    }
    .cards-list {
      margin-bottom: $spacer * 1.875;
    }
    .cards-list > div {
      margin-bottom: $spacer * 1.875;
    }

    .track-more-details .link-icon2 {
      height: 20px;
      width: 20px;
    }
    .paperless-billing .card-bill-label2 {
      display: block;
      padding-top: 18px;
    }
    .paperless-billing .card-bill-label3 {
      border-left: none;
      margin-left: -11px;
    }
    .paperless-billing.paperless-billing-icon {
      height: 21px;
      margin-right: 2px;
    }
    .paperless-billing .icon-data {
      margin-bottom: 15px;
    }
  }
  // .home-helpful-links {
  //   margin-bottom: $spacer * 3.75;
  // }
  .card-msg-subject {
    //font: normal normal normal 20px/23px Arial;

    div:nth-child(2) {
      max-width: 58%;
    }
  }
  .messages-count {
    line-height: 0.2rem;
    margin-top: 5px;
  }
  .messages-row .messages-dt {
    font: normal normal normal 0.72rem/0.84rem Arial;
  }
  .molina-card .card-link-cta-block-2col {
    width: 87%;
    .molina-card-link:first-child {
      margin-bottom: 0.25rem;
      padding-right: 8px;
    }
  }

  // .card-bill-msg {
  //   width: 338px;
  // }
  .card-bill-label3 {
    padding-top: 5px;
  }
}

@include media-breakpoint-down(xl) {
  .card-msg-subject {
    div:nth-child(2) {
      max-width: 50%;
    }
  }
  .carousel-item-block .card {
    min-width: 205px;
    width: 100%;
    height: 76px;
  }
  .card-bill-label3 .card-bill-link {
    margin-top: 0px;
  }
  .molina-card .card-bill-link {
    display: inline !important;
  }
  .carousel-item-block .carousel-icon {
    height: 35px;
    margin-top: 8px;
  }
  .carousel-item-block .card div:nth-child(2) {
    width: 80%;
    div {
      max-width: 100%;
    }
  }
  .card-bill-link {
    word-break: break-all;
    display: inline;
  }
  .btn-pay-on .card-bill-label3 {
    border-left: none;
    margin-left: -11px;
  }
  .alert-wrapper .link-icon {
    padding-left: 6px;
    margin-bottom: 0px;
  }
}
@include media-breakpoint-down(lg) {
  .p-t-9 {
    padding-top: 13px;
  }
  .home-page {
    .trackmyspending {
      margin-top: $spacer;
    }
    .molina-card {
      min-height: 280px;
    }
    .custom-tooltip {
      link-icon2 {
        height: 22px;
        margin-bottom: 3px;
      }
    }

    .card-details-width {
      width: 140px;
    }

    .cards-list {
      > div:nth-child(1),
      > div:nth-child(2),
      > div:nth-child(3) {
        margin-bottom: $spacer * 1.875;
      }
    }
  }
  .card-msg-subject {
    div:nth-child(2) {
      max-width: 75%;
    }
  }

  .card-msg-body {
    display: none;
  }

  // .molina-card .card-link-cta-block-2col {
  //   bottom: -14px !important;
  // }

  .card-link-cta-block-2col .molina-card-link {
    width: 100% !important;
  }

  .card-link-cta-block-2col .card-width {
    width: 100% !important;
    display: block;
    text-align: center;
  }

  .mobile-space {
    margin-left: 0px;
  }

  // .mobile-bottom {
  //   margin-bottom: 20px !important;
  // }
  .alert-wrapper .link-icon {
    padding-left: 6px;
    margin-bottom: 2px;
  }
  .alert-wrapper .red-rect {
    margin-right: 9px;
  }

  .messages-row .card-link {
    margin-left: 2.1rem !important;
  }

  .carousel-item-block {
    width: 50% !important;
    display: inline-block;
    vertical-align: top;
  }
  .carousel-item-block .card {
    min-width: 250px;
    width: 100%;
    div:nth-child(2) div {
      max-width: 175px;
    }
    &:hover div:nth-child(2) div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
    }
  }
  .lang-info-container {
    height: 240px;
  }
}

@include media-breakpoint-down(md) {
  .card-bill-label {
    font: normal normal normal 22px/26px 'campton-medium';
  }
  .card-bill-value {
    font: normal normal normal 22px/26px Arial;
    letter-spacing: 0px;
  }
  .card-desc-label {
    font: normal normal normal 17px/28px 'campton-medium';
    letter-spacing: 0px;
  }
  .card-desc-value {
    font: normal normal normal 18px/21px Arial;
    letter-spacing: 0.18px;
  }

  .molina-card {
    min-height: 300px;
  }
  .molina-card-link .link-change-doc {
    margin-bottom: 5px !important;
  }
  .molina-card .card-body {
    padding: 3.06rem 2rem 1.56rem;
  }
  .home-title {
    font-size: 22px;
    line-height: 28px;
  }
  .home-title > a {
    display: inline-block;
    margin-top: $spacer * 1;
    font-size: 22px;
    line-height: 28px;
  }

  .card-details-block {
    margin-bottom: $spacer * 0.5;
  }

  .paperless-billing-icon {
    height: 24px;
    margin-right: 5px;
    margin-bottom: 6px;
  }

  .bill-subtitle .card-bill-value {
    width: 70px;
    margin-left: 2px;
    margin-top: 2px;
  }
  .paperless-billing .icon-data {
    word-break: break-all;
  }
  .card-details-block2 {
    margin-bottom: 0;
    padding-bottom: 12px;
  }
  .card-bill-box {
    padding-top: 25px;
  }
  .card-bill-txt {
    padding-top: 5px;
  }
  .molina-card .my-bill-cta-block {
    // bottom: 5px;
    left: 0;
    position: absolute;
    right: 0;
  }
  .card-wrapper {
    padding-bottom: 58px;
  }
  .link-wrapper .link-icon2 {
    margin-bottom: 0px !important;
  }

  .card-desc-label {
    padding-bottom: 5px;
  }
  .card-desc-value {
    padding-bottom: 12px;
  }
  .messages-row {
    margin-bottom: $spacer * 2;
    .card-msg-subject {
      div:nth-child(2) {
        max-width: 75%;
        font: normal normal normal 20px/23px Arial;
      }
    }
    .card-link {
      font-size: 18px;
      line-height: 23px;

      margin-left: 42.5px !important;
    }
  }

  .messages-count {
    height: 31px;
    width: 31px;
    font-size: 18px;
    line-height: 13px;
  }
  .messages-row .messages-dt {
    float: left;
    display: block;
    margin-left: 43px;
    margin-bottom: 0.5rem;
    width: 75%;
    font-size: 18px;
    line-height: 21px;
  }

  .track-msg {
    font: normal normal normal 14px/17px Arial;
    color: $list-desc-details;
    padding-top: 12px;
    opacity: 0.8;
    width: 100%;
    text-align: initial;
  }

  .home-helpful-links {
    .card-title {
      margin-bottom: 0rem;
    }
    .links {
      margin-left: -4px;
    }
  }

  .carousel-item-block .card > div,
  .carousel-item-block .card div:nth-child(2) {
    display: table-row !important;
    text-align: center !important;
    height: 39px;
    div {
      max-width: 100%;
      width: 100%;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .carousel-item-block .card {
    min-height: 88px;
    min-width: 139px;
    width: 100%;
    height: 124px;
    border-radius: 10px;
  }
  .carousel-item-block .carousel-icon {
    height: 37px;
    margin-top: 15px;
  }

  .carousel-btns > a img {
    height: 29px;
  }

  .carousel-item-block img {
    float: none;
    margin: auto;
    width: 100%;
  }

  .carousel-item-block .carousel-icon {
    height: 34px;
  }

  .carousel-item-block .carousel-icon-sm {
    height: 29px;
  }

  .slick-dots li button:before {
    font-size: 7px !important;
  }
  .inline-status {
    white-space: nowrap;
    font: normal normal 600 18px/23px 'campton-semibold';
  }
  .inline-status-grace-prd-ylw {
    font: normal normal 600 18px/23px 'campton-semibold';
  }

  .inline-status-terminated {
    font: normal normal 600 18px/23px 'campton-semibold';
  }

  .inline-status-pending {
    font: normal normal 600 18px/23px 'campton-semibold';
  }
  .alert-wrapper {
    .red-rect {
      width: 9px;
      height: inherit;
      padding-right: 9px;
    }
    .blue-rect {
      width: 9px;
      height: inherit;
    }
    .renew-title {
      //display: inline-grid;
      font: normal normal 18px/23px 'campton-medium';
    }
    .renew-discription {
      // margin-left: -90px;
      // margin-top: 15px;
      // padding-left: 55px;
    }
    .card-link-learn-more {
      // margin-left: -48px;
      margin-top: 14px;
      color: #575757;
      font: normal normal 0.72rem/0.92rem campton-book;
      letter-spacing: 0;
    }
    .alert-title {
      // display: inline-grid;
      padding-bottom: 8px;
    }
    .alert-title-noCaps {
      padding-bottom: 8px;
    }
    // .icon-wrapper {
    //   padding-left: 19px;
    // }
    .card-link {
      padding-left: 2px;
    }
    .renew-discription {
      font: normal normal 15px/19px 'campton-medium';
      letter-spacing: 0.08px;
    }
    .alert-continued-title {
      padding-left: 5px;
      color: #000000;
      font: normal normal 18px/23px campton-medium;
    }
    .link-icon {
      padding-left: 5px;
      height: 7px;
      margin-bottom: 0px;
    }
    .btn-alert-modal {
      top: 13px;
      transform: scale(0.85);
      margin-right: -12px;
    }
  }
  .btn-alert-close {
    background: $white escape-svg($molina-close-btn-sm-svg) 0px 0px no-repeat padding-box;
    top: 12px;
  }
  .track-more-details .link-icon2 {
    margin-left: 1px;
  }
  .home-page {
    .card-bill-link {
      font: normal normal normal 20px/28px campton-book;
    }
    .coverage-width {
      width: 210px;
    }
    .card-bill-link {
      word-break: break-all;
      display: inline;
    }
    .card-desc-label {
      padding-bottom: 12px;
    }

    .link-icon2 {
      margin-bottom: 4px;
    }
    // .molina-card .card-link-cta-block-2col .link-icon2 {
    //   vertical-align: middle;
    // }
    .btn-pay-on .card-bill-label3 {
      margin-left: 1px;
    }
    .paperless-billing .card-bill-label3 {
      margin-left: 0px;
    }
    .paperless-billing .icon-data {
      margin-bottom: -1px;
    }
    .card-bill-value2 {
      font: normal normal normal 16px/18px Arial;
    }
    .track-more-details {
      margin-top: -4px;
    }
  }
  .lang-info-container {
    background: #e0e0e0 0 0 no-repeat padding-box;
    margin-bottom: -86px;
    margin-top: 177px;
    opacity: 1;
    width: 100%;
    height: 375px;
  }

  .btn-pay-on {
    .card-bill-label3 {
      margin-top: 0px;
    }
    display: inline-grid;
  }
  .card-bill-label3 {
    border-left: none;
    padding-left: 0px;
    margin-top: 10px;
    margin-left: -1px;
  }
  // .card-bill-msg {
  //   idtwh: 214px;
  //   text-align: center;
  // }
  .home-page {
    .bill-subtitle {
      width: 239px;
      white-space: nowrap;
    }
    .molina-card .card-title {
      margin-bottom: 18px;
    }
    .learnmoreonclick .img-link-icon {
      height: 10px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .card-link-cta-block-2col .card-width {
    width: 95% !important;
  }
  .alert-wrapper .red-rect {
    margin-left: -13px;
  }
  .alert-wrapper .blue-rect {
    margin-left: -13px;
  }
  .track-more-details .link-icon2 {
    margin-left: 1px;
  }
  .account-font {
    .phoneState {
      border: 1px solid black;
      margin: 10px;
      padding: 5px;
      text-align: center;
      font-size: 12px;
    }
    .phoneStateHeading {
      border: 1px solid black;
      margin: 10px;
      padding: 5px;
      text-align: center;
      font-size: 12px;
    }
  }
  .alert-wrapper {
    .carousel-btn-alert-next {
      position: absolute;
      top: 35%;
      margin: auto;
      cursor: pointer;
      right: 3%;
    }
    .carousel-btn-alert-prev {
      position: absolute;
      top: 35%;
      margin: auto;
      cursor: pointer;
    }
  }
}

.home-helpful-links {
  .links {
    position: relative;
  }
  .disabled-link {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
//   .home-page .cards-list > div:nth-child(3) {
//     padding-top: 1rem;
//   }
// }

.t-mid-par {
  display: table;
}
.t-mid-child {
  display: table-cell;
  vertical-align: middle;
}

.p-l-r-0 {
  padding: 0 !important;
}

.pad-mb {
  padding: 0 15px;
  @include media-breakpoint-up(xl) {
    padding: 0 20px;
  }
}
.p-r-5 {
  padding-right: 5px;
}

.header-alerts-carousel {
  .alert {
    padding: 0.64rem 0.64rem 0.95rem 0.64rem;
    @include media-breakpoint-down(lg) {
      padding: 0.64rem 0.64rem 1.85rem;
    }
  }
  .slick-dots {
    bottom: 35px !important;
    @include media-breakpoint-down(xxl) {
      bottom: 27px !important;
    }
    @include media-breakpoint-down(lg) {
      bottom: 24px !important;
    }
    @include media-breakpoint-down(md) {
      bottom: 22px !important;
    }
  }
}
@include media-breakpoint-down(xl) {
  .carousel-btns-alert.text-align-center {
    padding-right: 17px;
  }
}
@include media-breakpoint-down(lg) {
  .carousel-btns-alert.text-align-center {
    padding-right: 16px;
  }
  .p-xs-0 {
    // padding: 0;
  }
  .p-r-xs {
    padding-right: 0 !important;
  }
}

.message-send-msg {
  position: relative;
  bottom: 25px;
}
.mess-send-link {
  color: orangered;
  font: normal normal bold 0.72rem/0.84rem Arial;
}
.mess-send-link-termed {
  color: grey;
  font: normal normal bold 0.72rem/0.84rem Arial;
}
.message-send-msg a {
  color: orangered;
}
.message-send-msg a:hover {
  color: orangered;
}

.message-send-msg-termed {
  position: relative;
  bottom: 25px;
}
.message-send-msg-termed a {
  color: grey;
}
.message-send-msg-termed a:hover {
  color: grey;
}
.home-idcard {
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.img-playstore {
  width: 150px;
  position: absolute;
  display: inline-block;
  left: 250px;
  bottom: -200px;
}
.img-mobile-app {
  position: relative;
  width: 460px;
  top: 0;
}
.img-appstore {
  width: 150px;
  position: absolute;
  display: inline-block;
  left :70px;
  bottom: -200px;
}

@media screen and (max-width: 400px){
  .img-playstore{
    width: 100px;
    bottom: -110px;
    left: 140px;
  }
  .img-appstore{
    width: 100px;
    bottom: -110px;
    left: 35px;
  }
}
@media screen and (min-width: 420px) and (max-width: 600px){
  .img-playstore{
    width: 110px;
    bottom: -140px;
    left: 160px;
  }
  .img-appstore{
    width: 110px;
    bottom: -140px;
    left: 40px;
  }
}
@media screen and (min-width: 1020px) and (max-width: 1030px){
  .img-playstore{
    width: 110px;
    bottom: -140px;
    left: 160px;
  }
  .img-appstore{
    width: 110px;
    bottom: -140px;
    left: 35px;
  }
}
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .molina-card .card-link-cta-block-2col .molina-card-link:first-child {
    margin-bottom: 0;
    padding-top: 12px;
  }
  .idcardimage {
    height: 200px !important;
  }
}
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .idcardimage {
    height: 300px !important;
    width: 400px;
    margin: auto;
  }
}
