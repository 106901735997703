//
// Basic MDB table
//

.table {
  font-size: $table-font-size;
  box-shadow: 0px 4px 6px #00000075;

  > :not(caption) > * > * {
    padding: $table-cell-padding-y $table-cell-padding-x;
    transition: $table-hover-transition;
    background-image: none;
    background-color: var(--bs-table-accent-bg);
    border-bottom: 1px solid $gray-300;
  }

  th {
    //font-weight: 500;
    font: normal normal bold 18px/15px Arial;
    letter-spacing: 0.83px;
    color: $black;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    padding: $spacer * 1.563;
  }

  tbody {
    //font-weight: 300;
    font: normal normal normal 17px/20px Arial;
    letter-spacing: -0.65px;
    color: $black;
  }

  > :not(:last-child) > :last-child > * {
    //border-bottom-color: $table-group-separator-color;
    border-bottom-color: $gray-300;
  }
}

.table-sm {
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
  }
}

@each $color, $value in $table-variants {
  @include table-variant-mdb($color, $value);
}
