@use 'sass:math';

.memberUsefulTools > table > tr td {
  padding-bottom: 15px;
}

.memberSocialIcon {
  margin: 0 auto;
  text-align: center;
}

.memberSocialIcon .list-inline-item .img-fluid {
  width: 20px;
  height: 20px;
}

.utMedicardRight {
  padding-left: 69%;
}

.utMedicardRight > ul > li:not(:last-child) {
  padding-bottom: 5px;
}

#utMemberidCard {
  padding-top: 4.5rem;
}

.pwdPopover {
  border: 1px solid #151515;
  background-color: #f0f0f0 !important;
  font-size: 0.55rem !important;
  width: 18%;
}

.pwdPopover li {
  margin-top: 0.3rem;
}

.popoverHeader {
  font-size: 0.65rem;
}

.popover:after {
  border-right-color: red;
}

.memberSocialIcon .list-inline-item:not(:last-child) {
  margin-right: 2px;
}

@include media-breakpoint-down(md) {
  .memberSocialIcon .list-inline-item:not(:last-child) {
    margin-right: 0px;
  }

  .memberSocialIcon .list-inline-item .img-fluid {
    width: 15px;
    height: 15px;
  }
}

// Your custom variables
.qr-grid {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.font-faq {
  font-size: 12pt;
  font-family: 'Campton-medium';
}

.faq-debit {
  font-family: 'Campton-medium';
  margin: 0;
  padding: 0;
  height: 50vh;
  overflow: hidden;
}

.faq-container {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.faq-debit-h1 {
  font: bold normal 1.12rem / .92rem Campton-medium;
  color: #575757;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.faq-debit-h2 {
  font: bold normal .92rem / .92rem Campton-medium;
  color: #575757;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.faq-paragraph {
  line-height: 1.6;
}

.faq-disclaimer {
  margin-left: 30%; 
  margin-right: 0;
  color: #575757;
  font: normal normal .72rem / .92rem Campton-medium;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.faq-ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #575757;
  font: normal normal .72rem / .92rem Campton-medium;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.font-mac {
  font: normal normal 600 1rem/1.4rem Arial;
}

.font-mac-comingSoon {
  font: normal normal normal .8rem/.92rem Arial;
}

.font-win-comingSoon {
  font: normal normal normal .8rem/.92rem Arial;
  color: red;
}

.font-mac-items {
  font: normal normal normal 1rem/1.4rem Arial;
}

.row-label-style {
  text-align: center;
  font: normal normal 18px 'Campton-medium';
  padding-bottom: 7px;
  color: #000000;  
}

.styleType {
  list-style-type: circle;
}

.styleTypeDisc {
  list-style-type: disc;
}

.font-helpfullink {
  font-size: 12pt;
  font-family: 'Campton-medium';
}

.qr-header {
  margin-left: 10px;
  margin-bottom: 0px;
}

.qr-image {
  height: 115px;
  width: 115px;
}

.header-fix {
  position: fixed;
  width: 100%;
  z-index: 11;
  top: 0;
}

header {
  box-shadow: 0px 3px 6px #00000029;
}

.disabled-link {
  pointer-events: none;
  color: $gray-2 !important;

  img {
    opacity: 0.5;
  }
}

.my-id {
  top: 0px;
  left: 107px;
  width: 101px;
  height: 21px;
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal normal 16px/36px Arial;
  letter-spacing: 0px;
  color: #000000;
}

.my-id1 {
  top: 0px;
  left: 0px;
  width: 107px;
  height: 21px;
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal 16px/36px 'Campton-medium';
  letter-spacing: 0px;
  color: #000000;
}

.molina-header1 {
  border-bottom: 1px solid $gray-3;
  padding: 16px 0px 16px 0px;

  .dropdown-menu {
    box-shadow: 0px 5px 6px #00000066;
    border: 1px solid #cbcbcb;
    border-radius: 0;
  }
}

.molina-header1-ia {
  border-bottom: 1px solid $gray-3;
  padding: 16px 0px 16px 0px;

  .dropdown-menu {
    box-shadow: 0px 5px 6px #00000066;
    border: 1px solid #cbcbcb;
    border-radius: 0;
  }
}

.grid-2col-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
}

.header-icon {
  height: 25px;
  width: 33px;
  margin-left: 16px;
  margin-right: 16px;
  vertical-align: middle;
}

.bckLightBox:after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: grey;
  opacity: 0.5;
}

.navheaderCls {
  background-color: white;
  z-index: 1;
}

.header-dropdown-icon {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.molina-header1 ul:first-child .nav-item:first-child {
  padding: 0 0.32rem;
  display: flex;
  align-items: center;
}

.molina-header1-ia ul:first-child .nav-item:first-child {
  padding: 0 0.32rem;
  display: flex;
  align-items: center;
}

.molina-header-lbl1 > a {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 17px !important;
  cursor: pointer;
}

.molina-header-lbl1 a > div {
  font: normal normal normal 0.72rem/0.84rem Arial;
  color: $black;

  .dropdown-toggle {
    white-space: normal;
  }
}

.molina-header-lbl1 a > div > div:first-child {
  font: normal normal normal 0.72rem/0.64rem Campton-medium;
  color: $black;
  padding-bottom: 3px;
  display: inline-flex;

  span {
    min-width: 150px;
    display: block;
    display: -webkit-box;
    max-width: 275px;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.molina-header-lbl1 .dropdown-menu {
  left: 4.5%;
  padding: 0.938rem 0rem 0.625rem 0rem;

  .inner-spinner {
    padding-right: 11px;
  }

  .dropdown-item {
    padding: 0.5rem 1.938rem;
  }

  font: normal normal normal 16px/30px Campton-medium;

  .header-drp-lbl {
    text-transform: capitalize;
    font: normal normal normal 16px/36px Campton-medium;
  }

  .header-drp-value {
    font: normal normal normal 16px/36px Arial;
  }
}

.molina-header-lbl1 .dropdown-toggle::after {
  margin-top: 5px;
}

.molina-header-lbl1 .dropdown-menu hr {
  margin-left: 15px;
  margin-right: 15px;
}

.molina-header-lbl2 > a {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px !important;
  border-left: solid 1px $black;
}

.molina-header-lbl2 > a > div {
  display: inline-table;
  font: normal normal normal 0.72rem/0.88rem Campton-medium;
  color: $black;
  min-width: 150px;
  display: block;
  display: -webkit-box;
  max-width: 265px;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.molina-header-lbl2 > a > div > span {
  font-family: 'campton-bold';
  color: $black;
}

.molina-header-lbl2 > a > div:last-child {
  font: normal normal normal 0.72rem/0.88rem Arial;
  color: $black;
  display: block;
}

.molina-header-logo > img {
  margin-top: 0px;
  margin-right: 0px;
}

.molina-header-logo > span {
  border-left: 1px solid $gray-3;
  padding-left: 15px;
  color: $secondary;
  font: normal normal normal 0.96rem/1.24rem Campton-semibold;
}

.molina-header-logo-ia {
  position: absolute;
  left: 50%;
  right: 50%;
  justify-content: center;
}

.molina-header-logo-ia > img {
  margin-top: -5px;
  margin-right: 15px;
}

.molina-header-logo-ia > span {
  border-left: 1px solid $gray-3;
  padding-left: 15px;
  color: $secondary;
  font: normal normal normal 0.96rem/1.24rem Campton-semibold;
}

.molina-header-lbl3 {
  .header-icon,
  .header-icon-msg {
    height: 29px;
    width: 29px;
    margin-left: 10px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:first-child .dropdown-menu {
    padding: 12px 0px;
  }

  &:last-child {
    padding-right: 14px;
  }
}

.molina-header-lbl3 .dropdown-toggle {
  color: $black;

  div {
    font: normal normal bold 16px/16px Arial;
    vertical-align: middle;
    display: inline-block;
    color: $black;
  }
}

.molina-header-lbl3 .dropdown-toggle::after {
  content: none;
}

.molina-header-lbl3 .dropdown-item {
  font: normal normal normal 0.72rem/1.2rem Campton-medium;
}

.molina-header-lbl3 .dropdown-item.active {
  background-color: $white;
}

.molina-header-lbl3 .dropdown-item.active:hover {
  background-color: $gray-200;
}

.molina-header-lbl3 .dropdown-item.active span {
  border-bottom: 1px solid $black;
  padding-bottom: 1px;
}

.molina-header-lbl3 .dropdown-item .header-dropdown-icon {
  visibility: hidden;
}

.molina-header-lbl3 .dropdown-item.active .header-dropdown-icon {
  visibility: visible;
}

.molina-header-lbl3:nth-child(2) .dropdown-item {
  font: normal normal normal 0.72rem/1.2rem Campton-book;
  padding: 39px 36px 38px 37px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.molina-header2 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.molina-header2 .header-link {
  justify-content: center;
  display: flex;
}

.molina-header2 .navbar-brand {
  font: normal normal normal 0.96rem/1.24rem Campton-book;
  padding: 16px 35px 16px 35px;
  border-bottom: 2px solid $white;
  margin-left: 0;
  margin-right: 0;
  color: $black;
  text-align: center;
}

.molina-header2 .navbar-brand:hover {
  border-bottom: 2px solid $primary;
}

.molina-header2 .navbar-brand.active {
  background: $primary;
  color: $white;
  border-bottom: 2px solid $primary;
}

.sidenav {
  height: 100%;
  width: 365px;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  background-color: $white;
  overflow-x: hidden;
  transition: 0.5s;
  transform: translateX(-365px);

  &::-webkit-scrollbar-track {
    background-color: $gray-100;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: $gray-100;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $gray-bg;

    &:hover {
      cursor: pointer;
    }
  }

  .navbar {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.active-leftnav {
  transform: translateX(0px);

  box-shadow: 0px 6px 8px #00000066;
}

.sidenav .sidenav-nav:first-child {
  padding-top: $spacer * 0.64;

  a:hover,
  a:active {
    background: none;
  }
}

.sidenav .sidenav-nav {
  list-style: none;
  padding-right: $spacer * 0;
  padding-left: $spacer * 0;
  margin-bottom: $spacer * 0;
}

.sidenav .sidenav-nav li .nav-link {
  font: normal normal normal 0.8rem/0.8rem Campton-book;
  letter-spacing: 0.36px;
  padding: $spacer * 1.063 $spacer * 1 $spacer * 1.063 $spacer * 2;
}

.molina-leftnav-logo {
  padding: $spacer * 1.2 $spacer * 1 $spacer * 1.2 $spacer * 2;
}

.sidenav .sidenav-nav li a {
  color: $black;
  font-family: 'campton-book';
}

.sidenav .sidenav-nav li > .nav-link:hover,
.sidenav .sidenav-nav li > .nav-link:active {
  background-color: $gray-3;
}

.notification-unseen-flag {
  width: 5px;
  height: 5px;
  background-color: $count-circle-bg-color;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 2px;
}

.sidenav .sidenav-nav {
  .sidenav-nav-logo {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .sidenav-nav-title:nth-child(3) {
    padding-bottom: 23px;
    font: normal normal normal 18px/21px Arial;
  }

  .sidenav-nav-title:nth-child(4) {
    padding-bottom: 12px;
  }

  .sidenav-nav-title:nth-child(5) {
    padding-bottom: 8px;
  }
}

.sidenav .sidenav-nav .sidenav-nav-title {
  padding-right: 16px;
  padding-left: 32px;

  .leftnav-label {
    text-transform: capitalize;
    font: normal normal normal 18px/28px Campton-medium;
  }

  .leftnav-value {
    font: normal normal normal 18px/28px Arial;
  }
}

.sidenav .sidenav-nav .sidenav-nav-title span {
  font: normal normal bold 18px/23px 'campton-bold';
}

.molina-leftnav-logo > img {
  height: 28px;
  vertical-align: sub;
}

.molina-leftnav-logo > span {
  border-left: 1px solid $text-color-muted-light;
  padding-left: 10px;
  color: $secondary;
  font: normal normal normal 17px/22px 'campton-semibold';
  margin-left: 10px;
}

.sidenav .sidenav-btn {
  padding-top: $spacer * 1;
  padding-bottom: $spacer * 1.063;
}

.sidenav .menubtn {
  margin-top: 15px;
  margin-left: 16px;
  margin-right: 50px;
}

.sidenav .closebtn {
  position: absolute;
  top: 26px;
  right: 18px;
  margin-left: 50px;
}

.form-error-msg {
  font-size: 0.72rem;
  line-height: 0.84rem;
}

.dropdown-divider {
  margin: 8px 0;
}

.molina-card {
  min-height: 464px;
  width: 100%;

  .card-link {
    display: inline-flex;
    margin-bottom: 0.5rem;
    align-items: center;
    color: $black;
  }

  .card-link-cta-block {
    position: absolute;
    bottom: 0.75rem;
    left: 0;
    right: 0;
  }

  .card-link-cta-block .card-link,
  .card-link-cta-block-2col .card-link {
    font: normal normal normal 0.72rem/0.92rem 'campton-medium';
    letter-spacing: 0.9px;
    color: $black;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 0px;
    cursor: pointer;

    .link-icon2 {
      vertical-align: bottom;
    }
  }

  .card-link-cta-block .viewallnormalfont {
    margin-top: 0;
    text-align: center;
    line-height: 1.2;
    font: normal normal normal 12px/23px 'campton-medium';
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #757575 !important;
    font-weight: 400 !important;

    div {
      margin-bottom: math.div($spacer, 2);
      font: normal normal normal 18px/27px 'campton-medium';
      letter-spacing: 0.9px;
      opacity: 0.5;
      color: $black;
    }
  }
}

.text-padding-top {
  padding-top: 32px;
}

.text-align-center {
  text-align: center;
  padding-top: 32px;
  font: normal normal normal 0.88rem/1.12rem Campton-book;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}

.autocomplete-control {
  position: relative;

  .autocomplete-list {
    position: absolute;
    z-index: 1;
    cursor: default;
    padding: 0;
    list-style: none;
    background-color: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    top: 0px;
    left: 0px;
    min-width: 200px;
    width: 100%;
    font: normal normal normal 0.64rem/0.96rem Arial;
    letter-spacing: 0.5px;

    li {
      padding: 0.5rem 1rem;
      background: #fff;
    }

    li:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
}

.learnmoreonclick {
  letter-spacing: 0px;
  color: #454545;
  opacity: 1;
  font: normal normal 0.88rem/1.12rem 'Campton-book';
}

.font-weight-500 {
  font-weight: 500;
}

.float-right {
  float: right;
}

.i-tooltip {
  cursor: pointer;
}

.i-molina-teal {
  color: $teal;
  margin-right: 10px;
}

.img-molina-mail {
  margin-right: 18.5px;
  margin-top: 0.16rem;
  width: 0.92rem;
  height: 0.72rem;
}

.img-molina-phone {
  margin-right: 18.5px;
  margin-top: 4px;
  width: 23px;
  height: 18px;
}

.img-molina-mail-read {
  margin-right: 18.5px;
  margin-top: 0.16rem;
  margin-bottom: -0.16rem;
  width: 0.92rem;
  height: 0.92rem;
}

.img-molina-teal {
  margin-right: 14.5px;
  width: 1.12rem;
  height: 1.12rem;
}

.img-molina-info-teal {
  height: 0.56rem;
  margin-bottom: 2px;
}

.img-molina-live-chat {
  margin-left: 10.5px;
  margin-top: 0.16rem;
  margin-bottom: -0.16rem;
  width: 3.92rem;
}

.track-more-details,
.track-more-details:hover {
  font: normal normal normal 0.72rem/0.84rem Arial;
  color: $text-color-muted;
  // border-left: 1px solid $text-color-muted-light;
  padding-left: 10px;
  margin-left: 10px;
}

.molina-progress-bar-list {
  height: 100%;
  align-items: center;
  display: grid;
}

.molina-progress-bar-list.NVrewards {
  height: auto !important;
}

.molina-progress-bar-title {
  display: grid;
  padding-bottom: $spacer * 0.5;
}

.molina-progress-bar-title span:first-child {
  font: normal normal normal 0.72rem/0.92rem 'campton-bold';
  color: $text-color-muted;
}

.molina-progress-bar-title .molina-progress-bar-total-score {
  font: normal normal normal 0.72rem/0.84rem Arial;
  color: $black;
  float: right;
}

.molina-progress-bar {
  position: relative;
  margin-right: 56px;
  margin-bottom: $spacer * 2.144;

  .progress {
    height: 0.8rem;
  }
}

.molina-progress-bar {
  .molina-progress-bar-score {
    position: absolute;
    //min-width: 50px;
    text-align: center;
    color: $black;
    top: 100%;
    margin-top: 10px;
    font: normal normal bold 0.8rem/0.92rem Arial;
  }

  .txt-left {
    text-align: left !important;
  }

  .txt-right {
    text-align: right !important;
  }
}

.video-placeholder-text {
  position: absolute;
  top: 70%;
  left: 0;
  text-align: center;
  right: 0;
  font-size: 0.76rem;
  line-height: 0.92rem;
  font-family: 'Campton-book';
  color: $purple;

  div {
    width: 80%;
    margin: auto;
  }
}

.molina-video-block picture {
  display: none;
}

.molina-video-block .embed-responsive {
  display: block !important;
}

.page-heading {
  font: normal normal normal 2rem/2.56rem 'campton-medium';
}

.mr-13 {
  margin-right: 13px;
}

.mt-30px {
  margin-top: 30px;
}

.lg-card {
  .card-body {
    padding: 2rem;
  }
}

.font-arial-20 {
  font-size: $font-size-20;
}

input:disabled::-webkit-input-placeholder {
  color: $input-disabled-placeholder-color;
}

.input-error {
  border: 2px solid $input-error-color;
}

.mb-58 {
  margin-bottom: 58px;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;

  p {
    margin-bottom: 10px;
  }

  span {
    font: normal normal 0.96rem/1.24rem Campton-medium;
    color: #000000;
  }

  a {
    color: #000000;
  }
}

.home-link {
  margin-top: 2.8rem;
  margin-bottom: 0.8rem;

  img.left-arrow-custom {
    position: absolute;
    margin: 0.24rem 0px 0px -0.92rem;
    height: 0.8rem;
  }

  //.media-body {
  //  margin-left: 11px;
  //}
}

.left-arrow-custom {
  transform: rotate(180deg);
  margin-top: 3px;
}

.popup-btn a {
  top: 55%;
  position: fixed;
  right: 0;
  z-index: 1000;
  transform: rotate(-90deg);
  background-color: #009ea0;
  padding: 10px 0.8rem 1.2rem;
  height: 0px;
  color: #fff;
  transform-origin: 100% 100%;

  &:hover {
    background-color: #fff;
    color: #009ea0;
  }
}
.chat-btn {
  top: 83%;
  position: fixed !important;
  right: 2%;
  &:hover {
    cursor: pointer;
  }
  z-index: 1;
}

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

.custom-tooltip {
  position: relative;
  display: inline-block;

  .custom-tooltip-text {
    visibility: hidden;
    width: 225px;
    white-space: pre-line;
    background-color: #e0e0e0;
    color: #000000;
    text-align: center;
    font: normal normal normal 19px/22px Arial;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 128%;
    left: 15%;
    box-shadow: 0px 3px 6px #00000040;
    text-align: initial;
    padding-bottom: 10%;
    padding-left: 10px;
    padding-right: 10px;

    &::after {
      content: '';
      position: absolute;
      bottom: 98%;
      left: 7%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent rgba(224, 224, 224) transparent;
    }
  }

  .custom-tooltip-text-livechat {
    visibility: hidden;
    width: 225px;
    white-space: pre-line;
    background-color: #e0e0e0;
    color: #000000;
    text-align: center;
    //font: normal normal normal 19px/22px Arial;
    box-shadow: 3px 0px 6px #00000040;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 114%;
    //text-align: initial;
    padding-top: 10%;
    padding-left: 10px;
    padding-right: 10px;
    right: 5%;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 75%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent rgba(224, 224, 224) transparent;
      transform: rotate(180deg);
    }
  }

  &:hover {
    .custom-tooltip-text-livechat {
      visibility: visible;
    }
  }

  &:hover {
    .custom-tooltip-text {
      visibility: visible;
    }
  }
}

.custom-tooltip-spending {
  position: relative;
  display: inline-block;

  .custom-tooltip-spending-text {
    display: none;
    box-sizing: border-box;
    width: 247px;
    font: normal normal normal 0.72rem/0.84rem Arial;
    // white-space: pre-line;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    // padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 128%;
    left: 25%;
    // width: 247.46px;
    box-shadow: 0px 3px 6px #00000040;
    text-align: initial;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 98%;
    //     left: 7%;
    //     margin-left: 5px;
    //     border-width: 10px;
    //     border-style: solid;
    //     border-color: transparent transparent #707070;
    //     opacity: 1;
    // }
  }

  &:hover {
    .custom-tooltip-spending-text {
      display: block;
    }
  }
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.molina-select__control {
  border-color: #999999 !important;
  padding: 11px 8px 11px 8px !important;
  transition: all 0.1s linear;
  letter-spacing: 0.15px;
  font: normal normal normal 0.64rem/0.8rem Arial;
  border-radius: 4px 4px 0 0 !important;

  // &:hover {
  //   border: 1px solid #00000099 !important;
  // }
}

.molina-select__control_err {
  .molina-select__control {
    border: 2px solid #e1684c !important;
  }
}

.molina-select__control--is-focused {
  border-color: $black !important;
  box-shadow: none !important;
}

.molina-select__control--menu-is-open {
  border-bottom: 2px solid #009ea0 !important;
}

.molina-select__menu {
  margin: 0 !important;
  box-shadow: $dropdown-molina-box-shadow !important;
  border-radius: 0 !important;
  border-color: #999;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  font: normal normal normal 16px/20px Arial;
  letter-spacing: 0.5px;

  .molina-select__single-value {
    font: normal normal normal 0.64rem/0.96rem Arial !important;
  }
}

.molina-select__option {
  color: #000000de !important;
}

.molina-select__option--is-focused {
  background-color: #e0e0e0 !important;
}

.molina-select__option--is-selected {
  background-color: #e0e0e0 !important;
}

.blue-text {
  color: #009ea0;
}

.molina-select__indicator-separator {
  display: none;
}

.molina-select__dropdown-indicator {
  color: inherit !important;
  padding: 0px !important;

  .fa-caret-down {
    height: 16px;
    width: 16px;
    background: escape-svg($molina-dropdown-arrow-svg) 50% 50% no-repeat padding-box;
  }

  .fa-caret-down::before {
    content: none;
  }
}

.select-info-icon {
  width: 22px;
}

.show {
  display: block;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.field1 .field2 {
  justify-content: space-between;
}

.text-right {
  text-align: right;
}

.text-right .dropdown-item.active {
  background-color: $white;
}

.text-right .dropdown-item.active span {
  border-bottom: 1px solid $black;
}

.text-right .dropdown-item {
  font-family: 'campton-medium';
}

.scrollable-tabs {
  position: relative;

  .carousel-btns {
    position: absolute;
    right: 0px;
    bottom: 3.7rem;

    a:first-child {
      margin-right: 8px;
    }

    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .nav-tabs {
    flex-wrap: nowrap;
    overflow: hidden;

    .nav-link {
      white-space: nowrap;
      border-right: 1px solid #f5f5f7;
    }

    .nav-item:last-child {
      border-right: none;
    }
  }
}

.nav-tabs {
  .nav-link {
    font: normal normal bold 0.8rem/0.64rem Arial;
    text-transform: none;
    background: $white;
    color: $nav-tab-txt-color;
    padding: 27px 30px 25px 30px;
    margin-bottom: 1px;

    &:hover {
      background: $gray-3;
    }
  }

  .nav-link.active {
    background: $gray-3;
  }
}

.tab-sub-title {
  font: normal normal 1.2rem/1.52rem Campton-medium;
  padding-bottom: 2.75rem;
}

.sub-color {
  color: "#009ea0" !important;
}

.tab-content {
  word-break: break-word;

  .tab-title {
    font-size: 1.2rem;
    font-family: 'campton-semibold';
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
  }

  .no-tab-title {
    padding-top: $spacer * 2.5;
  }

  .list-group-item {
    margin-bottom: $spacer;
    box-shadow: 0px 3px 1px #0000001f;
    border-radius: 0.25rem;
    font-size: 20px;
    padding: $spacer * 1.5 $spacer * 1.5;
  }

  .list-title {
    font: normal normal normal 0.8rem/1.04rem 'campton-medium';
    //font-family: ;
    letter-spacing: 0.15px;
  }

  .list-desc {
    > div:first-child {
      font: normal normal normal 0.8rem/0.96rem Arial;
      // padding-bottom: $spacer * 1.5;
      color: #121212;
    }

    .list-desc-txt a {
      color: $teal;
    }

    .list-desc-txt a:hover {
      text-decoration: underline;
    }
  }

  .list-desc2 > div:first-child {
    font: normal normal normal 0.8rem/1.04rem Arial;
    letter-spacing: 0.15px;
    padding-bottom: $spacer;
  }

  .list-desc-title {
    color: $list-desc-details;
    font: normal normal bold 0.8rem/1.04rem Arial;
    letter-spacing: 0.15px;
    opacity: 0.6;
  }

  .list-desc-details {
    color: $list-desc-details;
    font: normal normal normal 0.8rem/1.04rem Arial;
    letter-spacing: 0.15px;
    opacity: 0.6;
  }

  .list-multi-link {
    align-items: center;
    display: grid;
  }

  .list-multi-link-drp .text-right {
    display: flex;
    justify-content: flex-end;
  }

  .list-icon-download {
    color: #575757;
    font: normal normal bold 18px/28px Arial;
    letter-spacing: 0.14px;
  }

  .list-icon-download:hover {
    text-decoration: underline;
  }

  .list-icon-download img {
    width: 37px;
    height: 37px;
    //padding-left: 10px;
  }

  .list-icon-pen {
    color: #575757;
    font: normal normal bold 18px/28px Arial;
    letter-spacing: 0.14px;
  }

  .list-icon-pen:hover {
    text-decoration: underline;
  }

  .list-icon-pen img {
    width: 37px;
    height: 37px;
    //padding-left: 10px;
  }

  .list-icon-newtab {
    position: absolute;
    right: 1.2rem;
    top: 0.7552rem;
    width: 32px;
  }

  .list-icon-newtab-myvidoes {
    position: absolute;
    right: 0.1rem;
    top: 0.7552rem;
    width: 32px;
  }
  
  .list-icon-newtab > img {
    width: 32px;
    height: 32px;

    @include media-breakpoint-down(xxl) {
      width: 27px;
      height: 27px;
    }
  }

  .accordion-item {
    position: relative;
    margin-bottom: $spacer;
    box-shadow: 0px 3px 1px #0000001f;
    border-radius: 0.25rem;
    background: $white;
  }

  .accordion-item .row {
    display: contents;
  }

  .accordion-item .accordion-button,
  .accordion-item .accordion-link {
    padding: 1.2 * 0.64rem 1.5 * 0.64rem 1 * 0.64rem 1.1 * 0.64rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font: normal normal normal 0.8rem/0.96rem 'campton-medium';
    letter-spacing: 0.15px;
    border: none;

    &:focus {
      box-shadow: none;
      border: none;
      z-index: 0;
    }
  }

  .accordion-item .accordion-button:not(.collapsed) {
    color: $black;
    background: $gray-3;
  }

  .accordion-item .accordion-button::after {
    margin-right: -10px;
  }

  .accordion-body {
    color: $list-desc-details;

    .accordion-body-title {
      font: normal normal normal 0.8rem/1.04rem 'campton-medium';
      letter-spacing: 0.15px;
      padding-top: $spacer * 2;
      padding-bottom: 1rem;
    }

    .accordion-body-desc {
      font: normal normal normal 0.8rem/1.04rem Arial;
      letter-spacing: 0.15px;
      padding-bottom: $spacer * 1.5;
    }
  }
}

.btn-close-modal {
  background: $white escape-svg($molina-close-btn-xl-svg) 0px 0px no-repeat padding-box;
  height: 24px;
  width: 24px;
  border: none;
  position: absolute;
  right: 24px;
  top: 24px;
}

.custom-modal {
  max-width: 600px;
}

.custom-modal-md {
  max-width: 800px;
  margin-top: $spacer * 9.375;

  .btn-close-modal {
    right: 27px;
    top: 27px;
  }

  h5 {
    margin-bottom: $spacer * 2.75;
    padding-bottom: 0px !important;
  }

  .form-group {
    padding-bottom: $spacer * 1.5;
  }

  .form-group > label {
    font: normal normal normal 0.72rem/0.84rem Arial;
    color: $black;
    margin-bottom: $spacer * 0.75;
  }

  .modal-btns {
    margin-top: 20px;

    button {
      text-transform: uppercase;
    }

    .cancel-button {
      left: 0%;
    }

    .search-button {
      margin-left: $spacer;
    }
  }
}

.custom-modal-lg {
  max-width: 1326px;

  .back-link {
    font: normal normal normal 0.96rem/1.24rem Campton-medium;
    cursor: pointer;

    .back-link-arrow {
      margin-bottom: 3px;
    }

    .back-link-title {
      display: inline;
    }
  }

  .table-heading {
    font: normal normal normal 0.88rem/1.12rem Campton-medium;
    color: $gray-1;
    margin: $spacer * 1.563 0 $spacer * 1.875;

    span {
      font: normal normal normal 0.88rem/1.12rem Campton-semibold;
      color: $gray-1;
    }
  }
}

.custom-modal-xl {
  max-width: 1490px;

  .back-link {
    font: normal normal normal 0.96rem/1.24rem Campton-medium;
    cursor: pointer;

    .back-link-arrow {
      margin-bottom: 3px;
    }

    .back-link-title {
      display: inline;
    }
  }

  .table-heading {
    font: normal normal normal 0.88rem/1.12rem Campton-medium;
    color: $gray-1;
    margin: $spacer * 1.563 0 $spacer * 1.875;

    span {
      font: normal normal normal 0.88rem/1.12rem Campton-semibold;
      color: $gray-1;
    }
  }
}

.update-header {
  h5 {
    text-align: left;
    font: normal normal 1.04rem/1.32rem Campton-medium;
    letter-spacing: 0px;
    color: #000000;
    padding-bottom: 1rem;
    // padding-right: 1.5rem;
  }

  p {
    text-align: left;
    font: normal normal normal 0.88rem/1.04rem Arial;
    letter-spacing: 0.26px;
    color: #454545;
    padding-bottom: 1rem;
    // padding-right: 1.5rem;
  }

  h4 {
    text-align: left;
    font: normal normal 1.04rem/1.32rem Campton-medium;
    letter-spacing: 0px;
    color: #009ea0;
    padding-bottom: 1rem;
  }
}

.info-update-button {
  background: #009ea0 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  font-family: 'campton-medium';
  font-size: 18px;
}

.info-update-button a {
  color: #ffffff;
}

.info-cancel-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000040;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  font-family: 'campton-medium';
  font-size: 18px;
}

.info-cancel-button a {
  color: #009ea0;
}

.confirm-button {
  background: #009ea0 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  font-family: 'campton-medium';
  font-size: 18px;
  color: #ffffff;
}

.continue-button {
  background: #009ea0 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  font-family: 'campton-medium';
  font-size: 18px;
  color: #ffffff;
  margin-left: 1rem;
}

.cancel-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000003d;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  font-family: 'campton-medium';
  font-size: 18px;
  color: #009ea0;
}

//drp-year//
.drp-year {
  display: flex;
  align-items: center;

  .drp-label {
    font: normal normal normal 0.88rem/1.12rem 'campton-medium';
    white-space: nowrap;
  }
}

//End//

//Print option modal//
@include media-breakpoint-up(xxl) {
  .member-id-card {
    .print-options-modal {
      .background-idcard {
        padding: 8rem 1rem 2rem 1.2rem;
      }

      .member-id-card-back {
        padding: 0rem 0.3rem 2rem;
      }
    }
  }
}

.print-options-modal {
  .modal.show {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .modal-content {
    margin: unset;
    min-width: 580px;
    border-radius: unset;
  }

  .modal-header {
    padding-bottom: unset;
  }

  .modal-body {
    padding: 18px 50px 9px 50px;

    h5 {
      font: normal normal normal 1.04rem/1.32rem 'campton-medium';
    }

    p {
      font: normal normal normal 0.88rem/1.04rem Arial;
    }

    .form-check {
      margin-bottom: 10px;
      font: normal normal normal 0.88rem/1.04rem Arial;
    }
  }

  .modal-footer {
    border: none;
    padding-left: 50px;
    padding-right: 50px;

    .btn,
    .btn-primary {
      width: auto;
      font: normal normal normal 0.72rem/0.92rem 'campton-medium';
    }
  }
}

//Print option modal End//

//pagination and print button//
.pagination {
  font: normal normal normal 0.9rem/0.84rem Arial;
  margin-bottom: 14px;
  margin-top: 27px;

  a {
    color: #000000;

    img {
      margin-right: 8px;
    }
  }
}

//pagination and print button End//

// .container {
//   padding-right: 0px;
//   padding-left: 0px;
// }
@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .container {
    padding-right: 5px;
    padding-left: 5px;
  }
  .pwdPopover{
    width: 40%;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .row > .col,
  .row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .info-update-button {
    left: 4rem;
  }

  .info-cancel-button {
    left: 3rem;
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .mobileMessage {
    margin-top: -1.1rem;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }

  .info-update-button {
    left: 6rem;
  }

  .info-cancel-button {
    left: 5rem;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .container {
    padding-right: 81px;
    padding-left: 81px;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;

    .card-padding-back {
      padding: 2.5rem 5px 0px !important;
    }
  }

  // .modal-update-dialog {
  //   left: 6rem;
  // }
  .info-update-button {
    left: 16rem;
  }

  .info-cancel-button {
    left: 15rem;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .container {
    padding-right: 137px;
    padding-left: 137px;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }

  // .modal-update-dialog {
  //   left: 23rem;
  // }
  .info-update-button {
    left: 15rem;
  }

  .info-cancel-button {
    left: 14rem;
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) and (max-width: map-get($grid-breakpoints, xxl)) {
  .container {
    padding-right: 139px;
    padding-left: 139px;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }

  // .modal-update-dialog {
  //   left: 23rem;
  // }
  .info-update-button {
    left: 15rem;
  }

  .info-cancel-button {
    left: 14rem;
  }

  // .update-info{
  //   h1{
  //     padding-left: 50px;
  //     padding-top: 30px;
  //     padding-bottom: -5px;
  //   }
  // }
  // .update-info-content{
  //   p{
  //     padding-left: 50px;
  //     padding-right: 50px;
  //     padding-bottom: 44px;
  //   }
  // }
}

@media (min-width: map-get($grid-breakpoints, xxl)) {
  .container {
    padding-right: 178px;
    padding-left: 178px;
  }

  .row {
    margin-left: -22px;
    margin-right: -22px;
  }

  .row > .col,
  .row > [class*='col-'] {
    padding-right: 22px;
    padding-left: 22px;
  }

  // .modal-update-dialog {
  //   left: 38rem;
  // }
  .info-update-button {
    left: 15rem;
  }

  .info-cancel-button {
    left: 14rem;
  }
}

@include media-breakpoint-down(xxl) {
  .btn {
    font: normal normal normal 0.64rem/0.84rem 'campton-medium';
  }

  .TXmrgnCHIP > li:not(:last-child) {
    margin-bottom: 0px;
  }

  .img-molina-teal {
    margin-right: 17.5px;
    width: 24px;
    height: 24px;
  }

  .page-heading {
    line-height: 32px;
    font-size: 30px;
  }

  .molina-header1 {
    padding: 8px 0px;
  }

  .molina-header1-ia {
    padding: 8px 0px;
  }

  .header-icon {
    height: 20px;
    width: 25px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .molina-header-lbl1 {
    .dropdown-menu {
      padding: 0.5rem 0rem;

      .dropdown-item {
        padding: 0.5rem 1.5rem;
      }

      .header-drp-lbl,
      .header-drp-value {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }

  .molina-header-lbl1 > a {
    padding-right: 10px !important;
  }

  .molina-header-lbl1 a > div > div:first-child {
    font-size: 14px;
    line-height: 15px;
    margin-top: 3px;
  }

  .molina-header-lbl1 a > div {
    font-size: 14px;
    line-height: 16px;
  }

  .molina-header-lbl1 .dropdown-menu {
    left: 4%;

    .inner-spinner {
      padding-right: 11px;
    }
  }

  .molina-header-lbl2 > a {
    padding-left: 10px !important;
  }

  .molina-header-lbl2 > a > div {
    max-width: 218px;
  }

  .molina-header-lbl2 > a > div:first-child {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .molina-header-logo {
    img {
      height: 30px;
    }
  }

  .header-dropdown-icon {
    height: 20px;
    width: 20px;
  }

  .molina-header-lbl3 {
    .header-icon {
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }

    .header-icon-msg {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-top: 2px;
    }

    &:first-child .dropdown-menu {
      padding: 5px 0px;
    }

    .dropdown-item {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .molina-header-lbl3:nth-child(2) .dropdown-item {
    font-size: 16px;
    line-height: 26px;
    padding: 26px 17px 25px 18px;
  }

  .molina-header2 .navbar-brand {
    font: normal normal normal 16px/20px Campton-book;
    padding: 10px 15px 10px 15px;
  }

  .sidenav {
    .sidenav-btn {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .closebtn {
      top: 22px;
      right: 17px;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }

  .sidenav .sidenav-nav li .nav-link {
    font: normal normal normal 18px/20px Campton-book;
    letter-spacing: 0px;
    padding: 17px 16px 17px 32px;
  }

  .molina-leftnav-logo {
    padding: 9px 16px 0px 32px;
  }

  .nav-tabs {
    .nav-link {
      //ont: normal normal bold 16px/12px Arial;
      padding: 17px 25px 16px 25px;
    }
  }

  .card-title {
    // font: normal normal normal 25px/48px 'campton-medium';
    letter-spacing: -0.2px;
    color: #009ea0;
    font-weight: 600;
  }

  .molina-card .card-link-cta-block-2col .card-link,
  .molina-card .card-link-cta-block .card-link {
    .link-icon2 {
      height: 20px;
      width: 20px;
      margin-bottom: 0px;
    }
  }

  .molina-progress-bar {
    margin-right: 25px;
    margin-bottom: $spacer * 2.875;

    .progress {
      height: 16px;
    }
  }

  .molina-progress-bar-title {
    padding-bottom: $spacer * 0;
  }

  .molina-progress-bar .molina-progress-bar-score {
    margin-top: 2px;
  }

  .autocomplete-control {
    .autocomplete-list {
      font: normal normal normal 0.72rem/0.96rem Arial;
      letter-spacing: 0.56px;
    }
  }

  .tab-content {
    .no-tab-title {
      padding-top: $spacer * 2;
    }

    .list-group-item {
      margin-bottom: $spacer;
      box-shadow: 0px 3px 1px #0000001f;
      border-radius: 0.25rem;
      font-size: 20px;
      padding: 1.5rem;
    }

    // .list-title {
    //   font: normal normal bold 20px/23px Arial;
    //   letter-spacing: 0px;
    // }

    // .list-desc > div:first-child {
    //   font: normal normal normal 16px/18px Arial;
    //   letter-spacing: 0px;
    // }

    .list-desc-title {
      // font: normal normal bold 16px/26px Arial;
      //letter-spacing: 0.12px;
      opacity: 0.75;
    }

    .list-desc-details {
      // font: normal normal normal 16px/26px Arial;
      //letter-spacing: 0.12px;
      opacity: 0.75;
    }

    .list-multi-link {
      display: flex;
      justify-content: flex-end;
    }

    .list-icon-download {
      font: normal normal bold 16px/18px Arial;
      letter-spacing: 0.14px;
    }

    .list-icon-download img {
      width: 27px;
      height: 27px;
      //padding-left: 10px;
    }

    .list-icon-pen {
      font: normal normal bold 16px/18px Arial;
      letter-spacing: 0.14px;
    }

    .list-icon-pen img {
      width: 27px;
      height: 27px;
      //padding-left: 10px;
    }

    .accordion-body {
      color: $list-desc-details;
    }
  }

  .molina-select__control {
    padding: 5px 8px 6px 8px !important;
    //font: normal normal normal 16px/20px Arial;
  }

  .custom-modal-md {
    margin-top: $spacer * 6.25;
  }

  .custom-modal-lg {
    max-width: 800px;

    .back-link {
      .back-link-arrow {
        height: 20px;
        width: 20px;
      }
    }

    .table-heading {
      margin-bottom: $spacer * 1.156;
    }
  }

  .custom-modal-xl {
    max-width: 1100px;

    .back-link {
      .back-link-arrow {
        height: 20px;
        width: 20px;
      }
    }

    .table-heading {
      margin-bottom: $spacer * 1.156;
    }
  }

  @include media-breakpoint-down(md) {
    .molina-card .card-link-cta-block-2col .card-link,
    .molina-card .card-link-cta-block .card-link {
      font: normal normal normal 1rem/0.92rem campton-medium;
    }

    .pagination {
      font: normal normal normal 16px/0.84rem Arial;
    }

    .text-align-center {
      text-align: center;
      width: 96%;
      padding-top: 0px;
      padding-bottom: 20px;
    }

    .list-icon-newtab > img {
      width: 27px;
      height: 27px;
    }

    .tab-content {
      .accordion-item .accordion-button,
      .accordion-item .accordion-link {
        font: normal normal bold 20px/23px Arial;
        letter-spacing: 0px;
      }

      .accordion-body {
        .accordion-body-title {
          font: normal normal 700 20px/26px Arial;
          letter-spacing: 0.15px;
        }

        .accordion-body-desc {
          font: normal normal normal 18px/22px Arial;
          letter-spacing: 0.14px;
        }
      }
    }
  }

  //drp-year//
  .drp-year {
    .drp-label {
      font: normal normal normal 0.72rem/0.84rem Arial;
    }
  }

  //End//
}

@include media-breakpoint-down(xl) {
  // .molina-header-logo {
  //   left: 40%;
  // }
  .page-heading {
    font: normal normal normal 30px/35px 'campton-medium';
  }

  .custom-modal-xl {
    max-width: 800px;
  }

  .molina-header-lbl1 a > div > div:first-child span {
    max-width: 190px;
    min-width: 150px;
  }

  .molina-header-lbl2 > a > div {
    max-width: 190px;
    min-width: 150px;
  }

  .molina-header-lbl3:last-child {
    padding-right: 0px;
  }

  .molina-header2 .navbar-brand {
    font: normal normal normal 15px/18px Campton-book;
    padding: $spacer * 0.5;
  }

  .showChat {
    display: block !important;
  }
}

@include media-breakpoint-down(lg) {
  // .molina-header-logo {
  //   left: 34.5%;
  // }
  .molina-select__control {
    font: normal normal normal 0.72rem/0.88rem Arial;
  }

  .btn {
    padding: 12px 20.5px;
    font: normal normal normal 0.8rem/1rem 'campton-medium';
  }

  .MuiInputBase-input {
    font: normal normal normal 1rem/1rem Arial !important;
  }

  .tab-content {
    .list-multi-link-drp .text-right {
      //display: inline-table;
      justify-content: flex-end;
    }

    .list-multi-link-drp .text-right select {
      min-width: 75px;
    }

    .list-multi-link-drp .text-right .list-icon-download,
    .list-multi-link-drp .text-right .list-icon-pen {
      display: table-row;
      text-align: center;
    }
  }

  .popup-btn a {
    padding-bottom: 25px;
  }

  .molina-progress-bar:first-child {
    border-top: none;
    padding-top: 0;
  }

  .molina-progress-bar {
    border-top: 1px solid rgba(112, 112, 112, 0.37);
    padding-top: $spacer * 0.96;
  }

  .custom-modal-md {
    max-width: 700px;
  }

  .custom-modal-lg {
    max-width: 700px;
  }

  .custom-modal-xl {
    max-width: 700px;
  }

  .molina-header1 ul:first-child .nav-item:first-child {
    display: flex;
    align-items: center;
  }

  .molina-header1-ia ul:first-child .nav-item:first-child {
    display: flex;
    align-items: center;
  }

  .molina-select__control {
    padding: 4px 8px 4px 8px !important;
    //font: normal normal normal 16px/20px Arial;
  }

  .form-check-input[type='radio'],
  .form-check-input {
    height: 20px;
    width: 20px;
  }

  .form-check-input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    margin-top: 2px;
    margin-left: 2px;
  }

  .form-check-input[type='radio']:after {
    width: 0rem;
    height: 0rem;
  }

  .form-check-input[type='checkbox']:checked:after {
    margin-left: 0.29em;
    margin-top: 0px;
  }

  .showChat {
    display: none !important;
  }
}

@include media-breakpoint-down(md) {
  .btn {
    font: normal normal normal 16px/21px 'campton-medium';
  }

  .tab-content .tab-title {
    font-size: 25px;
    font-family: 'campton-medium';
  }

  .card-title {
    font: normal normal normal 25px/48px 'campton-medium';
  }

  .page-heading {
    margin-left: 0px;
  }

  .molina-card {
    min-height: 443px;
  }

  .molina-card .card-body {
    padding: 3.43rem 2rem 1.56rem;
  }

  .header-icon {
    vertical-align: middle;
  }

  .molina-header1 {
    padding: 11px 0px 11px 0px;
  }

  .molina-header1 ul:first-child .nav-item:first-child {
    padding: math.div($spacer, 2) 0rem math.div($spacer, 2) 0.1rem;
  }

  .molina-header1-ia {
    padding: 36px 0px 3px 0px;
  }

  .molina-header1-ia ul:first-child .nav-item:first-child {
    padding: math.div($spacer, 2) 0rem math.div($spacer, 2) 0.1rem;
  }

  .media-body {
    span {
      font-size: 24px;
      line-height: 31px;
    }
  }

  .home-link {
    img.left-arrow-custom {
      margin: 7px 0 0 -23px;
      height: 20px;
    }
  }

  .header-icon {
    height: 20px;
    width: 27px;
    margin-left: 5px;
    margin-right: 2px;
  }

  .molina-header-lbl1 {
    > a {
      padding-right: 5px !important;
    }

    > a .dropdown-toggle {
      white-space: break-spaces;
      display: flex;
    }

    .dropdown-toggle::after {
      content: none;
    }

    a > div > div:first-child {
      font: normal normal normal 13px/14px Campton-semibold;
      padding-bottom: 6px;

      span {
        -webkit-line-clamp: 2;
        min-width: 100px;
        max-width: 125px;
        word-break: break-word;
      }
    }
  }

  .molina-header-lbl1 a > div {
    font: normal normal normal 13px/15px Arial;
  }

  .molina-header-logo {
    left: 1rem;
  }

  .molina-header-logo > img {
    margin-right: 0px;
    height: 23px;
  }

  .molina-header-logo > span {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    white-space: normal;
    display: inline-block;
  }

  .molina-header-logo-ia {
    left: auto;
    right: 0%;
  }

  .molina-header-logo-ia > img {
    margin-right: 9px;
    height: 23px;
  }

  .molina-header-logo-ia > span {
    padding-left: 10px;
    font-size: 12px;
    width: 85px;
    white-space: normal;
  }

  .navbar-brand.molina-header-logo-ia {
    margin: -160px;
    padding-bottom: 86px;
    margin-right: 9px;
  }

  .molina-header-logo .call-icon-ia {
    width: 100%;
    height: 100%;
    position: relative;
    left: -209px;
    top: 37px;
  }

  .sidenav {
    width: 341px;
    transform: translateX(-341px);
  }

  .active-leftnav {
    transform: translateX(0);
  }

  .sidenav .sidenav-nav .sidenav-nav-title {
    span {
      font: normal normal bold 18px/23px Campton-semibold;
    }

    .leftnav-label {
      font: normal normal normal 18px/28px Arial;
    }

    &:nth-child(3) {
      font: normal normal normal 18px/21px Arial;
    }
  }

  .form-error-msg {
    font-size: 16px;
    line-height: 20px;
  }

  .molina-card .card-title,
  .lg-card .card-title {
    margin-bottom: $spacer * 2.438;
  }

  .molina-card-link {
    text-align: center;
  }

  .home-link {
    margin-top: 25px;
    margin-bottom: 38px;
  }

  .img-molina-mail {
    margin-right: 18.5px;
    margin-top: 4px;
    width: 23px;
    height: 18px;
  }

  .img-molina-mail-read {
    margin-right: 18.5px;
    margin-top: 4px;
    margin-bottom: -4px;
    width: 23px;
    height: 23px;
  }

  .img-molina-teal {
    margin-right: 14.5px;
    width: 28px;
    height: 28px;
  }

  .img-molina-info-teal {
    height: 14px;
    margin-bottom: 5px;
  }

  .track-more-details {
    display: block;
    border: none;
    padding-left: 0px;
    margin-left: 0px;
    font: normal normal normal 18px/21px Arial;
    color: $black;
  }

  .molina-progress-bar {
    margin-right: 0px;

    .progress {
      height: 16px;
    }
  }

  .molina-progress-bar-title span:first-child {
    color: $progress-bar-title-color;
    font: normal normal normal 18px/24px 'campton-bold';
  }

  .molina-progress-bar-title .molina-progress-bar-total-score {
    color: $progress-bar-title-color;
    margin-top: 7px;
    width: 100%;
    text-align: right;
    font: normal normal normal 18px/21px Arial;
  }

  .molina-progress-bar .molina-progress-bar-score {
    font: normal normal normal 18px/21px Arial;
    color: $progress-bar-title-color;
    margin-inline: auto;
  }

  .video-placeholder-text {
    font-size: 10px;
    line-height: 12px;
  }

  .modal-content {
    margin: 10px;
  }

  .modal-body {
    padding: 9px 13px 24px 13px;
  }

  .tab-content {
    .list-group-item {
      padding: $spacer * 1.2 $spacer * 1.5 $spacer * 0.5 $spacer * 1.1;
    }

    .list-title {
      padding-bottom: $spacer;
      font: normal normal bold 20px/23px Arial;
      letter-spacing: 0px;
    }

    .no-tab-title {
      padding-top: $spacer * 1;
    }

    .list-desc > div:first-child {
      padding-bottom: $spacer * 1.5;
      font: normal normal normal 16px/18px Arial;
      letter-spacing: 0px;
      color: $black;
    }

    .list-desc2 > div:first-child {
      font: normal normal normal 16px/18px Arial;
      letter-spacing: 0px;
    }

    .list-desc-title {
      padding-top: math.div($spacer, 2);
      font: normal normal bold 16px/26px Arial;
      letter-spacing: 0.12px;
      opacity: 0.75;
    }

    .list-desc-details {
      padding-top: math.div($spacer, 2);
      padding-bottom: $spacer;
      font: normal normal normal 16px/26px Arial !important;
      letter-spacing: 0.12px;
      opacity: 0.75;
    }

    .list-multi-link {
      display: flex;
      justify-content: flex-end;
    }

    .list-multi-link > div {
      padding-left: 15px;
    }

    .list-multi-link-drp .text-right {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 0.5rem;
    }

    .learnmorepopup {
      margin: 28px;
    }

    .list-multi-link-drp .text-right ul {
      list-style-type: none;
    }

    .list-icon-newtab {
      top: 16px;
    }

    .accordion-item .accordion-button,
    .accordion-item .accordion-link {
      padding: 1.2rem 1.5rem 1rem 1.1rem;
    }

    .accordion-body {
      color: $list-desc-details;

      .accordion-body-title {
        padding-top: $spacer * 1.5;
      }

      .accordion-body-desc {
        padding-bottom: $spacer * 1;
      }
    }
  }

  .custom-tooltip-spending .custom-tooltip-spending-text {
    width: 200px;
    left: 14%;
    top: 55%;
    font-size: 18px;
    line-height: 21px;
  }

  .custom-tooltip {
    .custom-tooltip-text {
      left: 0% !important;
    }
  }

  .btn-close-modal {
    background: $white escape-svg($molina-close-btn-sm-svg) 0px 0px no-repeat padding-box;
    height: 18px;
    width: 18px;
    right: 14px;
    top: 24px;
  }

  .update-header {
    h5 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 18px;
      line-height: 27px;
    }

    h4 {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .custom-modal-md {
    max-width: 328px;

    .btn-close-modal {
      right: 15px;
    }

    .modal-content {
      margin: 0px;

      .update-header {
        h5 {
          font: normal normal normal 0.64rem/0.96rem Arial;
          color: $gray-1;
          margin-bottom: $spacer * 0.875;
        }

        .form-label-txt {
          font: normal normal normal 0.64rem/0.96rem Arial;
          color: $gray-1;
          margin: 12px auto 32px;
          text-align: center;
        }

        .form-group {
          padding-bottom: $spacer * 1.25;

          > label {
            font: normal normal normal 0.64rem/0.96rem Arial;
            color: $gray-1;
            margin-bottom: $spacer * 0.5;
          }

          .form-control::-webkit-input-placeholder {
            /* WebKit browsers */
            color: transparent;
          }

          .form-control:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: transparent;
          }

          .form-control::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: transparent;
          }

          .form-control :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: transparent;
          }
        }
      }
    }
  }

  .custom-modal-lg {
    max-width: 328px;

    .btn-close-modal {
      right: 21px;
      top: 29px;
    }

    .back-link {
      font: normal normal normal 22px/26px Arial;
      letter-spacing: 0px;
    }

    .table-heading {
      font-size: 18px;
      line-height: 23px;
      color: #212121;

      span {
        font-size: 18px;
        line-height: 23px;
        color: #212121;
      }
    }

    .modal-content {
      margin: 0px;
    }
  }

  .custom-modal-xl {
    max-width: 328px;

    .btn-close-modal {
      right: 21px;
      top: 29px;
    }

    .back-link {
      font: normal normal normal 22px/26px Arial;
      letter-spacing: 0px;
    }

    .table-heading {
      font-size: 18px;
      line-height: 23px;
      color: #212121;

      span {
        font-size: 18px;
        line-height: 23px;
        color: #212121;
      }
    }

    .modal-content {
      margin: 0px;
    }
  }

  .print-options-modal {
    .modal-content {
      min-width: 328px;
    }

    .modal-body {
      padding: 0px 13px 0px 13px;

      h5 {
        font: normal normal normal 0.88rem/1.12rem 'campton-medium';
      }

      .form-check,
      p {
        font: normal normal normal 18px/21px Arial;
      }
    }

    .modal-footer {
      padding-left: 13px;
      padding-right: 13px;
    }
  }

  .molina-select__menu {
    font: normal normal normal 16px/20px Arial;
  }

  .molina-select__control {
    font: normal normal normal 16px/20px Arial;
    padding: 6px 8px 6px 8px !important;
  }

  .showChat {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .navbar-nav {
    flex-direction: row;
  }
  .pwdPopover{
    width: 40%;
  }
  .molina-header-lbl1 {
    > a {
      max-width: 75%;
    }
  }

  .molina-header-logo > img {
    padding: 2px 0;
    height: 25px;
  }

  .molina-header-logo > span {
    padding-left: 10px;
    font-size: 10px;
  }

  .custom-tooltip {
    .custom-tooltip-text {
      width: auto !important;
    }
  }

  .showChat {
    display: none !important;
  }
}

//survey
#rotate {
  vertical-align: top;
  transform: rotate(7deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

/* Absolute Center Spinner */
.spinner {
  position: fixed;
  z-index: 1051;
  height: 3rem;
  width: 3rem;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

/*End of Spinner*/
.a.learnmoreonclick:hover,
.text-zoom:hover {
  transform: scale(1.15);
  transition: 0.3s;
  display: inline-block;
  text-decoration: underline;
}
.generic-popup a {
  padding: 0 15px;
}
.text-zoom-sm:hover {
  transform: scale(1.05);
  transition: 0.3s;
  display: inline-block;
  text-decoration: underline;
}

.text-zoom-lg:hover {
  transform: scale(1.05);
  transition: 0.3s;
  display: inline-block;
  text-decoration: underline;
}

.hr-contact:hover {
  transform: scale(1.05);
}

a.track-more-details.text-zoom:hover {
  transform: scale(1.1);
  transition: 0.3s;
  display: inline-block;
  text-decoration: underline;
}

.d-flex:hover {
  display: flex;
}

a.learnmoreonclick:hover {
  transform: scale(1.05);
  transition: 0.3s;
  display: inline-block;
  text-decoration: underline;
}

@include media-breakpoint-down(xl) {
  .a.learnmoreonclick:hover,
  .hr-contact:hover,
  .text-zoom:hover,
  a.track-more-details.text-zoom:hover,
  a.learnmoreonclick:hover,
  .text-zoom-lg:hover,
  .text-zoom-sm:hover {
    transform: none;
  }
}

.arr-icon {
  margin-top: -2px;
  height: 23px;
}

.p-b-xs {
  padding-bottom: 5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.f-16 {
  font-size: 16px;
}

button {
  img {
    height: 1.44rem;
  }
}

.input-error .MuiInputBase-root .MuiInputBase-inputAdornedEnd {
  border: 2px solid #e1684c !important;
}

.inner-spinner::after {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  right: 0;
  bottom: 0;
  content: ' ';
  border-radius: 10px;
  z-index: 1;
}

.inner-spinner {
  text-align: center;
  bottom: 50%;
  top: 50%;

  .spinner-border {
    width: 1rem;
    height: 1rem;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.nav-KY {
  @include media-breakpoint-down(xxxl) {
    .molina-leftnav-logo > span {
      border-left: 1px solid $text-color-muted-light;
      padding-left: 10px;
      color: $secondary;
      margin-left: 13px;
      font-size: 13px;
    }
  }

  @include media-breakpoint-down(xxl) {
    .molina-leftnav-logo > span {
      border-left: 1px solid $text-color-muted-light;
      padding-left: 10px;
      color: $secondary;
      margin-left: 13px;
      font-size: 12px;
    }
  }

  @include media-breakpoint-down(xl) {
    .molina-leftnav-logo > span {
      border-left: 1px solid $text-color-muted-light;
      padding-left: 10px;
      color: $secondary;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  @include media-breakpoint-down(lg) {
    .molina-leftnav-logo > span {
      border-left: 1px solid $text-color-muted-light;
      padding-left: 10px;
      color: $secondary;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  @include media-breakpoint-down(md) {
    .molina-leftnav-logo > span {
      border-left: 1px solid $text-color-muted-light;
      padding-left: 10px;
      color: $secondary;
      margin-left: 10px;
      font-size: 10px;
    }
    .pwdPopover{
      width: 40%;
    }
  }

  @include media-breakpoint-down(sm) {
    .molina-leftnav-logo > span {
      border-left: 1px solid $text-color-muted-light;
      padding-left: 10px;
      color: $secondary;
      margin-left: 10px;
      font-size: 10px;
    }
  }
}

//Bright Care Portal
.chp-CA {
  .progress-bar,
  .step.active {
    border-color: $brightCare-color-ca !important;
    color: $brightCare-color-ca !important;
  }
  .btn-primary,
  .tick,
  .progress-bar {
    background-color: $brightCare-color-ca !important;
  }
  a {
    color: $brightCare-color-ca;
  }
  .btn-1 {
    background-color: #fff !important;
    color: $brightCare-color-ca !important;
    &:hover {
      color: $body-color;
      text-decoration: if($link-hover-decoration == underline, none, null);
    }
  }

  .btn-primary:hover,
  .btn-primary:hover a:hover {
    background-color: #fff !important;
    color: $brightCare-color-ca !important;
  }

  .sign-link-line {
    color: $brightCare-color-ca !important;
  }

  .cookie-terms, .cookie-terms a {
    color: #fff !important;
  }

  .dropdown-item {
    color: $dropdown-color;
  }

  .card-title {
    color: $brightCare-color-ca !important;
  }

  .slick-dots li.slick-active button:before {
    color: $brightCare-color-ca !important;
  }

  .crt-mess-heading {
    color: $brightCare-color-ca !important;
  }

  .btn-cancel {
    background-color: #d3d3d3 !important;
    color: white !important;
  }

  .molina-header2 .navbar-brand.active {
    background: $brightCare-color-ca !important;
    color: $white;
    border-bottom: 2px solid $brightCare-color-ca !important;
  }

  .molina-header-logo > span {
    color: $brightCare-color-ca;
  }

  .molina-leftnav-logo > span {
    color: $brightCare-color-ca;
  }

  .update-header h4{
    color: $brightCare-color-ca;
  }

  h4 {
    color: $brightCare-color-ca;
  }

  .renew-discription{
    color: $brightCare-color-ca !important;
  }

  .btn-default {
    color: $brightCare-color-ca;
  }

  .sub-color {
    color: $brightCare-color-ca !important;
  }
  .btn-add {
    color: $brightCare-color-ca !important;
  }
  .btn-molina {
    background-color: $brightCare-color-ca;
  }
  .MFA-left-Button {
    color: $brightCare-color-ca;
  }
  .new-mess-link {
    background-color: $brightCare-color-ca;
  }
  .msg-details-head {
    color: $brightCare-color-ca;
  }
  .msg-dtl-correspondence {
    color: $brightCare-color-ca;
  }
  .blue-text {
    color: $brightCare-color-ca;
  }
  .continue-button {
    background: #005d63 0% 0% no-repeat padding-box;
    background-color: $brightCare-color-ca !important;
  }
  .continue-button:hover{
    background-color: #fff !important;
    color: $brightCare-color-ca !important;
  }
}

// KY theme
.state-KY {
  @include media-breakpoint-down(xl) {
    .molina-header-lbl1 a > div > div:first-child span {
      max-width: 135px;
      min-width: 125px;
    }
  }

  @include media-breakpoint-down(lg) {
    .molina-header-lbl1 a > div > div:first-child span {
      max-width: 120px;
      min-width: 120px;
    }

    .molina-header-logo {
      left: 53%;

      > img {
        height: 35px !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .molina-header-logo {
      left: 50%;
    }
  }

  .state-color,
  .header-dropdown-icon,
  .dropdown-icon,
  .cards-md-tab2 .list-multi-link-drp a img,
  .checkbox-img {
    filter: invert(60%) sepia(61%) saturate(1304%) hue-rotate(85deg) brightness(100%) contrast(80%);
  }

  .renew-icon,
  .contact-us img,
  .hr-contact img {
    filter: invert(60%) sepia(61%) saturate(1304%) hue-rotate(52deg) brightness(100%) contrast(80%);
  }

  .list-icon-download > img {
    content: escape-svg($molina-ky-download-svg);
  }

  .claim-items > a > img {
    content: escape-svg($molina-ky-download-svg);
  }

  .confirm-info,
  .contact-info {
    content: escape-svg($molina-ky-error-svg);
  }

  .navbar-brand.molina-header-logo img,
  .molina-header-logo > img,
  .molina-leftnav-logo > img {
    // content: url(../img/icons/visibility_off_black_24dp.svg);
    // content: escape-svg($molina-ky-molina-logo-svg);
    height: 50px;
  }

  @include media-breakpoint-down(md) {
    .molina-header-logo > img {
      height: 30px !important;
    }

    .molina-leftnav-logo > img {
      height: 35px;
      margin-bottom: -8 px;
    }
  }

  .molina-leftnav-logo {
    height: 40px;
    margin-bottom: -10px;
  }

  .molina-leftnav-logo {
    > img {
      float: left;
      margin-left: 20px;
      height: 40px;
    }

    span {
      width: 150px;
      margin-left: -17px;
      left: -14px;
      padding-left: 13px;
      display: inline-block;
    }
  }

  .message-inbox-panel ul .inbox-message-unread {
    background: escape-svg($molina-ky-message-svg) 10px 40px no-repeat padding-box;
  }

  .popup-btn a,
  .btn-primary,
  .MuiPickersToolbar-toolbar,
  .MuiTypography-subtitle1,
  .form-check-input[type='radio']:checked:after,
  .progress-bar,
  .msg-counter,
  .continue-button {
    background-color: $molina-color-ky !important;
  }

  .btn-primary:hover,
  .btn-primary:hover a:hover,
  .continue-button:hover,
  .contact-modal .contact-button .btn:hover,
  .contact-modal .contact-btn .btn:hover {
    background-color: #fff !important;
    color: $molina-color-ky !important;
  }

  .update-header h4 {
    color: $molina-color-ky !important;
  }

  .wellness-page .step.active {
    border-color: $molina-color-ky !important;
  }

  .wellness-page .tick {
    background-color: $molina-color-ky !important;
  }

  .btn-default:hover {
    border: 1px solid $molina-color-ky;
  }

  .molina-header2 .navbar-brand.active {
    background: $molina-color-ky;
    border-bottom: 2px solid $molina-color-ky;
  }

  .slick-dots li.slick-active button:before,
  .slick-dots li button:before,
  .state-color-txt,
  .contact-note a,
  .hr-contact,
  .my-resources-page a,
  .btn-default,
  .tab-sub-title a,
  .docbusinesshours a,
  .member-id-card .table-second-heading,
  .popup-btn a:hover,
  .account-settings .btn:hover,
  .redirect:hover,
  .contact-modal .contact-btns .btn:hover,
  .MuiPickersYear-yearSelected {
    color: $molina-color-ky !important;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom-color: $molina-color-ky !important;
  }

  .MuiPickersDay-daySelected,
  .MuiPickersDay-daySelected:hover {
    border-color: $molina-color-ky !important;
  }

  .molina-header2 .navbar-brand:hover {
    border-bottom: 2px solid $molina-color-ky;
  }

  .form-check-input:checked {
    border-color: $molina-color-ky;
  }

  .form-check-input[type='checkbox']:checked:disabled {
    background-color: $molina-color-ky !important;
  }

  .form-check-input[type='checkbox']:checked {
    background-color: $molina-color-ky !important;
  }

  .form-check-input:checked:focus:before {
    box-shadow: 0 0 0 13px $molina-color-ky;
  }

  .message-left-panel .message-menu-list .active,
  .sort-container .message-menu-list .active {
    background: rgba($molina-color-ky, 0.3);
  }

  .popup-btn a:hover {
    background-color: white !important;
  }

  // .checkbox-img input[type=checkbox]:checked+label:before {}
  .alert-wrapper {
    .card-link-read-more {
      color: $molina-color-ky !important;
    }

    .card-link-read-more:hover {
      color: $molina-hover-color-ky !important;
    }
  }
}

// default theme

.state-default {
  @include media-breakpoint-down(lg) {
    .molina-header-logo {
      left: 53%;

      > img {
        height: 35px !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .molina-header-logo {
      left: 50%;
    }
  }

  .state-color,
  .header-dropdown-icon,
  .dropdown-icon,
  .cards-md-tab2 .list-multi-link-drp a img,
  .checkbox-img,
  .list-icon-download > img {
    // filter: invert(60%) sepia(61%) saturate(1304%) hue-rotate(85deg) brightness(100%) contrast(80%);
    filter: grayscale(1);
  }

  .renew-icon,
  .contact-us img,
  .hr-contact img {
    filter: grayscale(1);
  }

  // .list-icon-download > img {
  //   // content: escape-svg($molina-ky-download-svg);
  // }
  .claim-items > a > img {
    filter: grayscale(1);
  }

  // .confirm-info,
  // .contact-info {
  //   // content: escape-svg($molina-ky-error-svg);
  // }
  // .navbar-brand.molina-header-logo img,
  // .molina-header-logo > img,
  // .molina-leftnav-logo > img {
  //   // content: url(../img/icons/visibility_off_black_24dp.svg);
  //   // content: escape-svg($molina-ky-molina-logo-svg);
  //   // height: 50px;
  // }

  @include media-breakpoint-down(md) {
    .molina-header-logo > img {
      height: 30px !important;
    }

    .molina-leftnav-logo > img {
      height: 35px;
      margin-bottom: -8 px;
    }
  }

  .molina-leftnav-logo {
    height: 40px;
    margin-bottom: -10px;
  }

  .molina-leftnav-logo {
    > img {
      float: left;
      margin-left: 20px;
      height: 40px;
    }

    span {
      width: 150px;
      margin-left: -17px;
      left: -14px;
      padding-left: 13px;
      display: inline-block;
    }
  }

  // .message-inbox-panel ul .inbox-message-unread {
  //   background: escape-svg($molina-bg-color-default) 10px 40px no-repeat padding-box;
  // }
  .popup-btn a,
  .btn-primary,
  .MuiPickersToolbar-toolbar,
  .MuiTypography-subtitle1,
  .form-check-input[type='radio']:checked:after,
  .progress-bar,
  .msg-counter,
  .continue-button,
  img {
    // background-color: $molina-bg-color-default !important;
    // color: $molina-color-default;
    filter: grayscale(1) !important;
  }

  .btn-primary:hover,
  .btn-primary:hover a:hover,
  .continue-button:hover,
  .contact-modal .contact-button .btn:hover,
  .contact-modal .contact-btn .btn:hover {
    background-color: #fff !important;
    color: $molina-color-default !important;
  }

  .update-header h4 {
    color: $molina-color-default !important;
  }

  .wellness-page .step.active {
    border-color: $molina-color-default !important;
  }

  .wellness-page .tick {
    background-color: $molina-bg-color-default !important;
  }

  .btn-default:hover {
    border: 1px solid $molina-color-default;
  }

  .molina-header2 .navbar-brand.active {
    background: $molina-bg-color-default;
    border-bottom: 2px solid $molina-color-default;
    color: $molina-color-default;
  }

  .slick-dots li.slick-active button:before,
  .slick-dots li button:before,
  .state-color-txt,
  .contact-note a,
  .hr-contact,
  .my-resources-page a,
  .btn-default,
  .tab-sub-title a,
  .docbusinesshours a,
  .member-id-card .table-second-heading,
  .popup-btn a:hover,
  .account-settings .btn:hover,
  .redirect:hover,
  .contact-modal .contact-btns .btn:hover,
  .MuiPickersYear-yearSelected,
  .MuiTypography-subtitle1,
  .MuiTypography-h4,
  .toolbar-Text {
    color: $molina-color-default !important;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom-color: $molina-color-default !important;
  }

  .MuiPickersDay-daySelected,
  .MuiPickersDay-daySelected:hover {
    border-color: $molina-color-default !important;
  }

  .molina-header2 .navbar-brand:hover {
    border-bottom: 2px solid $molina-color-default;
  }

  .form-check-input:checked {
    border-color: $molina-color-default;
  }

  .form-check-input[type='checkbox']:checked:disabled {
    background-color: $molina-bg-color-default !important;
  }

  .form-check-input[type='checkbox']:checked {
    background-color: $molina-bg-color-default !important;
  }

  .form-check-input:checked:focus:before {
    box-shadow: 0 0 0 13px $molina-color-default;
  }

  .message-left-panel .message-menu-list .active,
  .sort-container .message-menu-list .active {
    background: rgba($molina-bg-color-default, 0.3);
  }

  .popup-btn a:hover {
    background-color: white !important;
  }

  // .checkbox-img input[type=checkbox]:checked+label:before {}
  .alert-wrapper {
    .card-link-read-more {
      color: $molina-color-default !important;
    }

    .card-link-read-more:hover {
      color: $molina-color-default !important;
    }
  }

  .MuiPickersToolbar-toolbar,
  .MuiTypography-subtitle1 {
    background-color: transparent;
  }
}

.m-l-xs {
  margin-left: 3px;
}

.m-l-1 {
  margin-left: 1px;
}

.m-b-t-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-a-top {
  vertical-align: top;
}

.inner-spinner-without-overlay {
  .inner-spinner {
    margin-left: -2px;

    @include media-breakpoint-down(md) {
      margin-left: -115px;
    }
  }

  .inner-spinner:after {
    background-color: transparent;
  }
}
.spotlight {
  height: auto !important;
}
.myplanresources {
  margin-bottom: 20px;
}
.myplanresources .MuiFormControl-marginNormal {
  margin: 0px;
}
.myplanresources .search-btn {
  margin-top: 25px;
}
.myplanresources .btn-search {
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px #0000003d;
  box-shadow: 0 2px 2px #0000003d;
  font: normal normal 18px/20px Campton-medium;
  height: 2rem;
  letter-spacing: 1.38px;
  text-align: center;
  text-transform: uppercase;
  width: 152px;
}
.myplanresources .MuiInputBase-input {
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 4px;
}
.myplanresources .MuiInputBase-input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
.myplanresources .MuiInputBase-input::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.myplanresources .MuiInputBase-input:-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.myplanresources .MuiInputBase-input::-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.myplanresources .MuiInputBase-input::placeholder {
  color: #000;
  opacity: 1;
}
.molina-leftnav-logo > .NY_MSWH {
  font: normal normal normal 11px/22px campton-semibold;
}
.molina-header-logo .call-icon {
  width: 131px;
  height: 30px;
}
.molina-header-logo .Affinity_logo {
  height: 85px;
  width: 177px;
}
.molina-header-logo .swh_logo {
  width: 163px;
  height: 30px;
}
.molina-leftnav-logo .Affinity_logo_hambergur {
  height: 65px;
  width: 150px;
  vertical-align: middle;
}
.text-decoration-underline {
  display: inline-block;
}
.molina-leftnav-logo > .NY_MSWH {
  font: normal normal normal 11px / normal campton-semibold;
  display: inline-block;
  width: 140px;
}
#KYAddressTable .kycolumnone {
  width: 80%;
}
.kyrxrow {
  width: 100%;
}
.kyrxcolumn {
  display: inline-block;
  margin-left: 68%;
}
.kyrxcolleft {
  display: inline-block;
  width: 68%;
}
.kyrxcolright {
  display: inline-block;
}
.member-id-card #idcontainer {
  padding: 100px 20px 15px 20px;
  background-position: top;
}
#idcontainer #memberCurrentID {
  margin-left: 0px;
}
.listmrgn {
  margin-bottom: 0px;
}
.listmrgn > li {
  padding-top: 4px;
}
#idcontainer .mrgncomprehensivetb {
  padding: 15px 0px;
}
.cardleft,
.cardright {
  vertical-align: top;
}
#idcontainer #memberCurrentID tr td.cardleft {
  width: 72%;
}
.ltcmrgn {
  margin-bottom: 0px;
}
.ltcmrgn > li:not(:last-child) {
  padding-bottom: 18px;
}
.flheight {
  height: 115px;
}
.member-id-card #containerltc {
  padding: 100px 20px 15px 20px;
  background-position: top;
}
#containerltc #memberCurrentID {
  margin-left: 0px;
}
.member-id-card #containerTX {
  padding: 100px 10px 10px 30px;
  background-position: top;
}
#containerTX #memberCurrentID {
  margin-left: 0px;
}
.TXChipShift #containerTX #memberCurrentID {
  margin-top: 1rem;
}
.widthone {
  width: 70%;
  padding-right: 20px;
}
.widthtwo {
  width: 30%;
  padding-left: 5px;
}
.TXmrgn > li:not(:last-child) {
  margin-bottom: 6px;
}
.TXmrgnCHIP > li:not(:last-child) {
  margin-bottom: 0px;
}
.TXmrgn {
  margin-bottom: 10px;
}
.TXmrgnCHIP {
  margin-bottom: 10px;
}
.TXblock {
  margin-top: 20px;
}
.TXchipleft {
  width: 70%;
}
.TXchipright {
  width: 30%;
}
.chipTopHeight {
  height: 65px;
}
.cvsLogo {
  width: 110px;
  height: 13px;
}
.vborder {
  border-left: 1px solid #b5b5b5;
  padding-left: 12px;
}
.vborderTXDuals {
  border-left: 1px solid #b5b5b5;
  padding-left: 6px;
}
.chipTop2Height {
  height: 95px;
}
.TXheightRT {
  height: 48px;
}
.starplusbackid > label {
  display: inline;
  line-height: 14px;
}
.TXmrgntop {
  margin-top: 80px;
}
.TXmrgntoptwo {
  margin-top: 80px;
}
.perinateleft {
  width: 67%;
}
.perinateright {
  width: 32%;
  padding-left: 8px;
}
.chippertop {
  margin-top: 100px;
}
.FLbackmb {
  margin-bottom: 10px;
}
.member-id-card #FLback {
  padding: 30px 25px 10px;
}
.member-id-card #FLback .member-id-card-back-text {
  padding: 0;
}
.CAmedileft {
  width: 67%;
}
.CAmediright {
  width: 32%;
  padding-left: 15px;
}
.CAmrgnRtop {
  margin-top: 85px;
}
.CAmrgnLARtop {
  margin-top: 35px;
}
.CAlistMrgn > li:not(:last-child) {
  margin-bottom: 4px;
}
.CAlistMrgn {
  margin-bottom: 0px;
}
.CAlistR1 {
  margin-bottom: 0px;
}
.CAlistR1 > li:not(:last-child) {
  margin-bottom: 8px;
}
.member-id-card #containerCA {
  padding: 125px 10px 10px 25px;
  background-position: top;
}
.member-id-card #containerCACHP {
  padding: 125px 10px 30px 25px;
  background-position: top;
}

.CHP-front-id {
  padding: 125px 10px 25px 25px;
}

#containerCA #memberCurrentID {
  margin-left: 0px;
}
.TXlogoMrgn {
  padding-left: 15px;
}
.chipRSAleft {
  width: 70%;
}
.chipRSAright {
  width: 30%;
}
.chipTopHeight {
  height: 65px;
}
.TXdualleft {
  width: 64%;
}
.TXdualright {
  width: 36%;
}
.dualHeight {
  height: 80px;
}
.mediheight {
  height: 35px;
}
.id-card-back-website-name {
  font-size: 12px;
  color: #00a4b3;
}
.txt-pad {
  padding-left: 93px;
}
#TXchipBack .fontsizeBack {
  font-size: 14px;
  line-height: 15px;
}
.frontheight {
  height: 20px;
}
.SCmrgnTop {
  height: 50px;
}
.height1SC {
  height: 30px;
}
.height2SC {
  height: 40px;
}
.member-id-card #containerSC {
  padding: 100px 10px 10px 30px;
  background-position: top;
}
#containerSC #memberCurrentID {
  margin-left: 0px;
}
.leftSCcard {
  width: 70%;
}
.rightSCcard {
  width: 30%;
}
.mrgnListSC > li:not(:last-child) {
  margin-bottom: 5px;
}
.mrgnListSC > li.SCmrgnb {
  margin-bottom: 30px;
}
.mrgnListSC {
  margin-bottom: 0px;
}
#SCbackID #memberCurrentID {
  margin-left: 0px;
}
.member-id-card #backMedicare {
  padding-top: 20px;
}
.SClogoMrgnLeft {
  padding-left: 30px;
}
/* OH custom style starts here */
.blockSec {
  font: normal normal 18px Campton-medium;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 30px;
}
.blockSec2 {
  font: normal normal 18px Campton-medium;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 20px;
}
.ohio-subheading {
  font: normal normal 0.96rem Campton-medium;
  color: #000;
  text-align: left;
}
.showMore {
  cursor: pointer;
  display: block;
  font: normal normal 18px campton-medium;
  text-align: center;
}
.paraText {
  font: normal normal 14px Campton-medium;
  color: #000;
  text-align: left;
}
.textbold {
  font-weight: bold;
}
.addLink {
  text-decoration: none;
}
.red {
  color: red;
}
.w60 {
  width: 60%;
}
.w40 {
  width: 40%;
}
.height10 {
  height: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mrgnleft10 {
  margin-left: 10px;
}
.inputOther {
  position: relative;
}
.inputOther .other {
  position: absolute;
  top: -2px;
  margin-left: 15px;
}
.formcheckmrgn .form-check {
  margin-bottom: 10px;
}

.member-id-card #OHMedicaid {
  padding: 35px 15px 10px 15px;
  background-position: top;
}
.OHTitle {
  font-size: 12px;
  color: #1434a4;
}
.mb5 {
  margin-bottom: 5px;
}
.mb30 {
  margin-bottom: 30px;
}
.mrgnb10 {
  margin-bottom: 10px;
}
.primaryMrgn {
  margin-bottom: 5px;
  margin-top: 30px;
}
.mb50 {
  margin-bottom: 60px;
}
.datMrgn {
  margin-bottom: 15px;
}
.mt30 {
  margin-top: 30px;
}
.posrelative {
  position: relative;
}
.OHBox {
  font-size: 11px;
  width: 85%;
  display: inline-block;
  margin-left: 27px;
  margin-top: 3px;
}
.heightOH {
  height: 30px;
}
.CSPText {
  font-size: 20px;
  padding-bottom: 5px;
}
.OHBackTitle {
  margin-top: 15px;
  color: #1434a4;
}
.OHBackmrgn {
  margin-bottom: 10px;
}
.CSPBlock {
  position: absolute;
  top: 40px;
  left: 25px;
}
.OHBox2 {
  font-size: 11px;
  width: 85%;
  display: inline-block;
  margin-left: 27px;
  margin-top: 3px;
}
.CSPBlock2 {
  left: 25px;
  position: absolute;
  top: 20px;
}
.CSPBlock3 {
  left: 25px;
  position: absolute;
  top: 20px;
}
.titleMrgn {
  margin-bottom: 40px;
}
.OHBox3 {
  font-size: 11px;
  width: 85%;
  display: inline-block;
  margin-left: 27px;
  margin-top: 3px;
}
.textOH {
  font-size: 11px;
}
.titleMrgn3 {
  margin-bottom: 40px;
}
.gridblock {
  margin-bottom: 20px;
}
.gridblock .StdTxthead1 {
  font-size: 11px;
}
.gridblock .StdTxthead2 {
  font-size: 10px;
}
.gridblock .StdTxthead3 {
  font-size: 9px;
}
.primarytop {
  margin-top: 30px;
}
.titleMrgn2 {
  margin-bottom: 30px;
}
.OHBox4 {
  font-size: 11px;
  width: 85%;
  display: inline-block;
  margin-left: 25px;
  margin-top: 3px;
}
.dateMrgn {
  margin-bottom: 20px;
}
.highlightOption {
  background: #dfdfdf !important;
  color: #fff !important;
}
.multiSelectContainer li:hover {
  background: #dfdfdf !important;
  color: #fff !important;
}
.highlightOption,
.multiSelectContainer li:hover {
  color: #454545 !important;
}

@include media-breakpoint-down(md) {
  .myplanresources .label:not(:last-child) {
    margin-bottom: 5px;
  }
  .myplanresources .MuiInput-root {
    margin-bottom: 20px;
  }
  .myplanresources .btn-search {
    height: auto;
  }
  .pagination {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
  }
  .molina-header-logo .Affinity_logo {
    width: 120px;
    height: 65px;
  }
  .molina-header-logo .swh_logo {
    width: 85px;
    height: 20px;
  }
  #KYAddressTable .kycolumnone {
    width: 68%;
  }
  .kyrxcolumn {
    display: inline-block;
    margin-left: 68%;
  }
  .member-id-card #idcontainer {
    padding: 82px 12px 15px 12px;
  }
  .listmrgn > li {
    padding-top: 0px;
  }
  .mbomrgn {
    margin-bottom: 0px;
  }
  #idcontainer .mrgncomprehensivetb {
    padding: 10px 0px;
  }
  .flheight {
    height: 55px;
  }
  .member-id-card #containerltc {
    padding: 51px 12px 15px 12px;
    background-size: 100% 100%;
  }
  .ltcmrgn > li:not(:last-child) {
    padding-bottom: 0px;
  }
  #containerTX .table-font-size {
    font-size: 8px;
  }
  .TXmrgn > li:not(:last-child) {
    margin-bottom: 0px;
  }
  .TXmrgnCHIP > li:not(:last-child) {
    margin-bottom: 0px;
  }
  .member-id-card #containerTX {
    padding: 65px 10px 10px 25px;
  }
  .TXmrgn {
    margin-bottom: 4px;
  }
  .TXmrgnCHIP {
    margin-bottom: 4px;
  }
  .TXchipright {
    padding-left: 8px;
  }
  .TXheightRT {
    height: 32px;
  }
  .navbar > .mobilehead {
    display: block;
    height: 100px;
  }
  .molina-header-lbl1 a > div > div:first-child span {
    max-width: 100%;
    min-width: 100%;
  }
  .navbar-brand.molina-header-logo {
    margin-top: 15px;
    margin-left: 0px;
  }
  .TXmrgntop {
    margin-top: 45px;
  }
  .TXmrgntoptwo {
    margin-top: 40px;
  }
  .chippertop {
    margin-top: 40px;
  }
  .member-id-card #containerCA {
    padding: 85px 10px 10px 14px;
    font-size: 9px;
  }
  .CAlistMrgn > li:not(:last-child) {
    margin-bottom: 0px;
  }
  .CAmrgnRtop {
    margin-top: 60px;
  }
  .CAmrgnLARtop {
    margin-top: 25px;
  }
  .CAlistR1 > li:not(:last-child) {
    margin-bottom: 5px;
  }
  .chipTopHeight {
    height: 32px;
  }
  .cvsLogo {
    width: 70px;
    height: 9px;
  }
  .vborder {
    padding-left: 6px;
  }
  .chipTop2Height {
    height: 50px;
  }
  .dualHeight {
    height: 65px;
  }
  .mediheight {
    height: 30px;
  }
  .starPlusMob {
    width: 160px;
    height: 30px;
  }
  .TXbackwidth {
    width: 95%;
  }
  .member-id-card .id-card-new .background-id-row .height-adjustment-pcp {
    height: 14px;
  }
  .member-id-card .id-card-new .background-id-row tr td:first-child {
    width: 50%;
  }
  #TXchipBack .fontsizeBack {
    font-size: 8px;
    line-height: 12px;
  }
  .SCmrgnTop {
    height: 10px;
  }
  .height2SC {
    height: 25px;
  }
  .OHTitle {
    font-size: 8px;
  }
  .member-id-card #OHMedicaid {
    font-size: 9px;
  }
  .mb50 {
    margin-bottom: 20px;
  }
  .mb30 {
    margin-bottom: 15px;
  }
  .OHBox {
    font-size: 6px;
    display: inline-block;
    margin-left: 15px;
    margin-top: 10px;
  }
  .heightOH {
    height: 14px;
  }
  .mt30 {
    margin-top: 0px;
  }
  .mb5 {
    margin-bottom: 0px;
  }
  .member-id-card #OHMedicaid {
    padding: 20px 10px 10px;
  }
  .CSPBlock {
    left: 10px;
    position: absolute;
    top: 25px;
  }
  .OHBackmrgn {
    margin-bottom: 6px;
  }
  .OHBox2 {
    left: 22px;
    font-size: 6px;
    top: -10px;
  }
  .CSPBlock2 {
    left: 15px;
    top: 10px;
  }
  .OHBox3 {
    font-size: 6px;
    margin-left: 16px;
    margin-top: 3px;
  }
  .titleMrgn {
    margin-bottom: 20px;
  }
  .textOH {
    font-size: 6px;
  }
  .member-id-card #OHMedicaid .OHFullText {
    font-size: 6px;
  }
  .OHBox4 {
    font-size: 6px;
    margin-left: 16px;
    margin-top: -10px;
  }
  .titleMrgn2 {
    margin-bottom: 15px;
  }
  .dateMrgn {
    margin-bottom: 10px;
  }
  .titleMrgn3 {
    margin-bottom: 25px;
  }
  .gridblock {
    margin-bottom: 10px;
  }
  .gridblock .StdTxthead1 {
    font-size: 9px;
  }
  .gridblock .StdTxthead2 {
    font-size: 9px;
  }
  .gridblock .StdTxthead3 {
    font-size: 6px;
  }
  .primarytop {
    margin-top: 20px;
  }
  .CSPBlock3 {
    left: 15px;
    position: absolute;
    top: -12px;
  }
  .pref_Email_Mob {
    width: 62%;
  }
  .btnUpdateMob {
    position: inherit;
    margin-top: 12px;
  }
}

@media (max-width: 991.98px) {
  .CAipad {
    padding-top: 20px;
  }
  .CAmrgnRtop {
    margin-top: 115px;
  }
  .CAmrgnLARtop {
    margin-top: 55px;
  }
}
#RaceMultiSelect .searchWrapper {
  border: 1px solid #999;
  border-radius: 4px;
  min-height: 38px;
  padding: 5px 8px 6px !important;
}
#RaceMultiSelect .option input[type='checkbox'] {
  accent-color: #009ea0;
}
#RaceMultiSelect .option input {
  width: 15px;
  height: 15px;
  float: left;
}
#RaceMultiSelect .option .checkbox:checked {
  border-color: #009ea0;
}
#RaceMultiSelect .option .checkbox[type='checkbox']:checked {
  background-color: #009ea0;
  background-image: none;
}
#RaceMultiSelect .multiSelectContainer {
  font-size: 16px;
}
.popover {
  // scss-docs-start popover-css-vars
  --bs-popover-zindex: #{$zindex-popover};
  --bs-popover-max-width: #{$popover-max-width};
  @include rfs($popover-font-size, --bs-popover-font-size);
  --bs-popover-bg: #f0f0f0;
  --bs-popover-border-width: #{$popover-border-width};
  --bs-popover-border-color: #{$popover-border-color};
  --bs-popover-border-radius: #{$popover-border-radius};
  --bs-popover-inner-border-radius: #{$popover-inner-border-radius};
  --bs-popover-box-shadow: #{$popover-box-shadow};
  --bs-popover-header-padding-x: #{$popover-header-padding-x};
  --bs-popover-header-padding-y: #{$popover-header-padding-y};
  // @include rfs($popover-header-font-size, --bs-popover-header-font-size);
  --bs-popover-header-color: #{$popover-header-color};
  --bs-popover-header-bg: #{$popover-header-bg};
  --bs-popover-body-padding-x: #{$popover-body-padding-x};
  --bs-popover-body-padding-y: #{$popover-body-padding-y};
  --bs-popover-body-color: #{$popover-body-color};
  --bs-popover-arrow-width: #{$popover-arrow-width};
  --bs-popover-arrow-height: #{$popover-arrow-height};
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  // scss-docs-end popover-css-vars

  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  @include font-size(var(--bs-popover-font-size));
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  @include border-radius(var(--bs-popover-border-radius));
  @include box-shadow(var(--bs-popover-box-shadow));

  .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height);

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0;
    }
  }
}

.bs-popover-top {
  > .popover-arrow {
    bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); // stylelint-disable-line function-disallowed-list

    &::before,
    &::after {
      border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; // stylelint-disable-line function-disallowed-list
    }

    &::before {
      bottom: 0;
      border-top-color: var(--bs-popover-arrow-border);
    }

    &::after {
      bottom: var(--bs-popover-border-width);
      border-top-color: var(--bs-popover-bg);
    }
  }
}

/* rtl:begin:ignore */
.bs-popover-end {
  > .popover-arrow {
    left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width) + 0.04rem); // stylelint-disable-line function-disallowed-list
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width);

    &::before,
    &::after {
      border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; // stylelint-disable-line function-disallowed-list
    }

    &::before {
      left: 0;
      border-right-color: var(--bs-popover-arrow-border);
    }

    &::after {
      left: var(--bs-popover-border-width);
      border-right-color: var(--bs-popover-bg);
    }
  }
}

/* rtl:end:ignore */

.bs-popover-bottom {
  > .popover-arrow {
    top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); // stylelint-disable-line function-disallowed-list

    &::before,
    &::after {
      border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); // stylelint-disable-line function-disallowed-list
    }

    &::before {
      top: 0;
      border-bottom-color: var(--bs-popover-arrow-border);
    }

    &::after {
      top: var(--bs-popover-border-width);
      border-bottom-color: var(--bs-popover-bg);
    }
  }

  // This will remove the popover-header's border just below the arrow
  .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--bs-popover-arrow-width);
    margin-left: calc(-.5 * var(--bs-popover-arrow-width)); // stylelint-disable-line function-disallowed-list
    content: "";
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  }
}

/* rtl:begin:ignore */
.bs-popover-start {
  > .popover-arrow {
    right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); // stylelint-disable-line function-disallowed-list
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width);

    &::before,
    &::after {
      border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); // stylelint-disable-line function-disallowed-list
    }

    &::before {
      right: 0;
      border-left-color: var(--bs-popover-arrow-border);
    }

    &::after {
      right: var(--bs-popover-border-width);
      border-left-color: var(--bs-popover-bg);
    }
  }
}

/* rtl:end:ignore */

.bs-popover-auto {
  &[data-popper-placement^="top"] {
    @extend .bs-popover-top;
  }
  &[data-popper-placement^="right"] {
    @extend .bs-popover-end;
  }
  &[data-popper-placement^="bottom"] {
    @extend .bs-popover-bottom;
  }
  &[data-popper-placement^="left"] {
    @extend .bs-popover-start;
  }
}

// Offset the popover to account for the popover arrow
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0; // Reset the default from Reboot
  @include font-size(var(--bs-popover-header-font-size));
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  @include border-top-radius(var(--bs-popover-inner-border-radius));

  &:empty {
    display: none;
  }
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
  font-family: 'campton-medium';
}

.custom-tooltip-spending .tooltipBox{
  background-color: #ececec;
  width: 420px;
  padding: 10px 12px;
  font: normal normal 14px 'Campton-medium', Arial !important; 
}
.directionsText {
  padding: 1rem 0px 0px;
  width: 70%;
  margin: 0 auto;
  font: normal normal normal .77rem/1.12rem 'Campton-medium';
}
.StateAlign{
  padding-top: 8px;
  text-align: right;
  padding-right: 20px;
}
@include media-breakpoint-down(md) {
  .directionsText {
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .infoStep1 .custom-tooltip-spending .tooltipBox{
    left: -240px;
  }
  .infoStep1 .ssnInfo1 > .tooltipBox{
    left: -200px;
  }
  .infoStep1 .MedicareInfoImg > .tooltipBox{
    left: -300px;
  }
  .custom-tooltip-spending .tooltipBox {
    width: 280px;
    left: -150px;
    top: 20px;
  }
  .popoverMob {
    width: 100%;
  }
  .pref_Email_Mob {
    width: 62%;
  }
  .btnUpdateMob {
    position: relative;
    top: 38px;
  }
  .mobwidth {
    width: auto !important;
  }
}

@include media-breakpoint-between(md, xl) {
  .infoStep1 .custom-tooltip-spending .tooltipBox{
    left: -355px;
  }
  .infoStep1 .ssnInfo1 > .tooltipBox{
    left: -165px;
  }
}
.bgcolor  {
  background: #585958;
}
.bgcolor .renew-title .alert-title-noCaps {
  color: #ffffff;
}
.bgcolor a{
  text-decoration: underline;
 color: #ffffff;
}
.alert-wrapper #renewTitle {
    margin: 0px;
    padding: 0px;
}
body {
    background-color: #f5f5f7 !important;
}
.cookie-terms, .cookie-terms a {
  color: #fff;
  padding: .5rem;
  font-size: .72rem;
  font-family: campton-medium;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
//NE  implementation
.widththree {
  width: 66%;
  padding-right: 20px;
}
.createText{
  padding-right: 10px;
  color: #000;
}
.widthEOB{
  width: 130px;
}